import { Entity, core } from "breeze-client";
import { EntityBase } from './EntityBase';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class NotificationQueue extends EntityBase  {

    /// <code> Place custom code between <code> tags
    
    constructor()
    {
        super();
        this.notificationId = core.getUuid();   
        this.customMessage = '';
        this.clientFirst = '';
        this.clientLast = '';

    }




    toJson(): any {

        const item: any = {
            notificationId: this.notificationId,
            appointmentId: this.appointmentId,
            businessId: this.businessId,
            clientFirst: this.clientFirst,
            clientId: this.clientId,
            clientLast: this.clientLast,
            customMessage: this.customMessage,
            date: this.date,
            dateTimeEnd: this.dateTimeEnd,
            dateTimeStart: this.dateTimeStart,
            direction: this.direction,
            email: this.email,
            isBusinessOrigin: this.isBusinessOrigin,
            isCustom: this.isCustom,
            locationId: this.locationId,
            otherInfo: this.otherInfo,
            process: this.process,
            staffFirst: this.staffFirst,
            staffLast: this.staffLast,
            telephone: this.telephone,
            type: this.type
        };

        return item;

    }


    /// </code>

    // Generated code. Do not place code below this line.
    notificationId: string;
    appointmentId: string;
    businessId: string;
    clientFirst: string;
    clientId: string;
    clientLast: string;
    customMessage: string;
    date: Date;
    dateTimeEnd: Date;
    dateTimeStart: Date;
    direction: string;
    email: string;
    isBusinessOrigin: boolean;
    isCustom: boolean;
    locationId: string;
    otherInfo: string;
    process: string;
    staffFirst: string;
    staffLast: string;
    telephone: string;
    type: string;
}
export interface INotificationQueue extends NotificationQueue {}
export interface IBreezeNotificationQueue extends Entity, INotificationQueue { }
