import { UserAccess } from './UserAccess';
import { Entity } from 'breeze-client';
import { EntityBase } from './EntityBase';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class UserAccount extends EntityBase {

    /// <code> Place custom code between <code> tags
    status:string;
    /// </code>

    // Generated code. Do not place code below this line.
    userId: string;
    activated: boolean;
    avatar: string;
    activationCode: string;
    dateCreated: Date;
    enabled: boolean;
    fbId: string;
    firstname: string;
    identityNumber: string;
    lastLogin: Date;
    lastname: string;
    password: string;
    telephone: string;
    telephoneCountry: string;
    title: string;
    userRole: string;
    userType: string;
    username: string;
    userAccess: UserAccess[];
}
