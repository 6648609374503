import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings.service';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { ISignalRAppointment } from '../../models/entity-objects';
import { AuthService } from '../../auth/auth.service';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class SignalRServiceBase {

    private liveStatusSubject = new BehaviorSubject<signalR.HubConnectionState>(signalR.HubConnectionState.Disconnected);

    get liveStatus(): Observable<signalR.HubConnectionState> {
        return this.liveStatusSubject.asObservable();
    }


    connection: HubConnection;
    private reInitSignalRSubject = new Subject<any>();
    reInitSignalRSubject$ = this.reInitSignalRSubject.asObservable();




    constructor(private appSettings: AppSettings, private authService: AuthService) {
        console.log('RTS Base Initialized.');
    }

    async start(): Promise<void> {

        const token = this.authService.user.token;

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(environment.signalR_path, { accessTokenFactory: () => token })
            .withAutomaticReconnect()
            .build();

        await this.init();

        this.connection.onclose(async () => {
            this.appSettings.isLive = false;
            this.liveStatusSubject.next(this.connection.state);
        });

        this.connection.onreconnecting(error => {
            
            this.appSettings.isLive = false;
            this.liveStatusSubject.next(this.connection.state);
        });

        this.connection.onreconnected(error => {
            this.liveStatusSubject.next(this.connection.state);
            this.appSettings.isLive = true;
        });

        return;
    }

    async init(): Promise<void> {
        try {
            await this.connection.start();
            console.log('Connection Base Initialized.');
            this.appSettings.isLive = true;
            this.liveStatusSubject.next(this.connection.state);
            this.reInitSignalRSubject.next(null);

        } catch (err) {
            this.appSettings.isLive = false;
            console.log(err);
            setTimeout(() => this.start(), 5000);
        }
    }

    disconnect(): void {
        if (this.connection) {
            this.appSettings.isLive = false;
            this.liveStatusSubject.next(this.connection.state);
            this.connection.stop();
        }

    }

}
