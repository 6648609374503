import { ClientCacheService } from './services/cache/client-cache.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule, Optional, SkipSelf, APP_INITIALIZER, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthInterceptor, ErrorInterceptor } from 'app/core/auth/auth.interceptor';


import { AppSettings } from './services/app-settings.service';
import { AppControlService } from './services/app-control.service';
import { MessageService } from './services/message.service';
import { BreezeService } from './services/breeze.service';
// import { AppointmentService } from './services/appointment.service';
import { AppointmentCacheList } from './services/cache/appointment-cache.service';
import { SupportServices } from '../core/services/support.service';
import { IconService } from './services/icon.service';
import { ClientService } from './services/client.service';
import { AppInjector } from './services/app-injector.service';
import { AdminService } from '../core/services/admin.service';
import { SignalRServiceBase } from '../core/services/signalR/signalR-base.service';
import { SignalRInboundService } from '../core/services/signalR/signalR-inbound.service';
import { SignalROutboundService } from '../core/services/signalR/signalR-outbound.service';
import { GlobalEvents } from '../core/events/events';
import { GlobalCalendarEventsService } from './events/calendar.events';
import { HBUnitOfWork, UserAccessUOW } from './services/hb-unit-of-work';
import { EntityManagerProvider } from './services/data-access';
import { AppointmentManagerService } from './services/appointment.manager.service';
import { NotificationService } from './services/notification.service';
import {ZoomService} from './services/zoom.service';
@NgModule({
    imports: [
        HttpClientModule,
        MatSnackBarModule,
        MatDialogModule,
    ],
})
export class CoreModule {
    /**
     * Constructor
     *
     * @param {DomSanitizer} _domSanitizer
     * @param {MatIconRegistry} _matIconRegistry
     * @param parentModule
     */
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry,
        @Optional() @SkipSelf() parentModule?: CoreModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }

        // Register icon sets
        this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('iconsmind', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('dripicons', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dripicons.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('feather', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));
    }


    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                AuthService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ErrorInterceptor,
                    multi: true
                },
                AppSettings,
                AppControlService,
                BreezeService,
                AppointmentCacheList,
                AppointmentManagerService,
                SupportServices,
                // IconService,
                ClientService,
                MessageService,
                AppInjector,
                GlobalEvents,
                AdminService,
                GlobalCalendarEventsService,
                SignalRServiceBase,
                SignalRInboundService,
                SignalROutboundService,
                HBUnitOfWork,
                UserAccessUOW,
                EntityManagerProvider,
                ClientCacheService,
                NotificationService,
                ZoomService


            ]
        };
    }

}





