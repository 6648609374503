import { Appointment } from './Appointment';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Log {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    logId: string;
    appointmentId: string;
    batchId: string;
    businessId: string;
    clientFirst: string;
    clientId: string;
    clientLast: string;
    creationDate: Date;
    date: Date;
    logMessage: string;
    logType: string;
    otherInfoDecimal: number;
    otherInfoText: string;
    smsstatus: number;
    telephone: string;
    appointment: Appointment;
}

