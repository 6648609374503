<div *ngIf="!isScreenSmall" class="med-select select-small g-style flex-row">
  <mat-form-field>
    <mat-select [formControl]="viewControl" class="flex-auto" name="calendarviews">
      <mat-option [value]="'timeGridDayHB'">Day</mat-option>
      <mat-option [value]="'timeGridThreeDayHB'">3 Day</mat-option>
      <mat-option [value]="'timeGridFiveDayHB'">5 Day</mat-option>
      <mat-option [value]="'timeGridWeekHB'">Week</mat-option>
      <mat-option [value]="'listDayHB'">List</mat-option>
      <mat-option [value]="'listWeekHB'">List Week</mat-option>
      <mat-option [value]="'monthHB'">Month</mat-option>
    </mat-select>
  </mat-form-field>
</div>