import { Injectable, Input } from '@angular/core';
import { BreezeService } from './breeze.service';
import { AppSettings } from './app-settings.service';



@Injectable()
export class AdminService {

    constructor(private breezeService: BreezeService, private appSettings: AppSettings) {

    }

    // public getUser(id: string): Observable<IBreezeUserDetail | any> {
    //     var promise = new Promise((resolve, reject) => {
    //         var p1 = Predicate.create("user_ID", FilterQueryOp.Equals, id)

    //         var query = EntityQuery.from('Users').expand('userDetail').where(p1);


    //         //check locally
    //         var results = this.breezeService.service.executeQueryLocally(query);

    //         if (results != null && results != undefined && results.length > 0) {
    //             resolve(results[0]);
    //         }
    //         else
    //             this.breezeService.service.executeQuery(query).then((data) => {
    //                 resolve(<IBreezeUserDetail>data.results[0]);
    //             });

    //     });

    //     return Observable.fromPromise(promise);

    // }


    // getInterest(): Promise<any> {
    //     return new Promise((resolve, reject) => {

    //         var query = EntityQuery.from('Interests').orderBy('date', true);
    //         this.breezeService.service.executeQuery(query).then((data) => {
    //             resolve(data.results);
    //         });
    //     });
    // }

    // getLogs(id): Promise<any> {
    //     return new Promise((resolve, reject) => {


    //         if (id.length < 2) {
    //             resolve([]);
    //             return;
    //         }

    //         var p1 = Predicate.create('telephone', 'contains', id);
    //         var p2 = Predicate.create('patientFirst', 'contains', id);
    //         var p3 = Predicate.create('patientLast', 'contains', id);
    //         var p4 = Predicate.create('logMessage', 'contains', id);

    //         var pred: any;
    //         pred = Predicate.or([p1, p2, p3, p4]);

    //         var query = EntityQuery.from('Logging').where(pred);

    //         this.breezeService.service.executeQuery(query).then((data) => {
    //             resolve(data.results);
    //         });
    //     });
    // }

}