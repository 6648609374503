import { environment } from './../../../../environments/environment';
import { AuthService } from './../../../core/auth/auth.service';
import { BaseComponent } from './../../../shared/base-component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'app/core/user/user.model';
import { UserService } from 'app/core/user/user.service';
import { SignalRServiceBase } from 'app/core/services/signalR/signalR-base.service';
import { UserAccount } from 'app/core/models/entities/UserAccount';
import * as signalR from '@microsoft/signalr';
import { SwUpdate } from '@angular/service-worker';
import { IConfirmationData } from 'app/modules/components/confirmation-dialog/confirmation-dialog.component';
@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'userMenu'
})
export class UserMenuComponent extends BaseComponent implements OnInit, OnDestroy {
    static ngAcceptInputType_showAvatar: BooleanInput;

    @Input()
    showAvatar: boolean;

    // Private

    public isBus = false;
    private _user: UserAccount;
    public status = 'busy';
    public statusText = 'Disconnected';
    public imageURL = this._userService.userImageURL;
    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {Router} _router
     * @param {UserService} _userService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _userService: UserService,
        private _signalRBase: SignalRServiceBase,
        private _userAuth: AuthService,
        private _sw: SwUpdate
    ) {
        super();
        // Set the private defaults




        // Set the defaults
        this.showAvatar = true;

        this._signalRBase.liveStatus.pipe(takeUntil(this.unsubscribeAll)).subscribe((signalRstatus) => {
            if (signalRstatus === signalR.HubConnectionState.Connected) {
                this.status = 'online';
                this.statusText = 'Online';
            }
            else if (signalRstatus === signalR.HubConnectionState.Reconnecting) {
                this.status = 'reconnecting';
                this.statusText = 'Reconnecting...';
            }
            else if (signalRstatus === signalR.HubConnectionState.Disconnected) {
                this.status = 'disconnected';
                this.statusText = 'Disconnected';
            }
            this._changeDetectorRef.markForCheck();
        });

        this._ge.userImageChanged$.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
            this.refresh = (new Date()).getTime();
        });

        this._sw.available.pipe(takeUntil(this.unsubscribeAll)).subscribe(event => {
            this._noty.YesNoDialog('Update Available', "An update is available do you want to update?").afterClosed().subscribe((data: IConfirmationData) => {
                if (data.yesNo) {
                    this._sw.activateUpdate().then(() => document.location.reload());
                }
            })
        });



    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    // @Input()
    // set user(value: UserAccount) {

    //     // Save the user
    //     this._user = value;

    //     // Store the user in the service
    //     this._userService.user = value;

    //     this.imageURL = this._userService.getImage;
    // }

    get user(): UserAccount {
        return this._user;
    }

    @Input()
    set refresh(value: any) {

        this.imageURL = this._userService.getImage;
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {



        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((user: UserAccount) => {
                console.log(user);
                this._user = user;
                this.imageURL = this._userService.getImage;
                this._changeDetectorRef.markForCheck();
            });

        if (this._router.url.indexOf('/bus') >= 0) {
            this.isBus = true;

        }


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    version(): void {
        this._noty.YesNoDialog("Calendar 5 Info", "Running version:" + environment.version);
    }

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status): void {
        // Update the user data
        // this.user.status = status;

        // Update the user on the server
        // this._userService.update(this.user);
    }

    checkForUpdates(): void {
        this._sw.checkForUpdate();

    }
    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }
}
