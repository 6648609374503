
/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class BookingSlot {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    bookingSlotId: string;
    fridayIsAvailable: boolean;
    locationId: string;
    mondayIsAvailable: boolean;
    saturdayIsAvailable: boolean;
    sundayIsAvailable: boolean;
    thursdayIsAvailable: boolean;
    timeSlotEnd: string;
    timeSlotStart: string;
    timeslot: string;
    tuesdayIsAvailable: boolean;
    wednesdayIsAvailable: boolean;
}

