
/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MedicalAid {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    medicalAidId: string;
    medicalAid1: string;
}

