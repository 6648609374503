
export enum UserType {
  ADMIN = 'ADMIN',
  BUSINESS = 'BUSINESS',
  CALADMIN = "CALADMIN",
  USER = "USER"
}

export class UserAuth {

  title: string;
  username: string;
  uid: string;
  firstname: string;
  lastname: string;
  type: UserType;
  roles: string[];
  token: string;
  expiry: string;
  expires_in: string;
  permissions: string[];


  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}

export interface NewUser {

}


export class TokenAuth {
  username: string;
  password: string;
  grant_type: string;
}

export class FBToken {
  fbToken: string;
}


// new JProperty("username", user.Username),
// new JProperty("token", accessToken),
// new JProperty("uid", user.UserId.ToString()),
// new JProperty("firstname", user.Firstname),
// new JProperty("lastname", user.Lastname),
// new JProperty("type", user.UserRole),
// new JProperty("roles", Newtonsoft.Json.JsonConvert.SerializeObject(jroles.Select(x => x.Value))),
// new JProperty("access_token", accessToken),
// new JProperty("token_type", "bearer"),
// new JProperty("expires_in", DateTime.Now.AddDays(TimeSpan.FromDays(60).Days)));
