import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { AppSettings } from '../services/app-settings.service';
import { Appointment } from '../models/entities/EntityModel';

export interface ICalendarSelectionUpdateData {
    start: Date;
    end: Date;
    allDay: boolean;
    resourceId: string;
}

@Injectable()
export class GlobalCalendarEventsService {


    //     // this class provides communication back to the scheduler component when a service needs to update the 

    private addAppointmentSubject = new Subject<Appointment>();
    private updateAppointmentSubject = new Subject<Appointment>();
    private deleteAppointmentSubject = new Subject<string>();


    // Observable string streams
    public addAppointment$ = this.addAppointmentSubject.asObservable();
    public updateAppointment$ = this.updateAppointmentSubject.asObservable();
    public deleteAppointment$ = this.deleteAppointmentSubject.asObservable();


    // private afterSaveAddAppointmentSubject = new Subject<Appointment>();
    // private afterSaveUpdateAppointmentSubject = new Subject<Appointment>();
    // private afterSaveDeleteAppointmentSubject = new Subject<string>();


    // // Observable string streams
    // public afterSaveAddAppointment$ = this.addAppointmentSubject.asObservable();
    // public afterUpdateAppointment$ = this.updateAppointmentSubject.asObservable();
    // public afterDeleteAppointment$ = this.deleteAppointmentSubject.asObservable();


 
  



    private changeDateSubject = new Subject<Date>();
    private changeViewSubject = new Subject<string>();
    private clearSelectionSubject = new Subject<boolean>();
    private nextButtonSubject = new Subject();
    private previousButtonSubject = new Subject();
    private showFullViewSubject = new Subject<boolean>();
    private slotSizeSubject = new BehaviorSubject<string>(this._appSettings.calSlotSize);
    private showDeletedSubject = new BehaviorSubject<boolean>(this._appSettings.calShowDeleted);
    private changeAfterDateDataSubject = new Subject<any>();
    private selectionUpdate = new Subject<ICalendarSelectionUpdateData>();

    private calendarLoadedSubject = new BehaviorSubject<boolean>(false);
    // private calendarSettings = new Subject<boolean>();

    public changeDate$ = this.changeDateSubject.asObservable();
    public changeView$ = this.changeViewSubject.asObservable(); // this changes the actual view (day,agenda,month)
    public changeAfterDateData$ = this.changeAfterDateDataSubject.asObservable(); // this emits the view after the date has changed. After a date range
    public nextButton$ = this.nextButtonSubject.asObservable();
    public previousButton$ = this.previousButtonSubject.asObservable();
    public clearSelection$ = this.clearSelectionSubject.asObservable();
    public showFullView$ = this.showFullViewSubject.asObservable(); // change the start end end time of the calendar from full day to half day.
    public slotSize$ = this.slotSizeSubject.asObservable();
    public showDeleted$ = this.showDeletedSubject.asObservable();
    public selectionUpdate$ = this.selectionUpdate.asObservable();
    public calendarLoadedSubject$ = this.calendarLoadedSubject.asObservable();
    // public calendarSettings$ = this.calendarSettings.asObservable();


    constructor(private _appSettings: AppSettings) {

    }


    public addAppointment(appointment: Appointment): void {
        this.addAppointmentSubject.next(appointment);
    }

    public updateAppointment(appointment: Appointment): void {
        this.updateAppointmentSubject.next(appointment);
    }

    public deleteAppointment(id: string): void {
        this.deleteAppointmentSubject.next(id);
    }

    // public afterSaveAddAppointment(appointment: Appointment): void
    // {
    //     this.afterSaveAddAppointmentSubject.next(appointment);
    // }

    // public afterSaveUpdateAppointment(appointment: Appointment): void
    // {
    //     this.afterSaveUpdateAppointmentSubject.next(appointment);
    // }

    // public afterSavedeleteAppointment(id: string): void
    // {
    //     this.afterSaveDeleteAppointmentSubject.next(id);
    // }



    // this method changes the size of the selection on the calendar - used when the date time selection is changed.
    public updateSelection(data: ICalendarSelectionUpdateData): void {
        this.selectionUpdate.next(data);
    }


    public changeDate(date: Date): void {

        this.changeDateSubject.next(date);
    }
    public changeView(view: string): void {
        // console.log("changing date about to push subject");
        this.changeViewSubject.next(view);
    }

    // clears the calendar selection.
    public clearSelection(): void {
        this.clearSelectionSubject.next(true);
    }

    // event that fires after the date on the calendar has changed.
    public dateChangedEvent(date: any): void // any was View
    {
        this._appSettings.calDate = date.currentStart;
        this.changeAfterDateDataSubject.next(date);
    }

    public nextDate(): void {
        this.nextButtonSubject.next(null);
    }
    public previousDate(): void {
        this.previousButtonSubject.next(null);
    }

    public showFullView(value: boolean): void {
        this.showFullViewSubject.next(value);
    }

    public slotSize(value: string): void {
        this.slotSizeSubject.next(value);
    }

    public calendarLoaded(value: boolean): void {
        this.calendarLoadedSubject.next(value);
    }

    public showDeleted(value: boolean): void {
        this.showDeletedSubject.next(value);
    }

    // STUBS THAT NEED TO BE REFACTORED

}
