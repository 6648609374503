import { Business } from './Business';
import { Client } from './Client';
import { InvoiceItems } from './InvoiceItems';
import { core, Entity } from 'breeze-client';
import { Appointment } from './Appointment';
import { EntityBase } from './EntityBase';
import { AppointmentInvoice } from './AppointmentInvoice';
/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Invoice extends EntityBase {

    /// <code> Place custom code between <code> tags

    constructor() {
        super();
        this.invoiceId = core.getUuid();
    }


    /// </code>

    // Generated code. Do not place code below this line.
    invoiceId: string;
    businessId: string;
    clientId: string;
    date: Date;
    discountAmount: number;
    discountPercentage: number;
    discountType: number;
    invoiceNo: number;
    status: number;
    total: number;
    type: number;
    appointmentInvoice: AppointmentInvoice[];
    business: Business;
    client: Client;
    invoiceItems: InvoiceItems[];

    initializer(entity: Appointment): void {

    }

    public addInvoiceItems(): InvoiceItems {
        return this.entityAspect.entityManager.createEntity('InvoiceItems',
            {
                invoiceItemId: core.getUuid(),
                amount: 0, description: '',
                icd: 0,
                invoiceId: this.invoiceId,
                item: '',
                medicalIdCode: '',
                discount: 0,
                productId: null,
                quantity: 1,
                tax: 15,
                editMode: true
            }) as InvoiceItems;
    }



    
}
