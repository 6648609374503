import { Business } from './Business';
import { Client } from './Client';
import { Entity } from 'breeze-client';
import { EntityBase } from './EntityBase';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Message extends EntityBase {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    messageId: string;
    businessId: string;
    clientId: string;
    concatNumSegments: string;
    concatSeqNum: string;
    contactReference: string;
    date: Date;
    direction: string;
    firstname: string;
    isRead: boolean;
    lastname: string;
    messageContent: string;
    msgId: number;
    msisdn: string;
    referringBatchId: string;
    referringMsgId: string;
    telephone: string;
    type: string;
    business: Business;
    client: Client;
}
export interface IMessage extends Message {}
export interface IBreezeMessage extends Entity, IMessage { }
