import { BaseComponent } from './../../../../shared/base-component';
import { AuthService } from 'app/core/auth/auth.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TreoMediaWatcherService } from '@treo/services/media-watcher';
import { TreoNavigationService } from '@treo/components/navigation';
import { InitialData } from 'app/app.types';
import { TreoSplashScreenService } from '@treo/services/splash-screen/splash-screen.service';

import { defaultNavigation, signedInNavigation } from './navigation.data'
import { UserService } from 'app/core/user/user.service';
import { UserAccount } from 'app/core/models/entities/UserAccount';

@Component({
    selector: 'modern-layout',
    templateUrl: './modern.component.html',
    styleUrls: ['./modern.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModernLayoutComponent extends BaseComponent implements OnInit, OnDestroy {
    data: InitialData;
    signedIn$ = this._auth.check();
    user: UserAccount;
    navigation = defaultNavigation;
    signedInNavigation = signedInNavigation;




    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _auth: AuthService,
        private _userService: UserService,
        private _treoNavigationService: TreoNavigationService,
        private _treoSplash: TreoSplashScreenService
    ) {
        super();
        this.signedIn$ = this._auth.check();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._treoSplash.hide();
        // Subscribe to the resolved route mock-api
        this._activatedRoute.data.pipe(takeUntil(this.unsubscribeAll)).subscribe((data: Data) => {
            this.data = data.initialData;
        });

        this._ge.serverAnnounceLogout$.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
            this.signedIn$ = this._auth.check();
        })

        this._userService.user$.pipe(takeUntil(this.unsubscribeAll)).subscribe((user) => {
            this.user = user
        })



    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        super.ngOnDestroy();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._treoNavigationService.getComponent(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
