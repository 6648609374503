export const environment = {
    production: true,
    api_path: 'https://api.calendar5.com/api',
    signalR_path: 'https://api.calendar5.com/signalr',
    // api_path: 'https://localhost:5001/api',
    // signalR_path: 'https://localhost:5001/signalr',
    zoomClientId: '0uj_Tf46TmiSInqyIGj6hQ',
    zoomRedirectUri: window.location.origin + '/zoom-register',
    version: '1.0.2',
    recurringEndDate: '2040-01-01',
    facebookAppId: '1233673223738046',
    payfastServer: 'https://sandbox.payfast.co.za/eng/process',
    payfastMerchantId: '10022519',
    payfastReturnUrl: 'https://0418c97f9e54.ngrok.io/api/payfast/success', //'https://19a3c5f62a40.ngrok.io/api/payfast/success',
    payfastCancelUrl: 'https://0418c97f9e54.ngrok.io/api/payfast/cancel',
    payfastNotifyUrl: 'https://0418c97f9e54.ngrok.io/api/payfast/info'
    // api_path: 'https://localhost:5001/api',
    // signalR_path: 'https://localhost:5001/signalr',
    // version: '10.0.1'
};
