import { EntityBase } from './EntityBase';
import { Business } from './Business';
import { Entity } from 'breeze-client';
import { InvoiceItems } from './InvoiceItems';
import { LocationService } from './LocationService';
import { StaffService } from './StaffService';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Service extends EntityBase {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    serviceId: string;
    businessId: string;
    deleted: boolean;
    description: string;
    enabled: boolean;
    icdCode: string;
    isService: boolean;
    listOrder: number;
    name: string;
    price: number;
    serviceTimeMinutes: number;
    showOnline: boolean;
    tarrifCode: string;
    taxable: boolean;
    vat: number;
    business: Business;
    invoiceItems: InvoiceItems[];
    locationService: LocationService[];
    staffService: StaffService[];

}
export interface IService extends Service {}
export interface IBreezeService extends Entity, IService { }
