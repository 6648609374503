// import { Route } from '@angular/router';
// import { AuthGuard } from 'app/core/auth/guards/auth.guard';
// import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
// import { LayoutComponent } from 'app/layout/layout.component';
// import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
// tslint:disable:max-line-length

import { EmptyLayoutComponent } from 'app/layout/layouts/empty/empty.component';
import { Service } from './core/models/entities/Service';
import { CheckUser, CloseSplash, InitialAppStartResolver, InitialBlankPageRedirect, InitialEntityResolver, InitialPageRedirect, PreInitCheck } from './app.resolvers';
import { AuthRoleGuard } from './core/auth/guards/role.guard';
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';

import { AuthGuardUser } from 'app/core/auth/guards/auth.guard.user';

import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', children: [], canActivate: [InitialBlankPageRedirect] },

    // Redirect signed in user to the '/example'
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '/bus/calendar' },
    { path: 'signed-in-redirect-user', pathMatch: 'full', redirectTo: '/private/account' },



    //Standard landing pages for desktop view - has a toolbar at the top.
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'modern'
        },
        canActivate: [CheckUser],
        children: [
            { path: 'home', loadChildren: () => import('app/modules/public/home/home.module').then(m => m.LandingHomeModule), canActivate: [InitialPageRedirect] },
            { path: 'business', loadChildren: () => import('app/modules/public/business-info/business-info.module').then(m => m.BusinessInfoModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
            { path: 'register-business', loadChildren: () => import('app/modules/auth/sign-up-business/sign-up-business.module').then(m => m.AuthSignUpBusinessModule) },
            { path: 'sign-up-business', loadChildren: () => import('app/modules/public/sign-up-business/sign-up-business.module').then(m => m.SignUpBusinessModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'about', loadChildren: () => import('app/modules/public/about/about.module').then(m => m.AboutModule) },
            { path: 'activate/:user/:code', loadChildren: () => import('app/modules/auth/activate/activate.module').then(m => m.ActivateModule) },
            { path: 'password-reset/:user/:code', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'search', loadChildren: () => import('app/modules/public/search/search.module').then(m => m.SearchModule) },
           
            // { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            // { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            // { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) },
        ]
    },



    //Homepage that looks more like an 'app' no toolbars etc.

    {
        path: '',
        component: LayoutComponent,
        canActivate: [CheckUser],
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home-mobile-app', loadChildren: () => import('app/modules/public/home/home.module').then(m => m.LandingHomeModule) },
        ]
    },

    // This is a sign-out used for non-business users.
    // Sign-out for business needs to clear out the breeze cache but breeze is not loaded for front-end users, only tokens.
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out-simple', loadChildren: () => import('app/modules/auth/sign-out-simple/sign-out-simple.module').then(m => m.AuthSignOutSimpleModule) },
        ]
    },


    //private pages for front-end
    {
        path: 'private',
        canActivate: [CheckUser, AuthGuardUser],
        canActivateChild: [AuthGuardUser],
        component: LayoutComponent,
        data: {
            layout: 'modern'
        },
        children: [
            { path: 'account', loadChildren: () => import('app/modules/public/private/account/account-home.module').then(m => m.AccountHomeModule) },
        ]
    },

    //----------------------------------------------------
    //Business Routes
    //----------------------------------------------------

    {
        path: 'bus',
        canActivate: [AuthGuard],
        children: [{

            path: '',
            canActivate: [InitialEntityResolver],
            children: [{

                path: '',
                canActivate: [InitialAppStartResolver],
                component: LayoutComponent,
                resolve: {
                    initialData: InitialDataResolver,
                },
                children: [


                    { path: 'calendar', loadChildren: () => import('app/modules/business/calendar/calendar.module').then(m => m.CalendarModule) },
                    { path: 'user', loadChildren: () => import('app/modules/business/user/user.module').then(m => m.UserModule) },
                    {
                        path: 'dashboards',
                        children: [
                            { path: 'finance', loadChildren: () => import('app/modules/business/dashboards/finance/finance.module').then(m => m.FinanceModule) },
                        ]
                    },
                    {
                        path: 'apps', children: [
                            { path: 'contacts', loadChildren: () => import('app/modules/business/contacts/contacts.module').then(m => m.ContactsModule) },
                            { path: 'locations', loadChildren: () => import('app/modules/business/locations/locations.module').then(m => m.LocationsModule) },
                            { path: 'staff', loadChildren: () => import('app/modules/business/staff/staff.module').then(m => m.StaffModule) },
                            { path: 'business', loadChildren: () => import('app/modules/business/business/business.module').then(m => m.BusinessModule) },
                            { path: 'notifications', loadChildren: () => import('app/modules/business/notifications/notifications.module').then(m => m.NotificationsModule) },
                            { path: 'services', loadChildren: () => import('app/modules/business/services/services.module').then(m => m.ServicesModule) },
                            { path: 'sharing', loadChildren: () => import('app/modules/business/sharing/sharing.module').then(m => m.SharingModule) },
                            { path: 'tags', loadChildren: () => import('app/modules/business/tags/tags.module').then(m => m.TagsModule) },
                            { path: 'invoice', loadChildren: () => import('app/modules/business/invoice/invoice.module').then(m => m.InvoiceModule) },
                            { path: 'search', loadChildren: () => import('app/modules/business/search/search.module').then(m => m.SearchModule) },
                            {path: 'settings', loadChildren: () => import('app/modules/business/settings/settings.module').then(m => m.SettingsModule)},
                        ]
                    },
                ]
            }]
        }]
    },

    //special pages for business that do not use a template.
    {
        path: '',
        canActivate: [AuthGuard, PreInitCheck],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            // { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) },
            { path: 'zoom-register', loadChildren: () => import('app/modules/auth/zoom-register/zoom-register.module').then(m => m.ZoomRegisterModule) }
        ]
    },



    //--------------------------------------
    // Default redirects for missing / other
    //--------------------------------------

    { path: '404-not-found', canActivate: [CloseSplash], pathMatch: 'full', loadChildren: () => import('app/modules/pages/errors/error-404/error-404.module').then(m => m.Error404Module) },
    { path: '**', redirectTo: '404-not-found' }
];


