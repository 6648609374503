import { SortDirection } from '@angular/material/sort';
import { Entity, EntityManager, EntityQuery, EntityType, FetchStrategy, Predicate, MergeStrategy, OrderByClause } from 'breeze-client';
import { Console } from 'console';

export interface IRepository<T> {
    inlineCount: number;
    withId(key: any, localFirst: boolean): Promise<T>;
    withIdInCache(key: any): T;
    where(predicate: Predicate, orderBy?: { orderBy: string, sortDirection: 'asc' | 'desc' | '' }, skipTake?: { take: number, skip: number }, fetchStrategy?: FetchStrategy, mergeStrategy?: MergeStrategy, expand?: string[], inlineCount?: boolean, params?: any): Promise<T[]>;
    whereEndpointParams(endpoint: string, params: any, orderBy?: { orderBy: string, sortDirection: 'asc' | 'desc' | SortDirection }, skipTake?: { take: number, skip: number }, inlineCount?: boolean, predicate?: Predicate, expand?: string[],): Promise<T[]>;
    whereInCache(predicate: Predicate): T[];
    expand(entities: string[]): Promise<T[]>;
    all(forceRemote?: FetchStrategy): Promise<T[]>;
    getEntities(): T[];
}

export class Repository<T> implements IRepository<T> {

    public inlineCount: number;
    private _resourceNameSet: boolean;
    protected _defaultFetchStrategy: FetchStrategy;

    constructor(private _manager: EntityManager,
        protected _entityTypeName: string,
        protected _resourceName: string,
        protected _isCachedBundle: boolean = false) {

        this._defaultFetchStrategy = _isCachedBundle ? FetchStrategy.FromLocalCache : FetchStrategy.FromServer;
    }

    protected get manager(): EntityManager {
        if (this._resourceNameSet) { return this._manager; }
        const metadataStore = this._manager.metadataStore;

        const entityType = metadataStore.getAsEntityType(this._entityTypeName || '', true);
        if (entityType) {
            entityType.setProperties({ defaultResourceName: this.localResourceName });
            metadataStore.setEntityTypeForResourceName(this.localResourceName, entityType);
        }

        return this._manager;
    }

    protected get localResourceName(): string {
        return this._isCachedBundle ? this._entityTypeName : this._resourceName;
    }

    withId(key: any, localFirst: boolean = true): Promise<T> {


        if (!this._entityTypeName) {
            throw new Error('Repository must be created with an entity type specified');
        }

        if (this._entityTypeName == 'Client') {

            console.warn('Not valid for Client due to parameter security reasons')
        }

        return this.manager.fetchEntityByKey(this._entityTypeName, key, localFirst)
            .then(data => {
                return data.entity;
            }).catch(e => {
                if (e.status === 404) {
                    return null;
                }
                // Something else happened
                throw e;
            });
    }

    withIdInCache(key: any): T {
        if (!this._entityTypeName) {
            throw new Error('Repository must be created with an entity type specified');
        }
        return this.manager.getEntityByKey(this._entityTypeName, key) as any as T;

    }




    where(predicate: Predicate, orderBy?: { orderBy: string, sortDirection: 'asc' | 'desc' }, skipTake?: { take: number, skip: number }, fetchStrategy?: FetchStrategy, mergeStrategy: MergeStrategy = MergeStrategy.OverwriteChanges, expand?: string[], inlineCount?: boolean, params?: any): Promise<T[]> {
        console.log('query');
        let query = this.baseQuery().where(predicate).using(mergeStrategy).expand(expand);

        if (orderBy) {
            query = query.orderBy(orderBy.orderBy + ' ' + orderBy.sortDirection);
        }

        if (skipTake) {
            query = query.skip(skipTake.skip);
            query = query.take(skipTake.take);
        }

        if (expand) {
            query = query.expand(expand);
        }

        if (skipTake || inlineCount) {
            query = query.inlineCount(true);
        }

        if (params) {
            query = query.withParameters(params);
        }


        if (fetchStrategy) {
            return this.executeQuery(query, fetchStrategy);
        }
        else {
            return this.executeQuery(query);
        }
    }

    whereEndpointParams(endpoint: string, params?: any, orderBy?: { orderBy: string, sortDirection: 'asc' | 'desc' }, skipTake?: { take: number, skip: number }, inlineCount?: boolean, predicate?: Predicate, expand?: string[]): Promise<T[]> {
        let query = EntityQuery.from(endpoint);
        console.log('query');

        if (orderBy) {
            query = query.orderBy(orderBy.orderBy + ' ' + orderBy.sortDirection);
        }

        if (predicate) {
            query = EntityQuery.from(endpoint).where(predicate);
        }

        if (skipTake) {
            query = query.skip(skipTake.skip);
            query = query.take(skipTake.take);
        }


        if (inlineCount) {
            query = query.inlineCount();
        }

        if (expand) {
            query = query.expand(expand);
        }

        if (params) {
            query = query.withParameters(params);
        }

        return this.executeQuery(query, FetchStrategy.FromServer);
    }

    expand(entities: string[]): Promise<T[]> {
        const query = this.baseQuery().expand(entities);
        return this.executeQuery(query, FetchStrategy.FromServer);
    }

    whereInCache(predicate: Predicate): T[] {
        const query = this.baseQuery().where(predicate);

        return this.executeCacheQuery(query);
    }

    all(fetchStrategy?: FetchStrategy): Promise<T[]> {
        const query = this.baseQuery();
        if (fetchStrategy) {
            return this.executeQuery(query, fetchStrategy);
        }
        else {
            return this.executeQuery(query);
        }
    }

    getEntities(): T[] {
        return this.manager.getEntities(this._entityTypeName) as any as T[];
    }


    protected baseQuery(): EntityQuery {
        return EntityQuery.from(this.localResourceName);
    }

    protected executeQuery(query: EntityQuery, fetchStrategy?: FetchStrategy): Promise<T[]> {
        const q = query.using(fetchStrategy || this._defaultFetchStrategy);
        return this.manager.executeQuery(q).then(data => {

            if (data.hasOwnProperty('inlineCount')) {
                this.inlineCount = data.inlineCount;
            }

            return data.results as any as T[];
        }).catch(e => {
            if (e.status === 404) {
                return [] as T[];
            }
            console.log(e);
            // Something else happend, rethrow the exception
            throw e;
        });
    }

    protected executeCacheQuery(query: EntityQuery): T[] {
        return this.manager.executeQueryLocally(query) as any as T[];
    }
}
