
import { MatIconModule } from '@angular/material/icon';
import { BusinessSelectorComponent } from './business-selector.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { AppAvatarIconModule } from '../app-avatar-icon/app-avatar-icon.module';



@NgModule({
  declarations: [
    BusinessSelectorComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    AppAvatarIconModule
  ],
  exports: [
    BusinessSelectorComponent
  ]
})
export class BusinessSelectorModule { }