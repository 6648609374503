import { environment } from 'environments/environment';

import { ISignalRAppointment } from 'app/core/models/entity-objects';
import { SupportServices } from './../../services/support.service';
import RRule, { Frequency, RRuleSet, rrulestr } from 'rrule';
import { Business } from './Business';
import { Client } from './Client';
import { Location } from './Location';
import { AppointmentTag } from './AppointmentTag';
import { Log } from './Log';
import { Staff } from './Staff';
import { core } from 'breeze-client';
import { AppointmentInvoice } from './AppointmentInvoice';
import { CustomStatus } from './CustomStatus';
/// <code-import> Place custom imports between <code-import> tags
import { EntityBase } from './EntityBase';
import { Subject } from 'rxjs';
import { Zoom } from './Zoom';
import { DateTime, Interval } from 'luxon';

import { Reminder } from './Reminder';
import { env } from 'process';
import { rangeRight } from 'lodash';
/// </code-import>

export interface IStatusInfo {
    text: string;
    id: number;
}

export class Appointment extends EntityBase {


    constructor() {
        super();
        this.appointmentId = core.getUuid();
        this.description = '';
        this.subject = '';
        this.email = '';
        this.telephone = '';
        this.label = 3;
        this.status = 3;
        this.allDay = false;
        this.creationDate = new Date();
        this.lastUpdated = new Date();
        this.colorId = '8';
        this.isConfirmed = false;
        this.recurringEndDate = new Date(Date.parse(environment.recurringEndDate));
    }

    private clientChanged = new Subject<string>();
    clientChanged$ = this.clientChanged.asObservable();

    /// <code> Place custom code between <code> tags
    valid = true;     // boolean telling if this appointment is valid;
    validReason = ''; // error message for why invalid;
    statusDescription = ''; // A description for the status code;
    recurrenceInstanceStart: Date = null;
    rruleText: string;
    clientRef: Client;
    zoomRef: Zoom;
    /// </code>



    // Generated code. Do not place code below this line.
    appointmentId: string;
    allDay: boolean;
    businessId: string;
    clientId: string;
    colorId: string;
    creationDate: Date;
    customField: string;
    description: string;
    email: string;
    endDate: Date;
    firstname: string;
    invoiceId: string;
    isConfirmed: boolean;
    isRecurring: boolean;
    isZoom: boolean;
    label: number;
    lastUpdated: Date;
    lastname: string;
    locationId: string;
    recurrenceInfo: string;
    recurringEndDate: Date;
    reminderInfo: string;
    serviceType: string;
    smsBatchId: string;
    smsStatus: number;
    staffId: string;
    startDate: Date;
    status: number;
    subject: string;
    telephone: string;
    telephoneCountry: string;
    userAccountId: string;
    appointmentInvoice: AppointmentInvoice[];
    appointmentTag: AppointmentTag[];
    business: Business;
    client: Client;
    invoice: AppointmentInvoice;
    location: Location;
    log: Log[];
    reminder: Reminder[];
    staff: Staff;
    zoom: Zoom;

    public static calculateStatus(label: any, status: any, date: any): IStatusInfo {
        const now = new Date();
        const statusObj: any = {};
        if (label > 0 && (date > now)) {
            switch (status) {
                case 1:
                    statusObj.text = 'Appointment Approved';
                    statusObj.id = 0;
                    break;
                case 2:
                    statusObj.text = 'Appointment Approved';
                    statusObj.id = 0;
                    break;
                case 10:
                    statusObj.text = 'Awaiting Approval';
                    statusObj.id = 1;
                    break;
                default:
                    statusObj.text = 'Appointment Approved';
                    statusObj.id = 0;
                    break;
            }
        }
        else if (label > 0) {
            switch (status) {
                case 1:
                    statusObj.text = 'Attended';
                    statusObj.id = 2;
                    break;
                case 2:
                    statusObj.text = 'Attended';
                    statusObj.id = 2;
                    break;
                case 10:
                    statusObj.text = 'No response';
                    statusObj.id = 3;
                    break;
            }
        }
        else if (label < 0) {

            switch (label) {
                case -1:
                    statusObj.text = 'Client Cancelled';
                    statusObj.id = 4;
                    break;
                case -2:
                    statusObj.text = 'No Show';
                    statusObj.id = 5;
                    break;
                case -3:
                    statusObj.text = 'You Declined';
                    statusObj.id = 6;
                    break;
                case -4:
                    statusObj.text = 'Client Cancelled Online';
                    statusObj.id = 7;
                    break;
                default:
                    statusObj.text = 'Cancelled';
                    statusObj.id = 8;
                    break;
            }
        }

        return statusObj;
    }

    initializer(entity: Appointment): void {


        // check to see if there an count or end date.

    }

    addClient(client?: Client): Client {


        if (client) {
            this.clientRef = client;
        }
        else {
            this.clientRef = this.entityAspect.entityManager.createEntity('Client') as Client;
        }

        this.clientRef.clientId = core.getUuid();
        this.clientRef.businessId = this.businessId;
        this.clientRef.globalUserId = SupportServices.EmptyGuid;

        this.clientId = this.clientRef.clientId;

        // Update appointment to indicate client
        this.colorId = '9';
        this.label = 1;
        this.status = 1;

        this.clientChanged.next(null);
        return this.clientRef;
    }

    addTag(tagId: string): void {
        console.log('adding');
        if (this.isRecurring) {
            const newTag = this.entityAspect.entityManager.createEntity('AppointmentTag', { appointmentId: this.appointmentId, appointmentDate: this.recurrenceInstanceStart, tagId: tagId, appointmentTagId: core.getUuid() }) as AppointmentTag;
            if (newTag.entityAspect.hasValidationErrors) {
                console.log(newTag.entityAspect.getValidationErrors());
                // newTag.entityAspect.rejectChanges();
            }
        }
        else {
            const newTag = this.entityAspect.entityManager.createEntity('AppointmentTag', { appointmentId: this.appointmentId, appointmentDate: this.startDate, tagId: tagId, appointmentTagId: core.getUuid() }) as AppointmentTag;
            if (newTag.entityAspect.hasValidationErrors) {
                console.log(newTag.entityAspect.getValidationErrors());
                // newTag.entityAspect.rejectChanges();
            }
        }
    }

    getTags(): AppointmentTag[] {

        if (this.isRecurring) {
            if (!this.recurrenceInstanceStart) {
                console.log('There object is not initiated correctly. Recurrance start is empty and its recurring. Tags will not work.');
            }
            else {
                return this.appointmentTag.filter((item) => {

                    let recurrence = DateTime.fromJSDate(this.recurrenceInstanceStart).startOf('day');
                    let tagDate = DateTime.fromJSDate(item.appointmentDate).startOf('day');

                    let result = (recurrence.year == tagDate.year && recurrence.month == tagDate.month && recurrence.day == tagDate.day);

                    return result;
                })
            }
        }
        else
            return this.appointmentTag;
    }


    getInvoices(): AppointmentInvoice[] {
        console.log('getInvoices method');
        if (this.isRecurring) {
            if (!this.recurrenceInstanceStart) {
                console.log('There object is not initiated correctly. Recurrance start is empty and its recurring. Invoices will not work.');
            }
            else {
                return this.appointmentInvoice.filter((item) => {

                    let recurrence = DateTime.fromJSDate(this.recurrenceInstanceStart).startOf('day');
                    let invoiceDate = DateTime.fromJSDate(item.appointmentDate).startOf('day');

                    let result = (recurrence.year == invoiceDate.year && recurrence.month == invoiceDate.month && recurrence.day == invoiceDate.day);

                    return result;
                })
            }
        }
        else
            return this.appointmentInvoice;
    }

    addInvoice(invoiceId: string): void {
        console.log('adding');
        if (this.isRecurring) {
            const newInvoice = this.entityAspect.entityManager.createEntity('AppointmentInvoice', { appointmentId: this.appointmentId, appointmentDate: this.recurrenceInstanceStart, invoiceId: invoiceId, invoiceAppointmentId: core.getUuid() }) as AppointmentInvoice;
            if (newInvoice.entityAspect.hasValidationErrors) {
                console.log(newInvoice.entityAspect.getValidationErrors());
                // newTag.entityAspect.rejectChanges();
            }
        }
        else {
            const newInvoice = this.entityAspect.entityManager.createEntity('AppointmentInvoice', { appointmentId: this.appointmentId, appointmentDate: this.startDate, invoiceId: invoiceId, invoiceAppointmentId: core.getUuid() }) as AppointmentInvoice;
            if (newInvoice.entityAspect.hasValidationErrors) {
                console.log(newInvoice.entityAspect.getValidationErrors());
                // newTag.entityAspect.rejectChanges();
            }
        }
    }

    getRRule(): string {
        const rRule = RRule.fromString(this.recurrenceInfo);
        return rRule.toText();
    }

    rejectChanges():void {
      let _recurrenceStart = this.recurrenceInstanceStart;

      this.entityAspect.rejectChanges();

      this.recurrenceInstanceStart = _recurrenceStart;
    }


    setClient(clientId: string): void {

        this.clientId = clientId;
        this.colorId = '9';
        this.label = 1;
        this.status = 1;
        this.clientChanged.next(null);
    }

    removeClient(): void {

        this.client = null; // new Client();
        this.clientId = null;
        this.label = 3;
        this.status = 3;
        delete this.client; // ?? dunno if this works better than this.client = null;
        this.clientChanged.next(null);
    }

    // addZoom(password: string = ''): void {
    //     console.log('Adding zoom meeting');
    //     const dStart = DateTime.fromJSDate(this.startDate);
    //     const dEnd = DateTime.fromJSDate(this.endDate);
    //     const interval = Interval.fromDateTimes(dStart, dEnd);

    //     const length = interval.toDuration().toFormat('mm');
    //     this.zoomRef = this.entityAspect.entityManager.createEntity('Zoom', { appointmentId: this.appointmentId }) as Zoom;

    //     this.zoomRef.agenda = this.subject;
    //     this.zoomRef.appointmentId = this.appointmentId;
    //     this.zoomRef.duration = Number(length);
    //     this.zoomRef.startTime = this.startDate;
    //     this.zoomRef.password = password;
    //     this.zoomRef.staffId = this.staffId;
    //     this.zoom = this.zoomRef;
    // }

    setDeleted(permanent?: boolean): void {
        if (permanent) {
            this.label = -10;
            this.status = -10;
        } else {
            this.label = -3;
            this.status = -3;
        }
    }

    getStatus(): IStatusInfo {
        return Appointment.calculateStatus(this.label, this.status, this.startDate);
    }

    toJson(): ISignalRAppointment {

        const item: ISignalRAppointment = {
            appointmentId: this.appointmentId,
            businessId: this.businessId,
            startDate: this.startDate,
            endDate: this.endDate,
            allDay: false,
            subject: this.subject,
            description: this.description,
            status: this.status,
            label: this.label,
            locationId: this.locationId,
            isRecurring: this.isRecurring,
            lastUpdated: new Date(),
            reminderInfo: this.reminderInfo,
            colorId: this.colorId,
            firstname: this.firstname,
            lastname: this.lastname,
            clientId: this.clientId,
            telephone: this.telephone,
            email: this.email,
            isConfirmed: this.isConfirmed,
            creationDate: new Date(),
            customField: this.customField,
            recurrenceInfo: this.recurrenceInfo,
            smsBatchId: this.smsBatchId,
            smsStatus: this.smsStatus,
            staffId: this.staffId,
            client: null
        };

        let clientJson: any;

        if (this.client) {
            clientJson = {
                clientId: this.client.clientId,
                building: this.client.addressBuildingNo,
                businessId: this.client.businessId,
                city: this.client.addressCity,
                customId: this.client.customId,
                date: this.client.date,
                deleted: this.client.deleted,
                email: this.client.email,
                fbId: this.client.fbId,
                firstname: this.client.firstname,
                gender: this.client.gender,
                globalUserId: this.client.globalUserId,
                identityNumber: this.client.identityNumber,
                issue: this.client.issue,
                lastname: this.client.lastname,
                lat: this.client.lat,
                lng: this.client.lng,
                notes: this.client.notes,
                postalCode: this.client.addressPostalCode,
                street: this.client.addressStreet,
                streetNo: this.client.addressStreetNo,
                suburb: this.client.addressSuburb,
                telephone: this.client.telephone,
                telephoneAlternate: this.client.telephoneAlternate
            };



        }

        item['client'] = clientJson;
        console.log(item);
        return item;

    }

    public updateStatusText(label: number, status: number): string {


        if (label === -1) {
            this.statusDescription = 'Client Cancelled';
        } else if (label === -2) {
            this.statusDescription = 'Client No-Show';
        } else if (label === 10) {
            this.statusDescription = 'Approval Needed';
        }

        return this.statusDescription;
        // if (label < 0) {
        //     // disable the appointment - this appointment is deleted.
        //     console.log('Appointment Status:Deleted');
        //     this.canEditStatus = false;
        //     this.canEdit = false;

        // } else {
        //     this.canEditStatus = true;
        // }

    }





}
