import { Appointment } from './Appointment';
import { Business } from './Business';
import { Schedule } from './Schedule';
import { Entity } from 'breeze-client';
import { EntityBase } from './EntityBase';
import { LocationService } from './LocationService';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Location extends EntityBase {

    /// <code> Place custom code between <code> tags

    /// </code>

    // Generated code. Do not place code below this line.
    locationId: string;
    addressBuildingNo: string;
    addressCity: string;
    addressPostalCode: string;
    addressStreet: string;
    addressStreetNo: string;
    addressSuburb: string;
    businessId: string;
    creationDate: Date;
    deleted: boolean;
    directionDescription: string;
    enabledBookings: boolean;
    geo: string;
    googleLocationString: string;
    json: string;
    lat: string;
    latitude: number;
    lng: string;
    longitude: number;
    name: string;
    secretaryEmail: string;
    telephone: string;
    telephoneCountry: string;
    appointment: Appointment[];
    business: Business;
    locationService: LocationService[];
    schedule: Schedule[];
}
