import { TreoNavigationItem } from '@treo/components/navigation';
export const defaultNavigation: TreoNavigationItem[] = [
    {
        id: 'home',
        title: 'CalendarFive',
        // subtitle: 'Business to consumer messaging made easy.',
        type: 'basic',
        classes: { title: 'text-3xl font-semibold tracking-tighter text-default' },
        link: '/home'
        // icon: 'apps',

    },
    {
        id: 'sign-up',
        title: 'For your business',
        // subtitle: 'Business to consumer messaging made easy.',
        type: 'basic',
        icon: 'heroicons_outline:office-building',
        link: '/sign-up-business',
        classes: { title: 'text-default' },
    },
    {
        id: 'search',
        title: 'Make a booking',
        // subtitle: 'Business to consumer messaging made easy.',
        link: '/search',
        type: 'basic',
        icon: 'heroicons_outline:search-circle',
    }


];

export const signedInNavigation: TreoNavigationItem[] = [
    {
        id: 'home',
        title: 'CalendarFive',
        // subtitle: 'Business to consumer messaging made easy.',
        type: 'basic',
        classes: { title: 'text-3xl font-semibold tracking-tighter text-default' },
        link: '/home'
        // icon: 'apps',
    },
    {
        id: 'home',
        title: 'Home',
        // subtitle: 'Business to consumer messaging made easy.',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/private/account',
        classes: { title: 'text-default' },
    },
    {
        id: 'profile',
        title: 'Profile',
        // subtitle: 'Business to consumer messaging made easy.',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/private/profile',
        classes: { title: 'text-default' },
    },
    {
        id: 'appointments',
        title: 'Appointments',
        // subtitle: 'Business to consumer messaging made easy.',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/private/appointments',
        classes: { title: 'text-default' },
    },
    {
        id: 'logout',
        title: 'Sign-out',
        // subtitle: 'Business to consumer messaging made easy.',
        link: '/sign-out-simple',
        type: 'basic',
        icon: 'heroicons_outline:logout',
    }


];

export const signedInBusinessNavigation: TreoNavigationItem[] = [
    {
        id: 'home',
        title: 'CalendarFive',
        // subtitle: 'Business to consumer messaging made easy.',
        type: 'basic',
        classes: { title: 'text-3xl font-semibold tracking-tighter text-default' },
        link: '/home'
        // icon: 'apps',
    },
    {
        id: 'home',
        title: 'Home',
        // subtitle: 'Business to consumer messaging made easy.',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/private/account',
        classes: { title: 'text-default' },
    },
    {
        id: 'profile',
        title: 'Business',
        // subtitle: 'Business to consumer messaging made easy.',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/bus/calendar',
        classes: { title: 'text-default' },
    },
    {
        id: 'logout',
        title: 'Sign-out',
        // subtitle: 'Business to consumer messaging made easy.',
        link: '/sign-out-simple',
        type: 'basic',
        icon: 'heroicons_outline:logout',
    }


];