import { Business } from './Business';
import { Entity } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Specialities {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    specialityId: string;
    alternativeText: string;
    specialityText: string;
    business: Business[];
}
export interface ISpecialities extends Specialities {}
export interface IBreezeInvoice extends Entity, ISpecialities { }
