import { EntityBase } from './EntityBase';
import { Location } from './Location';
import { Staff } from './Staff';
import { Business } from './Business';
/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Schedule extends EntityBase {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    scheduleId: string;
    businessId: string;
    color: string;
    endDate: Date;
    locationId: string;
    staffId: string;
    startDate: Date;
    subject: string;
    business: Business;
    location: Location;
    staff: Staff;
}

