import { AuthService } from 'app/core/auth/auth.service';

import { finalize, tap } from 'rxjs/operators';
// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
// import { forkJoin, Observable } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { InitialData } from 'app/app.types';

// @Injectable({
//     providedIn: 'root'
// })
// export class InitialDataResolver implements Resolve<any>
// {
//     /**
//      * Constructor
//      */
//     constructor(private _httpClient: HttpClient) {
//     }

//     // -----------------------------------------------------------------------------------------------------
//     // @ Public methods
//     // -----------------------------------------------------------------------------------------------------

//     /**
//      * Use this resolver to resolve initial mock-api for the application
//      *
//      * @param route
//      * @param state
//      */
//     resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
//         // Fork join multiple API endpoint calls to wait all of them to finish
//         return forkJoin([
//             // this._httpClient.get<any>('api/common/messages'),
//             this._httpClient.get<any>('api/common/navigation'),
//             // this._httpClient.get<any>('api/common/notifications'),
//             this._httpClient.get<any>('api/common/shortcuts'),
//             this._httpClient.get<any>('api/common/user')
//         ]).pipe(
//             map(([navigation, shortcuts, user]) => ({
//                 navigation: {
//                     compact: navigation.compact,
//                     default: navigation.default,
//                     futuristic: navigation.futuristic,
//                     horizontal: navigation.horizontal
//                 },
//                 // notifications,
//                 shortcuts,
//                 user
//             })
//             )
//         );
//     }
// }

import { UserAccount } from './core/models/entities/UserAccount';
import { HBUnitOfWork } from 'app/core/services/hb-unit-of-work';
import { AppControlService } from './core/services/app-control.service';
import { EntityManagerProvider } from './core/services/data-access/entity-manager-provider';


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, CanActivateChild, CanActivate } from '@angular/router';
import { forkJoin, Observable, concat, combineLatest, from, of } from 'rxjs';
import { map, take, first, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { GlobalEvents } from './core/events/events';
import { InitialData } from 'app/app.types';
import { notifications } from './mock-api/common/notifications/data';
import { SupportServices } from './core/services/support.service';
import { TreoSplashScreenService } from '@treo/services/splash-screen/splash-screen.service';
import { UserService } from './core/user/user.service';
import { AppSettings } from './core/services/app-settings.service';



// 1. Creates the base entity model. Order of 1, 2 and 3 important.
@Injectable({
    providedIn: 'root'
})
export class InitialEntityResolver implements CanActivate {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _emp: EntityManagerProvider,
        private _eventService: GlobalEvents,
        private _router: Router
    ) {

    }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this._eventService.event_loadingIndicator(true);
        return from(this._emp.prepare()).pipe(
            map(() => {
                return true;
            }),
            catchError(err => {
                if (this._router.url == '/pages/errors/500') {
                    this._router.navigate(['/pages/errors/500'], { queryParams: { count: 1, error: err } });
                }
                else {
                    this._router.navigate(['/pages/errors/500']);
                }
                return of(true);
            })
        );
    }
}


@Injectable({
    providedIn: 'root'
})
export class InitialPageRedirect implements CanActivate {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _router: Router,
        private _auth: AuthService,
        private _user: UserService
    ) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


        if (SupportServices.isInstalled()) {
            return this._auth.check().pipe(
                tap((isAuth: boolean) => {
                    if (isAuth)
                        this._router.navigate(['signed-in-redirect-user']);
                    else
                        this._router.navigate(['home-mobile-app']);

                }),
                switchMap(() => {
                    return of(true);
                })
            );
        } else {
            return of(true);
        }


    }
}



@Injectable({
    providedIn: 'root'
})
export class InitialBlankPageRedirect implements CanActivate {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _router: Router,
    ) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (SupportServices.isInstalled()) {
            this._router.navigate(['home-mobile-app']);
        } else {
            this._router.navigate(['home']);
        }

        return of(true);
    }
}


//This checks to see if the business has been started
//Used to safeguard routes where by accident they could load e.g business sign-out etc but business has not started.
@Injectable({
    providedIn: 'root'
})
export class PreInitCheck implements CanActivate {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _router: Router,
        private _appSettings: AppSettings
    ) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if(!this._appSettings.init)
        {
            this._router.navigate(['sign-out-simple']);
        }
        return of(true);
    }
}

//Makes sure the splash is closed when navigating to certain routes.
@Injectable({
    providedIn: 'root'
})
export class CloseSplash implements CanActivate {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _router: Router,
        private _splash: TreoSplashScreenService
    ) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this._splash.hide();
        return of(true);
    }
}


//Converts the token and populates the current logged in user.
@Injectable({
    providedIn: 'root'
})
export class CheckUser implements CanActivate {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _router: Router,
        private _user: UserService
    ) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this._user.get();
        return this._user.user$.pipe(
            switchMap(user => {
                return of(true);
            })
        )
    }
}


// 2: Loads the data into the entity provider from server.
@Injectable({
    providedIn: 'root'
})
export class InitialAppStartResolver implements CanActivate {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _router: Router,
        private _appStartService: AppControlService,
        private _eventService: GlobalEvents,
        private _emp: EntityManagerProvider
    ) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return from(this._appStartService.init());
    }
}


// 3: Load Base Data loads initial data for routes runs after EntityModel was created and AppStart has run.
@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _appStartService: AppControlService,
        private _eventService: GlobalEvents,
        private _router: Router,
        private _uow: HBUnitOfWork
    ) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Load messages
     *
     * @private
     */
    private _loadMessages(): Observable<any> {
        return of([]);
        //return from(this._uow.getRecentMessages());
    }

    /**
     * Load navigation data
     *
     * @private
     */
    private _loadNavigation(): Observable<any> {
        return this._httpClient.get('api/common/navigation');
    }

    /**
     * Load notifications
     *
     * @private
     */
    private _loadNotifications(): Observable<any> {
        //return this._httpClient.get('api/common/notifications');

        return of(notifications)
    }

    /**
     * Load shortcuts
     *
     * @private
     */
    private _loadShortcuts(): Observable<any> {
        return this._httpClient.get('api/common/shortcuts');
    }

    // private _appStart(): Observable<any> {
    //     return from(this._appStartService.init()).pipe(catchError(err => {
    //         this._router.navigate(['/pages/errors/500']);
    //         return of('Server Error');
    //     }));
    // }


    /**
     * Load user
     *
     * @private
     */
    private _loadUser(): Observable<UserAccount> {
        return of(this._uow.getMe());
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        this._eventService.event_loadingIndicator(true);
        console.log('Loading Router Data');
        // return this._appStart().pipe(mergeMap(() => {

        return forkJoin([

            // Messages
            this._loadMessages(),

            // Navigation data
            this._loadNavigation(),

            // Notifications
            this._loadNotifications(),

            // Shortcuts
            this._loadShortcuts(),

            // User
            this._loadUser()

        ]).pipe(
            map((data: any) => {
                console.log(data[1]);
                this._eventService.event_loadingIndicator(true);
                return {
                    messages: data[0],
                    navigation: {
                        compact: data[1].compact,
                        default: data[1].default,
                        futuristic: data[1].futuristic,
                        horizontal: data[1].horizontal
                    },
                    notifications: data[2].notifications,
                    shortcuts: data[3].shortcuts,
                    user: data[4]
                };
            })
        )
        // }));

    }
}
