import { AppSettings } from 'app/core/services/app-settings.service';
import { Injectable } from '@angular/core';
declare var Noty: any;
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
type QueueTypes = 'messages' | 'refresh' | 'appointment';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { IConfirmationData } from './confirmation-dialog.component';
import { Observable } from 'rxjs';
@Injectable()
export class NotifyService {

    constructor(public snackBar: MatSnackBar, public dialog: MatDialog, public appSettings: AppSettings) {

    }

    public success(message: string, actionText: string = null, duration: number = 4000): MatSnackBarRef<any> {

        const snackBarRef = this.snackBar.open(message, null, {
            duration: duration,
        });
        return snackBarRef;

    }

    public error(message: string, actionText: string = null, duration: number = 4500): MatSnackBarRef<any> {
        const verticalPos = this.appSettings.mobile ? 'bottom' : 'top';


        const snackBarRef = this.snackBar.open(message, actionText, {
            duration: duration,
            horizontalPosition: 'center',
            verticalPosition: verticalPos,
        });
        return snackBarRef;
    }

    public snack(message: string, actionText: string = null, duration: number = 4500): MatSnackBarRef<any> {
        const verticalPos = this.appSettings.mobile ? 'bottom' : 'top';


        const snackBarRef = this.snackBar.open(message, actionText, {
            duration: duration,
            horizontalPosition: 'center',
            verticalPosition: verticalPos,
        });
        return snackBarRef;
    }

    // this._snackBar.open('Cannonball!!', 'End now', {
    //     duration: 500,
    //     horizontalPosition: this.horizontalPosition,
    //     verticalPosition: this.verticalPosition,
    //   });


    public info(message: string, actionText: string = null, duration: number = 4000): MatSnackBarRef<any> {
        const verticalPos = this.appSettings.mobile ? 'bottom' : 'top';


        const snackBarRef = this.snackBar.open(message, actionText, {
            duration: duration,
            horizontalPosition: 'center',
            verticalPosition: verticalPos,
        });
        return snackBarRef;
    }

    public warn(message: string, actionText: string = null, duration: number = 4000): MatSnackBarRef<any> {
        const verticalPos = this.appSettings.mobile ? 'bottom' : 'top';


        const snackBarRef = this.snackBar.open(message, actionText, {
            duration: duration,
            horizontalPosition: 'center',
            verticalPosition: verticalPos,
        });
        return snackBarRef;
    }

    public alert(message: string, actionText: string = null, duration: number = 4000): MatSnackBarRef<any> {
        const verticalPos = this.appSettings.mobile ? 'bottom' : 'top';


        const snackBarRef = this.snackBar.open(message, actionText, {
            duration: duration,
            horizontalPosition: 'center',
            verticalPosition: verticalPos,
        });
        return snackBarRef;
    }

    public YesNoDialog(title: string, description: string, icon: string = 'delete', onlyInfo: boolean = false): MatDialogRef<ConfirmationDialogComponent> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = true;
        dialogConfig.autoFocus = false;
        dialogConfig.width = '300px';
        dialogConfig.data = { title: title, description: description, icon: icon, onlyInfo: onlyInfo } as IConfirmationData;
        const newDialogue = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

        newDialogue.afterClosed().subscribe(() => {
            newDialogue.close();
        });

        return newDialogue;
    }


}

