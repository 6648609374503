import { takeUntil } from 'rxjs/operators';
import { HBUnitOfWork } from '../../../core/services/hb-unit-of-work';
import { AppSettings } from 'app/core/services/app-settings.service';
import { Subscription, Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { GlobalEvents } from '../../../core/events/events';
import { MatSnackBar } from '@angular/material/snack-bar';

import { UntypedFormControl } from '@angular/forms';
import { Business } from '../../../core/models/entities/Business';

@Component({
  selector: 'app-business-selector',
  templateUrl: './business-selector.component.html',
  styleUrls: ['./business-selector.component.scss']
})
export class BusinessSelectorComponent implements OnInit, OnDestroy {
  multiuser = false;
  businesses: Business[];
  business: Business;
  viewControl = new UntypedFormControl();

  viewControlSub: Subscription;
  private _unsubscribeAll: Subject<any>;


  constructor(
    private uow: HBUnitOfWork,
    public appSettings: AppSettings,
    private events: GlobalEvents,
    public snackBar: MatSnackBar
    ) 
    { 

      this._unsubscribeAll = new Subject();

      this.multiuser = this.appSettings.multiuser;
      this.uow.getEntitiesObserver<Business>('Business').pipe(takeUntil(this._unsubscribeAll)).subscribe((business) => {
          this.businesses = business;
        });
    }
  
    async ngOnInit(): Promise<null> {
      this.business =  await this.uow.business.withId(this.appSettings.currentBID, true); 
      this.viewControl.setValue(this.appSettings.currentBID, {emitEvent: false});
      
      this.viewControl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(businessId => {
        this.business =  this.businesses.find(e => e.businessId === businessId);
        this.events.event_setBusiness(businessId);
      });

      this.events.businessAnnounced$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
          this.viewControl.setValue(this.appSettings.currentBID, {emitEvent: false});
          this.business =  this.businesses.find(e => e.businessId === this.appSettings.currentBID);
      });

      return;

    }
  

    ngOnDestroy(): void {
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
    }

  }
  
