import { Injectable } from '@angular/core';
import { Entity, EntityChangedEventArgs, EntityManager, EntityQuery, FetchStrategy, SaveOptions, EntityState } from 'breeze-client';
import { Subject, Observable, of, merge, defer, pipe } from 'rxjs';
import { filter, map, take, delay, takeLast, debounceTime, throttleTime } from 'rxjs/operators';
import { EntityManagerProvider } from './entity-manager-provider';
import { IRepository, Repository } from './repository';
import { Staff } from 'app/core/models/entities/Staff';

export interface IEntityFactory<T extends Entity> {
    create(...params: any[]): Promise<T>;
}

export class EntityFactory<T extends Entity> implements IEntityFactory<T> {

    constructor(private entityTypeName: string, private manager: EntityManager) {
    }

    create(config?: any, state?: EntityState): Promise<T> {
        const inst = this.manager.createEntity(this.entityTypeName, config, state) as T;
        return Promise.resolve(inst);
    }

    createNoPromise(config?: any, state?: EntityState): T {
        return this.manager.createEntity(this.entityTypeName, config, state) as T;
    }
}

export class SavedOrRejectedArgs {
    entities: Entity[];
    rejected: boolean;
}

@Injectable()
export class UnitOfWork {


    private static shelveSets = {};
    private static committedSubject = new Subject<Entity[]>();
    private static rejectedSubject = new Subject<Entity[]>();

    private _manager: EntityManager;
    private entityChangedSubject: Subject<EntityChangedEventArgs>;



    static deleteShelveSet(key: string): void {
        delete UnitOfWork.shelveSets[key];
    }

    protected get manager(): EntityManager {
        if (!this._manager) {
            this._manager = this._emProvider.newManager();

            this._manager.entityChanged.subscribe(args => {
                this.entityChangedSubject.next(args);
            });
        }
        return this._manager;
    }

    get entityChanged(): Observable<EntityChangedEventArgs> {
        return this.entityChangedSubject.asObservable();
    }

    static get committed(): Observable<Entity[]> {
        return UnitOfWork.committedSubject.asObservable();
    }

    static get rejected(): Observable<Entity[]> {
        return UnitOfWork.rejectedSubject.asObservable();
    }

    constructor(private _emProvider: EntityManagerProvider) {
        this.entityChangedSubject = new Subject<EntityChangedEventArgs>();


    }

    hasChanges(): boolean {
        return this.manager.hasChanges();
    }

    getChanges(): Entity[] {
        return this.manager.getChanges();
    }

    // gets list of entities when they change.
    getEntitiesObserver<T extends Entity>(entityType: string): Observable<T[]> {
        const eType = this._manager.metadataStore.getAsEntityType(entityType, false);
        return merge(defer(() => of(this._manager.getEntities(entityType) as T[])),
            this.entityChangedSubject.pipe(
                filter(x => x.entity.entityType.isSubtypeOf(eType)),
                map(() => this._manager.getEntities(entityType) as T[])
            ));
    }
    // notifies if a specific entity type has changed.
    entityTypeChanged<T extends Entity>(entityType: string): Observable<T[]> {
        const eType = this._manager.metadataStore.getAsEntityType(entityType, false);
        return this.entityChangedSubject.pipe(
            filter(x => x.entity.entityType.isSubtypeOf(eType)),
            map(() => this._manager.getEntities(entityType) as T[])
        );
    }



    attach(newEntity: Entity): void {
        this.manager.addEntity(newEntity);
    }

    commit(): Promise<Entity[]> {
        // const saveOptions = new SaveOptions({ resourceName: 'savechanges' });

        return this.manager.saveChanges()
            .then(saveResult => {
                UnitOfWork.committedSubject.next(saveResult.entities);
                return saveResult.entities;
            });
    }

    commitEntities(entities: Entity[]): Promise<Entity[]> {
        // const saveOptions = new SaveOptions({ resourceName: 'savechanges' });

        return this.manager.saveChanges(entities)
            .then(saveResult => {
                UnitOfWork.committedSubject.next(saveResult.entities);
                return saveResult.entities;
            });
    }

    rollback(): void {
        const pendingChanges = this.manager.getChanges();
        this.manager.rejectChanges();
        UnitOfWork.rejectedSubject.next(pendingChanges);
    }

    clear(): void {
        this._emProvider.reset(this.manager);
    }


    shelve(key: string, clear: boolean = false): void {
        const data = this.manager.exportEntities(null, { asString: false, includeMetadata: false });
        UnitOfWork.shelveSets[key] = data;
        if (clear) {
            this._emProvider.reset(this.manager);
        }
    }

    unshelve(key: string, clear: boolean = true): boolean {
        const data = UnitOfWork.shelveSets[key];
        if (!data) {
            return false;
        }

        if (clear) {
            // Clear the entity manager and don't bother importing lookup data from masterManager.
            this.manager.clear();
        }
        this.manager.importEntities(data);

        // Delete the shelveSet
        delete UnitOfWork.shelveSets[key];
        return true;
    }

    protected createRepository<T>(entityTypeName: string, resourceName: string, isCached: boolean = false): Repository<T> {
        return new Repository<T>(this.manager, entityTypeName, resourceName, isCached);
    }

    protected createFactory<T extends Entity>(entityTypeName: string): EntityFactory<T> {
        return new EntityFactory<T>(entityTypeName, this.manager);
    }


}
