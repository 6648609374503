import { AppointmentTag } from './AppointmentTag';
import { Business } from './Business';
import { ClientTag } from './ClientTag';
import { EntityBase } from './EntityBase';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Tag  extends EntityBase {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    tagId: string;
    businessId: string;
    description: string;
    system: boolean;
    text: string;
    appointmentTag: AppointmentTag[];
    business: Business;
    clientTag: ClientTag[];
}

