import { NotificationService } from './notification.service';
import { GlobalCalendarEventsService } from '../events/calendar.events';
import { HBUnitOfWork } from './hb-unit-of-work';
import { IFullCalendarAppointment, ISignalRAppointment, ValidationError, NotificationProcess, NotificationAction } from '../models/entity-objects';
import { Injectable } from '@angular/core';
import { Appointment } from '../models/entities/Appointment';
import { SupportServices } from '../services/support.service';
import RRule, { Frequency, RRuleSet, rrulestr } from 'rrule';
import { SignalRInboundService } from './signalR/signalR-inbound.service';
import { SignalROutboundService } from './signalR/signalR-outbound.service';

import { EntityState, Predicate, FilterQueryOp, FetchStrategy, MergeStrategy, core } from 'breeze-client';
import { from, Observable, of, Subject } from 'rxjs';
import { AppSettings } from './app-settings.service';
import { DateTime, Interval } from 'luxon';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ZoomService } from './zoom.service';

export enum AppointmentModificationMode {
    EditSingle,
    EditAll,
    DeleteSingle,
    DeleteAll
}

@Injectable()
export class AppointmentManagerService {

    private _unsubscribeAll: Subject<any>;
    currentAppointment: Appointment;

    constructor(
        private uow: HBUnitOfWork,
        private gces: GlobalCalendarEventsService,
        private sib: SignalRInboundService,
        private sob: SignalROutboundService,
        private _httpClient: HttpClient,
        private _as: AppSettings,
        private _ns: NotificationService,
        private _zoom: ZoomService
    ) {

        // SignalR listeners waiting for events from server to update cache.
        this.sib.receivedNewAppointment$.subscribe((data) => {
            console.log('Received New signalr R appointment');
            console.log(data);
            this.signalRAdd(data);
        });
        this.sib.receivedUpdateAppointment$.subscribe((data) => {
            this.signalRUpdate(data);
        });
        this.sib.receivedDeleteAppointment$.subscribe((data) => {
            this.signalRDelete(data);
        });
    }

    // public async getAppointmentbyId(event: IFullCalendarAppointment): Promise<Appointment>
    // {
    //     try
    //     {
    //         const apt = await this.uow.appointments.withId(event.extendedProps.appointmentId);
    //         return apt;

    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    public async getNewAppointment(event: IFullCalendarAppointment): Promise<Appointment> {

        const apt = await this.uow.appointmentFactory.create(
            {
                staffId: event.extendedProps.staffId,
                start: new Date(event.start),
                end: new Date(event.end)
            });

        return apt;
    }

    public async duplicateAppointment(event: IFullCalendarAppointment, current: Appointment): Promise<Appointment> {

        console.log('duplicating');

        const apt = await this.uow.appointmentFactory.create(
            {
                staffId: event.extendedProps.staffId,
                start: new Date(event.start),
                end: new Date(event.end)
            });

        apt.locationId = current.locationId;

        //apt.clientId = current.clientId; // because detached does not work.

        apt.client = current.client;
        apt.businessId = current.businessId;
        apt.clientRef = current.clientRef;
        apt.colorId = current.colorId;
        apt.description = current.description;
        apt.firstname = current.firstname;
        apt.lastname = current.lastname;
        apt.telephone = current.telephone;
        apt.isRecurring = false;

        apt.telephoneCountry = current.telephoneCountry;
        apt.subject = current.subject;


        return apt;
    }


    public async getAppointment(id: string): Promise<Appointment> {
        return this.uow.appointments.withId(id, true);
    }

    // private async fetchAppointmentById(id: string): Promise<Appointment | null> {

    //     const p1 = Predicate.create('appointmentId', FilterQueryOp.Equals, id);
    //     const appointment = await this.uow.appointments.where(p1, FetchStrategy.FromServer, MergeStrategy.OverwriteChanges, ['client']);
    //     return appointment[0];
    // }


    public async createAppointment(event: Appointment, zoomPassword?: string): Promise<null> {

        console.log('Calling save appointment');

        // attach the client to the entities
        if (event.clientRef) {
            this.uow.attach(event.clientRef);
        }
        this.uow.attach(event);


        event.subject = (!!event.subject) ? event.subject : '';
        event.firstname = (event.client && !!event.client.firstname) ? event.client.firstname : '';
        event.lastname = (event.client && !!event.client.lastname) ? event.client.lastname : '';
        event.telephone = (event.client && !!event.client.telephone) ? event.client.telephone : '';
        event.email = (event.client && !!event.client.email) ? event.client.email : '';
        event.recurringEndDate = new Date(Date.parse(environment.recurringEndDate));


        const dStart = DateTime.fromJSDate(event.startDate).toUTC();
        const dEnd = DateTime.fromJSDate(event.endDate).toUTC();
        const interval = Interval.fromDateTimes(dStart, dEnd);


        event.reminderInfo = interval.toDuration().toFormat('hh:mm');  // moment.utc(duration.asMilliseconds()).format('HH:mm');

        // set occurance end date so we can filter better on server.
        if (event.isRecurring) {
            // check to see if there an count or end date.
            const rRule = RRule.fromString(event.recurrenceInfo);
            const rRuleSet = new RRuleSet();
            rRuleSet.rrule(rRuleSet);

            if (event.recurrenceInfo.indexOf('UNTIL') >= 0 || event.recurrenceInfo.indexOf('COUNT') >= 0) {
                const recurringRecords = rRule.all();
                console.log(recurringRecords);
                const lastRecord = recurringRecords[recurringRecords.length - 1];
                event.recurringEndDate = lastRecord;
            }
        }



        console.log(' Before - ' + event.appointmentId);

        event.entityAspect.validateEntity();

        if (event.entityAspect.hasValidationErrors) {
            console.log(event.entityAspect.getValidationErrors());
            throw new ValidationError('Save failed', event.entityAspect.getValidationErrors());
        }

        // if (event.isZoom) {
        //     // Part 1: this does not add the zoom record on the zoom servers
        //     // This creates the zoom record in the DB that will be updated later on with the proper Zoom URL and ID.
        //     // The Angular client has all the info required and this is to prevent additional DB hits later on.
        //     event.addZoom(zoomPassword);
        // }

        // Add to the scheduler control (looks fast)
        this.gces.addAppointment(event);



        // Save appointment.
        const records = await this.uow.commit().catch((err) => {

            // If there is an error while creating the appointment remove it from the calendar. It was added optimistically.
            this.gces.deleteAppointment(event.appointmentId);
        });

        // This method calls the server and actually creates the zoom meeting on the Zoom servers
        if (event.isZoom) {
            const zoomData = await this._zoom.addZoomMeeting(event.appointmentId, zoomPassword).toPromise();
        }


        if (event.entityAspect.hasValidationErrors) {
            throw new ValidationError(' Save failed', event.entityAspect.getValidationErrors());
        }


        // Broadcast appointment SignalR
        this.sob.broadcastNewAppointment(event.toJson());


        const setting = await this.uow.setting.withId(event.businessId, true);

        if (event.client) {
            if (setting && setting.smsonNew) {
                const nf = await this.uow.notificationFactory.create(
                    {
                        appointmentId: event.appointmentId,
                        process: NotificationProcess.Sms,
                        action: NotificationAction.New
                    });
                if (nf) {
                    this._ns.send(nf);
                }
            }
            if (setting && setting.emailNewOn) {
                const nf = await this.uow.notificationFactory.create(
                    {
                        appointmentId: event.appointmentId,
                        process: NotificationProcess.Email,
                        action: NotificationAction.New
                    });

                if (nf) {
                    this._ns.send(nf);
                }
            }
        }

        return;

    }

    public async deleteAppointment(id: string, permanent: boolean = false): Promise<Appointment> {
        const event = await this.uow.appointments.withId(id, true);
        event.setDeleted(false);
        return this.updateAppointment(event);

    }


    public async deleteInstanceFromRecurring(id: string, date: Date, permanent: boolean = false): Promise<Appointment> {
        const event = await this.uow.appointments.withId(id, true);
        const ruleObj = rrulestr(event.recurrenceInfo, { forceset: true }) as RRuleSet;
        console.log('Delete Procedure');
        const utcDate = DateTime.fromJSDate(date).toUTC().toJSDate();

        if (event.recurrenceInfo.indexOf('UNTIL') >= 0 && ruleObj.count() == 1) {
            //check to see if this has a Until if it does then we can check how many dates there are going forward without performance issues.
            //if not we cannot just delete the appointment.
            //instead of excluding this using  ruleObj.exdate(utcDate) check if this is the last instance and then just hide it.
            //delete the appointment here.
            event.setDeleted();
            event.isRecurring = false;
        }
        else {
            ruleObj.exdate(utcDate);
            event.recurrenceInfo = ruleObj.toString();
        }

        return this.updateAppointment(event);

    }

    public async deleteAllFutureFromRecurring(id: string, date: Date, permanent: boolean = false): Promise<Appointment> {
        const event = await this.uow.appointments.withId(id, true);
        const ruleObj = rrulestr(event.recurrenceInfo, { forceset: true }) as RRuleSet;
        const rrule = ruleObj.rrules()[0];
        rrule.options.until = DateTime.fromJSDate(date).minus({ days: 1 }).toUTC().toJSDate();
        //used for easy filtering of end dates.
        const excludedDates = ruleObj.exdates();
        const rruleSet = new RRuleSet();
        const newRRule = new RRule(rrule.options);

        rruleSet.rrule(newRRule);

        for (const edate of excludedDates) {
            rruleSet.exdate(edate);
        }

        //check if there are no more instances
        if (event.recurrenceInfo.indexOf('UNTIL') >= 0 && rruleSet.count() == 0) {
            //this means our new ruleset is actually empty so we revert and make it empty.
            //delete the appointment here.
            event.isRecurring = false;
            event.setDeleted();

        } else {
            event.recurrenceInfo = rruleSet.toString();
            event.recurringEndDate = DateTime.fromJSDate(date).minus({ days: 1 }).toUTC().toJSDate();
        }



        return this.updateAppointment(event);

    }


    public async createSingleFromRecurring(newEvent: Appointment, recurringId: string, startDate: Date): Promise<null> {

        await this.deleteInstanceFromRecurring(recurringId, startDate);
        await this.createAppointment(newEvent);
        const originalInstance = await this.getAppointment(recurringId);
        //notify the calendar and signalR of the changes.
        this.gces.updateAppointment(originalInstance);
        return;

    }

    public async updateAppointment(event: Appointment): Promise<null> {
        if (event) {

            const deleted = (event.label > 0) ? false : true;
            let needToNotify: boolean;
            let addedClient = false;

            //check to see if there is recurring data and if there are instances that have been restored
            //this means a deleted appointment needs to be restored.
            // if (event.isRecurring && event.label < 0) {
            //     const ruleSet = rrulestr(event.recurrenceInfo, { forceset: true }) as RRuleSet;
            //     if (event.recurrenceInfo.indexOf('UNTIL') >= 0 && ruleSet.count() > 1) {
            //         if (event.label < 0) {
            //             if (event.clientId) {
            //                 event.label = 1;
            //                 event.status = 1;
            //             }
            //             else {
            //                 event.label = 3;
            //                 event.status = 3;
            //             }
            //         }
            //     }
            // }



            if (event.entityAspect.originalValues.hasOwnProperty('startDate') || event.entityAspect.originalValues.hasOwnProperty('endDate') || event.entityAspect.originalValues.hasOwnProperty('clientId')) {
                // something important on the appointment changed and we need to resend the SMS
                needToNotify = true;
            }

            if (event.client && event.client.entityAspect.entityState === EntityState.Modified) {
                // need to check if this is the geo or telephone number that has changed.
                if (Object.keys(event.client.entityAspect.originalValues).length > 2) {
                    needToNotify = true;
                }
                if (Object.keys(event.client.entityAspect.originalValues).length >= 2) {
                    if (event.client.entityAspect.originalValues['telephone'] === event.client.telephone) {
                        event.client.entityAspect.rejectChanges();
                    }
                    else {
                        needToNotify = true;
                    }
                }
                if (event.entityAspect.originalValues['clientId'] == null) {
                    addedClient = true;
                    needToNotify = true;
                }
            }

            event.entityAspect.validateEntity();

            if (event.entityAspect.hasValidationErrors) {
                throw new ValidationError('Save failed - validation', event.entityAspect.getValidationErrors());
            }

            // Update UI nicely.
            if (deleted) {
                this.gces.deleteAppointment(event.appointmentId);
            }
            else {
                this.gces.updateAppointment(event);
            }


            await this.uow.commit().catch((err) => {
                console.log(err);
            });


            // Do SignalR updates
            if (deleted) {
                this.sob.broadcastDeleteAppointment(event.toJson());
            } else {
                this.sob.broadcastUpdateAppointment(event.toJson());
            }

            // Send notifications.
            console.log('Running notification logic.')
            if (event.client && needToNotify) {

                const setting = await this.uow.setting.withId(event.businessId, true);

                if (!deleted) {

                    if (setting && setting.smsonMove) {
                        const nf = await this.uow.notificationFactory.create(
                            {
                                appointmentId: event.appointmentId,
                                process: NotificationProcess.Sms,
                                action: NotificationAction.Update
                            });
                        this._ns.send(nf);

                    }

                    if (setting && setting.emailUpdateOn) {
                        const nf = await this.uow.notificationFactory.create(
                            {
                                appointmentId: event.appointmentId,
                                process: NotificationProcess.Email,
                                action: NotificationAction.Update
                            });
                        this._ns.send(nf);
                    }

                } else {
                    if (setting && setting.smsonCancel) {
                        const nf = await this.uow.notificationFactory.create(
                            {
                                appointmentId: event.appointmentId,
                                process: NotificationProcess.Sms,
                                action: NotificationAction.Delete
                            });
                        this._ns.send(nf);
                    }

                    if (setting && setting.emailUpdateOn) {
                        const nf = await this.uow.notificationFactory.create(
                            {
                                appointmentId: event.appointmentId,
                                process: NotificationProcess.Email,
                                action: NotificationAction.Delete
                            });
                        this._ns.send(nf);

                    }

                }
            }

        }
        return;
    }


    // Below methods update the local Breeze cache and then broadcast to the scheduler control to update object.

    private async signalRAdd(data: ISignalRAppointment): Promise<null> {

        const appointmentEntity = await this.uow.appointmentFactory.createFromSignalRObject(data);
        if (data.client) {
            await this.uow.clientFactory.createUnchanged(data.client);
        }

        this.gces.addAppointment(appointmentEntity);

        return;
    }

    private async signalRUpdate(appointment: ISignalRAppointment): Promise<null> {
        const apt = await this.getAppointment(appointment.appointmentId);

        Object.assign(apt, appointment);
        apt.entityAspect.setUnchanged();

        if (apt.client) {
            Object.assign(apt.client, appointment.client);
        }

        this.gces.updateAppointment(apt);

        return;
    }

    // this function keeps the local cache updated when an appointment is deleted with signalR.
    private async signalRDelete(appointment: ISignalRAppointment): Promise<null> {
        const apt = await this.getAppointment(appointment.appointmentId);
        apt.setDeleted();
        apt.entityAspect.setUnchanged();
        this.gces.deleteAppointment(apt.appointmentId);
        return;
    }






}

