import { SupportServices } from 'app/core/services/support.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalEvents } from './../core/events/events';
import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppInjector } from '../core/services/app-injector.service';
import { Subscription, Subject } from 'rxjs';
import { AppSettings } from '../core/services/app-settings.service';
import { TreoMediaWatcherService } from '@treo/services/media-watcher';
import { takeUntil } from 'rxjs/operators';
import { NotifyService } from 'app/modules/components/confirmation-dialog/notify.service';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Location } from '@angular/common';


@Component({
  template: ''
})
export class BaseComponent implements OnDestroy {


  public multiuser: boolean;

  public isBusy: boolean; // can be used to block elements if busy.
  public currency: string;
  public redirectURL: string;




  private _isScreenSmall: boolean = false;
  get isScreenSmall(): boolean {

    return this._isScreenSmall;
  }


  get isStandAlone(): boolean {
    return SupportServices.isInstalled();
  }


  private _unsubscribeAll: Subject<any>;
  get unsubscribeAll(): Subject<any> {
    return this._unsubscribeAll;
  }

  protected _bp: BreakpointObserver;
  protected _appSettings: AppSettings;
  protected _treoMediaWatcherService: TreoMediaWatcherService;
  protected __changeDetectorRef: ChangeDetectorRef;
  protected _ge: GlobalEvents;
  protected _router: Router;
  protected _location: Location;
  protected _noty: NotifyService;
  protected _snackBar: MatSnackBar;
  // protected _activatedRoute: ActivatedRoute;

  constructor() {


    this._unsubscribeAll = new Subject();
    // Manually retrieve the dependencies from the injector    
    // so that constructor has no dependencies that must be passed in from child    
    const injector = AppInjector.getInjector();
    this._bp = injector.get(BreakpointObserver);
    this._treoMediaWatcherService = injector.get(TreoMediaWatcherService);
    this._ge = injector.get(GlobalEvents);
    this._appSettings = injector.get(AppSettings);
    this._router = injector.get(Router);
    this._noty = injector.get(NotifyService);
    this._snackBar = injector.get(MatSnackBar);
    this._location = injector.get(Location);


    this._treoMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the breakpoint is 'small'
        this._isScreenSmall = !matchingAliases.includes('sm');

      });

    this.multiuser = this._appSettings.multiuser;

  }

  public snack(message: string, actionText: string = null, duration: number = 4500): MatSnackBarRef<any> {
    const verticalPos = this.isScreenSmall ? 'bottom' : 'top';


    const snackBarRef = this._snackBar.open(message, actionText, {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: verticalPos,
    });
    return snackBarRef;
  }


  public busy(value): void {
    this.isBusy = value;
    this._ge.event_loadingIndicator(value);
  }

  public error(message?: string): void {
    this._router.navigateByUrl('../../errors/404');
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}

