import { SupportServices } from 'app/core/services/support.service';
import { AppSettings } from './../../services/app-settings.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { switchMap } from 'rxjs/operators';
import { UserType } from 'app/core/models/auth/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardUser implements CanActivate, CanActivateChild, CanLoad {
    /**
     * Constructor
     *
     * @param {AuthService} _authService
     * @param {Router} _router
     */
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _appSettings: AppSettings
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL): Observable<boolean> {
        console.log('running auth check in auth guard');
        // Check the authentication status
        return this._authService.check()
            .pipe(
                switchMap((authenticated) => {

                    // If the user is not authenticated...
                    if (!authenticated || this._appSettings.user.type !== UserType.USER) {

                        // Redirect to the sign-in page depending on if this is installed(mobile app) or website.

                        if (SupportServices.isInstalled()) {
                            this._router.navigate(['home-mobile-app'], { queryParams: { redirectURL } });
                        }
                        else {
                            this._router.navigate(['sign-in'], { queryParams: { redirectURL } });
                        }





                        // Prevent the access
                        return of(false);
                    }

                    // Allow the access
                    return of(true);
                })
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        let redirectUrl = state.url;

        if (redirectUrl === '/sign-out') {
            redirectUrl = '/';
        }

        return this._check(redirectUrl);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let redirectUrl = state.url;

        if (redirectUrl === '/sign-out') {
            redirectUrl = '/';
        }

        return this._check(redirectUrl);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check('/');
    }

}

