<ng-container *ngIf="!isScreenSmall && hasScheduler">
  <button class="today-button gfont-button" (click)="todayClick()" mat-flat-button>
    Today
  </button>
  <button (click)="prev()" matTooltip="Previous" mat-icon-button>
    <mat-icon svgIcon="chevron_left"></mat-icon>
  </button>
  <button (click)="picker.open()" mat-icon-button>
    <mat-icon svgIcon="date_range"></mat-icon>
    <input [formControl]="date" class="w-0 h-0" matInput [matDatepicker]="picker">
    <mat-datepicker #picker></mat-datepicker>
  </button>

  <button (click)="next()" matTooltip="Next" mat-icon-button>
    <mat-icon svgIcon="chevron_right"></mat-icon>
  </button>
  <span class="text-lg font-semibold">{{viewText}}</span>
</ng-container>

<ng-container *ngIf="isScreenSmall && hasScheduler">
  <button (click)="picker.open()" mat-icon-button>
    <mat-icon svgIcon="date_range"></mat-icon>
  </button>
  <button (click)="prev()" matTooltip="Previous" mat-icon-button>
    <mat-icon svgIcon="chevron_left"></mat-icon>
  </button>
  <span (click)="picker.open()" class="text-base font-semibold">{{viewText}}</span>
  <input [formControl]="date" class="w-0 h-0" matInput [matDatepicker]="picker">
  <mat-datepicker touchUi #picker></mat-datepicker>

  <button (click)="next()" matTooltip="Next" mat-icon-button>
    <mat-icon svgIcon="chevron_right"></mat-icon>
  </button>
</ng-container>