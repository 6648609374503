

import { LoadingIndicatorComponent } from './loading-indicator.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
@NgModule({
  declarations: [
    LoadingIndicatorComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatProgressSpinnerModule

  ],
  exports: [
    LoadingIndicatorComponent
  ]
})
export class LoadingIndicatorModule { }