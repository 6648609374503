
import { BaseComponent } from './../../../../shared/base-component';
import { Component, OnDestroy, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { interval, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { TreoMediaWatcherService } from '@treo/services/media-watcher';
import { TreoNavigationItem, TreoNavigationService } from '@treo/components/navigation';
import { InitialData } from 'app/app.types';
import { TreoSplashScreenService } from '@treo/services/splash-screen/splash-screen.service';
import { AuthService } from 'app/core/auth/auth.service';
import { UserAuth } from 'app/core/models/auth/user.model';
import {businessNavigation} from '../classic/classic.navigation.data'
import { environment } from 'environments/environment';


@Component({
    selector     : 'thin-layout',
    templateUrl  : './thin.component.html',
    styleUrls    : ['./thin.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ThinLayoutComponent extends BaseComponent implements OnInit, OnDestroy
{
    data: InitialData;
    isCalendar: boolean;
    user: UserAuth;
    navigation: TreoNavigationItem[]
    env = environment;
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: AuthService,
        private _treoNavigationService: TreoNavigationService,
        private _treoSplash: TreoSplashScreenService
    ) {
        super();

        if (this._router.url.toString().indexOf('calendar') >= 0)
            this.isCalendar = true;

        this.user = _authService.user;

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the resolved route mock-api



        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this.unsubscribeAll)
        ).subscribe(() => {

            // console.log(this._router);
            // console.log(this._router.url);

            if (this._router.url === '/' || this._router.url.indexOf('/calendar') >= 0) {
                this.isCalendar = true;

            }
            else
                this.isCalendar = false;

        });

        this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
            this.navigation = businessNavigation;


            if (this._router.url === '/' || this._router.url.indexOf('/calendar') >= 0) {
                this.isCalendar = true;

            } else
                this.isCalendar = false;

        });



    }

    openChanged(): void {


        let interval$ = interval(1);

        interval$.pipe(
            take(25),
            tap(() => {
                this._ge.event_resizeUi();
            })
        ).subscribe();

        setTimeout(() => {
            this._ge.event_resizeUi();
        }, 300)

    }

    ngAfterViewInit(): void {
        
       
          setTimeout(() => {
            this._treoSplash.hide();
          }, 250)

    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        super.ngOnDestroy();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._treoNavigationService.getComponent(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
