import { DateTime } from 'luxon';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, Observable, BehaviorSubject, from, Subscription } from 'rxjs';
// import { Message } from 'app/layout/common/messages/messages.types';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { HBUnitOfWork } from 'app/core/services/hb-unit-of-work';
import { Message } from 'app/core/models/entities/Message';
import { Client } from 'app/core/models/entities/Client';
import { Entity, DataService } from 'breeze-client';
import { fromPairs } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class MessagesService implements OnDestroy {
    private _messages: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);


    messageTicker: Observable<number>;
    tickerSubscription: Subscription


    hidden_Messages = 'hiddenMessages';

    //private _hidden = [];
    get hiddenMessage(): any[] {
        let hid = localStorage.getItem(this.hidden_Messages);
        if (!hid)
            return [];
        else {
            return JSON.parse(hid);
        }

    }



    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _uow: HBUnitOfWork
    ) {

        this.messageTicker = interval(300000);

        this.tickerSubscription = this.messageTicker.subscribe(() => {
            from(this._uow.messages.whereEndpointParams('MessagesRecent', null, { orderBy: 'date', sortDirection: 'desc' }, undefined, undefined, undefined, ['client'])).pipe(
                take(1),
                tap((messages) => {

                    let current = this.hiddenMessage;
                    const intersection = messages.filter(message => !current.some(hiddenItem => message.messageId === hiddenItem.id))
                    this._messages.next(intersection);

                    // this._messages.next(messages)
                })
            );
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for messages
     */
    get messages$(): Observable<Message[]> {
        return this._messages.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    load(): void {

        //cleanup old messages in local cache
        let current = this.hiddenMessage;
        //current.push({ id: id, date: new Date() });

        current.forEach((message, index) => {
            let today = DateTime.local();
            let dateS = DateTime.fromJSDate(message.date);

            let diff = Math.abs(today.diff(dateS, "days").days);
            if (diff > 7) {
                current.splice(index, 1);
            }
        });

        localStorage.setItem(this.hidden_Messages, JSON.stringify(current));

        from(this._uow.messages.whereEndpointParams('MessagesRecent', null, { orderBy: 'date', sortDirection: 'desc' }, undefined, undefined, undefined, ['client'])).pipe(
            take(1),
            tap((messages) => {

                let current = this.hiddenMessage;
                const intersection = messages.filter(message => !current.some(hiddenItem => message.messageId === hiddenItem.id))
                this._messages.next(intersection);


            })
        ).subscribe()
    }

    /**
     * Store messages on the service
     *
     * @param messages
     */
    store(messages: Message[]): Observable<Message[]> {
        // Load the messages
        this._messages.next(messages);

        // Return the messages
        return this.messages$;
    }

    /**
     * Create a message
     *
     * @param message
     */
    create(message: Message): Observable<Message> {
        return this.messages$.pipe(
            take(1),
            switchMap(messages => this._httpClient.post<Message>('api/common/messages', { message }).pipe(
                map((newMessage) => {

                    // Update the messages with the new message
                    this._messages.next([...messages, newMessage]);

                    // Return the new message from observable
                    return newMessage;
                })
            ))
        );
    }

    hide(id: string): void {
        let current = this.hiddenMessage;
        current.push({ id: id, date: new Date() });
        localStorage.setItem(this.hidden_Messages, JSON.stringify(current));
        this.messages$.pipe(
            take(1),
            tap((data: Message[]) => {
                const intersection = data.filter(message => !this.hiddenMessage.some(hiddenItem => message.messageId === hiddenItem.id))
                this._messages.next(intersection);
                // const results = data.filter(({ value: id1 }) => !arrayTwo.some(({ value: id2 }) => id2 === id1));
            })
        ).subscribe();
    }

    unhide(): void {
        localStorage.removeItem(this.hidden_Messages);
        this.load();

    }


    /**
     * Update the message
     *
     * @param id
     * @param message
     */
    update(id: string, message: Message): Promise<Entity[]> {

        return this._uow.commit();
    }

    /**
     * Delete the message
     *
     * @param id
     */
    delete(id: string): Observable<boolean> {
        return this.messages$.pipe(
            take(1),
            switchMap(messages => this._httpClient.delete<boolean>('api/common/messages', { params: { id } }).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted message
                    const index = messages.findIndex(item => item.messageId === id);

                    // Delete the message
                    messages.splice(index, 1);

                    // Update the messages
                    this._messages.next(messages);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }

    /**
     * Mark all messages as read
     */
    markAllAsRead(): Observable<Entity[]> {
        return this.messages$.pipe(
            switchMap((messages) => {
                messages.forEach((message, index) => {
                    message.isRead = true;
                });

                return from(this._uow.commit());
            })
        );
    }


    ngOnDestroy(): void {
        try {
            this.tickerSubscription.unsubscribe();
        }
        catch (err) {
            console.warn('Could not unsubscribe messages ticker');
        }
    }

}
