import { Invoice } from './Invoice';
import { Service } from './Service';
import { Entity } from 'breeze-client';
import { EntityBase } from './EntityBase';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class InvoiceItems extends EntityBase {

    /// <code> Place custom code between <code> tags
    //These are for the UI

    editMode: boolean = false;

    constructor() {
        super();
        console.log('constructor run');
        this.editMode = false;
    }

    /// </code>

    // Generated code. Do not place code below this line.
    invoiceItemId: string;
    amount: number;
    description: string;
    discount: number;
    icdcodes: string;
    invoiceId: string;
    item: string;
    productId: string;
    quantity: number;
    tarriffCodes: string;
    tax: number;
    invoice: Invoice;
    product: Service;

}
export interface IInvoiceItems extends Invoice { }
export interface IBreezeInvoice extends Entity, IInvoiceItems { }
