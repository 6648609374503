import { Appointment } from './Appointment';
import { BusinessType } from './BusinessType';
import { Client } from './Client';
import { EntityBase } from './EntityBase';
import { Image } from './Image';
import { Invoice } from './Invoice';
import { Location } from './Location';
import { Message } from './Message';
import { Schedule } from './Schedule';
import { Service } from './Service';
import { Setting } from './Setting';
import { Specialities } from './Specialities';
import { Staff } from './Staff';
import { Tag } from './Tag';
import { UserAccess } from './UserAccess';
import { Reminder } from './Reminder';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Business extends EntityBase {

    /// <code> Place custom code between <code> tags

    /// </code>

    // Generated code. Do not place code below this line.
   businessId: string;
    accountStatusReason: string;
    addressBuildingNo: string;
    addressCity: string;
    addressPostalCode: string;
    addressStreet: string;
    addressStreetNo: string;
    addressSuburb: string;
    allowOnlineBookings: boolean;
    billingEmail: string;
    billingLocationLicenses: number;
    billingModuleFrontdesk: boolean;
    billingModuleInvoices: boolean;
    billingModuleLoyalty: boolean;
    billingPackage: string;
    billingPackageDiscount: number;
    billingPackageType: boolean;
    billingSmsLimit: number;
    billingStaffLicenses: number;
    billingSubscriptionExternalId: string;
    billingSubscriptionId: string;
    billingTaxNo: string;
    businessTypeId: string;
    classification: string;
    currency: string;
    description: string;
    docHpcsa: string;
    docPracticeNumber: string;
    email: string;
    googleLocationString: string;
    hoursFriEnd: string;
    hoursFriStart: string;
    hoursMonEnd: string;
    hoursMonStart: string;
    hoursSatEnd: string;
    hoursSatStart: string;
    hoursSunEnd: string;
    hoursSunStart: string;
    hoursThuEnd: string;
    hoursThuStart: string;
    hoursTueEnd: string;
    hoursTueStart: string;
    hoursWedEnd: string;
    hoursWedStart: string;
    image: string;
    invoiceAccount: string;
    invoiceAccountBranch: string;
    invoiceAccountName: string;
    invoiceAccountType: string;
    invoiceBank: string;
    invoiceHideLogo: boolean;
    invoiceStandardVat: number;
    invoiceTermsDays: number;
    invoiceTermsStory: string;
    locked: boolean;
    name: string;
    operatesFri: boolean;
    operatesMon: boolean;
    operatesSat: boolean;
    operatesSun: boolean;
    operatesThu: boolean;
    operatesTue: boolean;
    operatesWed: boolean;
    ownerEmail: string;
    ownerFirstname: string;
    ownerLastname: string;
    ownerTelephone: string;
    ownerTelephoneCountry: string;
    password: string;
    showOnPortal: boolean;
    slogan: string;
    smsLimitReached: boolean;
    smsMode: string;
    smsPassword: string;
    smsUsername: string;
    specialityId: string;
    suburb: string;
    telephoneCountry: string;
    telephoneNumber: string;
    timeZone: string;
    websiteUrl: string;
    appointment: Appointment[];
    businessType: BusinessType;
    client: Client[];
    imageNavigation: Image[];
    invoice: Invoice[];
    location: Location[];
    message: Message[];
    reminder: Reminder[];
    schedule: Schedule[];
    service: Service[];
    setting: Setting;
    speciality: Specialities;
    staff: Staff[];
    tag: Tag[];
    userAccess: UserAccess[];
}
