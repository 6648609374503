import { AppSettings } from './../services/app-settings.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, first, map } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserAuth, NewUser, TokenAuth, FBToken } from '../models/auth/user.model';
// declare const FB:any;

@Injectable()
export class AuthService {
    // Private
    public _authenticated: boolean;


    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _appSettings: AppSettings

    ) {
        // Set the defaults
        this._authenticated = false;

    }

    get user(): UserAuth {
        try {
            if (this._appSettings.user) {
                return this._appSettings.user;
            }
            // try load from storage
            const us = localStorage.getItem('_u');
            if (us) {
                const u = JSON.parse(us);
                this._appSettings.user = new UserAuth(u);
                return this._appSettings.user;
            }
            this._authenticated = false;

        } catch {
            this._authenticated = false;
            throw new Error('Not signed in.');
        }
    }

    set user(user: UserAuth) {
        this._appSettings.user = user;
        const u = JSON.stringify(user);
        localStorage.setItem('_u', u);
    }

    signIn(credentials: TokenAuth): Observable<UserAuth> {

        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError(() => new Error('User is already logged in.'));
        }

        return this._httpClient.post(environment.api_path + '/auth/token', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.user = response;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return a new observable with the response
                return of(this.user);
            })
        );
    }

    signInActivate(username: string, code: string): Observable<UserAuth> {

        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError(() => new Error('User is already logged in.'));
        }

        return this._httpClient.post(environment.api_path + '/auth/activate', { username: username, code: code }).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.user = response;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return a new observable with the response
                return of(this.user);
            })
        );
    }


    signInWithFBToken(token: string): Observable<any> {
        // Throw error, if the user is already logged in

        let _params = new HttpParams().set('fbToken', token);


        return this._httpClient.post(environment.api_path + '/auth/FacebookLogin', null, { params: _params }).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.user = response;
                // Set the authenticated flag to true
                this._authenticated = true;
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    // signUp(user: NewUser): Observable<any> {

    //     return this._httpClient.post<NewUser>(environment.api_path + '/auth/signup', user)
    //         .pipe(
    //             map((results: boolean) => {
    //                 return results;
    //             })
    //         );

    // }

    signUp(user: NewUser): Observable<any> {

        return this._httpClient.post<NewUser>(environment.api_path + '/auth/CreateUser', user)
            .pipe(
                map((results: boolean) => {
                    return results;
                })
            );

    }


    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Renew token
        return this._httpClient.post(environment.api_path + '/auth/refreshtoken', {
            access_token: this.user.token
        }).pipe(
            catchError(() => {

                // Return false
                return of(false);
            }),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.user.token = response.access_token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {


        // Remove the access token from the local storage

        this._appSettings.init = false;
        localStorage.removeItem('access_token');
        localStorage.removeItem('_u');
        localStorage.removeItem('medapp_client_cache');
        localStorage.removeItem('token_id');
        localStorage.removeItem('medapp_message_cache');

        this._appSettings.clear();

        // Set the authenticated flag to false
        this._authenticated = false;
        // Return the observable
        return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {

        console.log('checking')

        // Check the access token availability
        if (!this.user || !this.user.token) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.user.token)) {
            return of(false);
        }

        return of(true);

    }

    public changeUser(id: string): any {

        const _self: any = this;

        this._httpClient.get(environment.api_path + '/auth/changeuser?id=' + id)
            .subscribe((response: any) => {
                _self.appSettings.clearLoggedInUser();
                _self.signalR.disconnect();
                _self.breeze.clear();
                _self.appSettings.init = false;
                _self.cache.clear();
                _self.gevents.event_setBusiness('');
                _self.gevents.stopTimers();
                localStorage.clear();


                if (response) {


                    // set token property
                    _self.appSettings.calDate = new Date();
                    _self.appSettings.saveToken(response);
                    _self.isAuthenticated.next(response);

                    if (response.type == 'ADMIN') {
                        _self.router.navigate(['/admin']);
                    }
                    else if (response.type == 'DOCTOR' || response.type == 'DOCADMIN') {
                        _self.router.navigate(['/bus/calendar']);
                    }
                    return response;
                } else {
                    // return false to indicate failed login
                    _self.isAuthenticated.error(false);
                    return false;
                }
            });
    }


}
