import { MetadataStore } from 'breeze-client';

import { Appointment } from './Appointment';

import { BookingSlot } from './BookingSlot';
import { Business } from './Business';
import { Certifications } from './Certifications';
import { Client } from './Client';

import { Image } from './Image';
import { Invoice } from './Invoice';
import { InvoiceItems } from './InvoiceItems';
import { Location } from './Location';
import { Log } from './Log';
import { MedicalAid } from './MedicalAid';
import { Message } from './Message';
import { NotificationQueue } from './NotificationQueue';
import { Schedule } from './Schedule';
import { Service } from './Service';
import { Setting } from './Setting';
import { Specialities } from './Specialities';
import { Staff } from './Staff';
import { UserAccess } from './UserAccess';
import { UserAccount } from './UserAccount';
import { Tag } from './Tag'
import { AppointmentInvoice } from './AppointmentInvoice';
import { AppointmentTag } from './AppointmentTag';
import { LocationService } from './LocationService';
import { StaffService } from './StaffService';

export class RegistrationHelper {


    //if its not in this list the methods wont be available only the properties.
    static register(metadataStore: MetadataStore): void {
        metadataStore.registerEntityTypeCtor('Appointment', Appointment, 'initializer');
        metadataStore.registerEntityTypeCtor('AppointmentInvoice', AppointmentInvoice);
        metadataStore.registerEntityTypeCtor('AppointmentTag', AppointmentTag);
        metadataStore.registerEntityTypeCtor('Invoice', Invoice);
        metadataStore.registerEntityTypeCtor('Tag', Tag);
        metadataStore.registerEntityTypeCtor('Invoice', Invoice);
        metadataStore.registerEntityTypeCtor('InvoiceItems', InvoiceItems);
        metadataStore.registerEntityTypeCtor('BookingSlot', BookingSlot);
        metadataStore.registerEntityTypeCtor('Business', Business);
        metadataStore.registerEntityTypeCtor('Certifications', Certifications);
        metadataStore.registerEntityTypeCtor('Client', Client);
        metadataStore.registerEntityTypeCtor('Image', Image);
        metadataStore.registerEntityTypeCtor('Location', Location);
        metadataStore.registerEntityTypeCtor('LocationService', LocationService);
        metadataStore.registerEntityTypeCtor('Log', Log);
        metadataStore.registerEntityTypeCtor('MedicalAid', MedicalAid);
        metadataStore.registerEntityTypeCtor('Message', Message);
        metadataStore.registerEntityTypeCtor('NotificationQueue', NotificationQueue);
        metadataStore.registerEntityTypeCtor('Schedule', Schedule);
        metadataStore.registerEntityTypeCtor('Service', Service);
        metadataStore.registerEntityTypeCtor('Setting', Setting);
        metadataStore.registerEntityTypeCtor('Specialities', Specialities);
        metadataStore.registerEntityTypeCtor('Staff', Staff);
        metadataStore.registerEntityTypeCtor('StaffService', StaffService);
        metadataStore.registerEntityTypeCtor('UserAccess', UserAccess);
        metadataStore.registerEntityTypeCtor('UserAccount', UserAccount);
    }
}
