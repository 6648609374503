import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   *
   * @param {AuthService} _authService
   */
  constructor(
    private _authService: AuthService
  ) {
  }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (req.url.indexOf('medapp.co.za') > 0 || req.url.indexOf('calendar5.com') > 0 || req.url.indexOf(environment.api_path) > 0 || req.url.indexOf('localhost') > 0) {
      if (this._authService.user && this._authService.user.token && !AuthUtils.isTokenExpired(this._authService.user.token)) {
        newReq = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + this._authService.user.token)
        });
      }
    }
    // Response
    return next.handle(newReq);
  }
}


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private _authService: AuthService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((response: any) => {
        if (request.url.indexOf('medapp.co.za') > 0 || request.url.indexOf('localhost') > 0 || request.url.indexOf('calendar5.com') > 0 || request.url.indexOf('calendarfive.com') > 0) {
          if (response instanceof HttpErrorResponse && response.status === 401) {
            console.log('not logged in.');
            this._authService.signOut();
            // Reload the app
            // location.reload();
          }
          return throwError(response);
        }
      }));
  }
}
