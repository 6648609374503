

import { HBUnitOfWork } from './hb-unit-of-work';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Appointment } from '../models/entities/EntityModel';
import { Observable } from 'rxjs';
import { DateTime, Interval } from 'luxon';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class ZoomService {

    constructor(
        private _httpClient: HttpClient,
        private uow: HBUnitOfWork
    ) {

    }

    public addZoomMeeting(appointmentId: string, password: string): Observable<any> {
        const paramsCurrent = new HttpParams().set('appointmentId', appointmentId).set('password', password);
        return this._httpClient.post(environment.api_path + '/zoom/addZoomMeeting', {}, { params: paramsCurrent, responseType: 'json' });
    }

    public getUsers(staffId: string): Observable<any> {
        const paramsCurrent = new HttpParams().set('staffId', staffId);
        return this._httpClient.get(environment.api_path + '/zoom/ListUsers', { params: paramsCurrent, responseType: 'json' });
    }

    public refreshToken(token: string, staffId: string): Observable<any> {

        const paramsCurrent = new HttpParams().set('refreshToken', token).set('staffId', staffId);
        return this._httpClient.post(environment.api_path + '/zoom/refreshToken', {}, { params: paramsCurrent });

    }

    public createZoomMeeting(apt: Appointment): Observable<any> {
        const staff = this.uow.staff.withIdInCache(apt.staffId);
        const business = this.uow.business.withIdInCache(apt.businessId);
        const paramsCurrent = new HttpParams().set('password', apt.zoom.password).set('appointmentId', apt.appointmentId).set('token', staff.zoomToken).set('refresh_token', staff.zoomRefreshToken).set('expiry', staff.zoomExpiry.toISOString()).set('timezone', business.timeZone);


        if (this.tokenIsFresh(staff.zoomExpiry)) {
            return this._httpClient.post(environment.api_path + '/zoom/createMeeting', {}, { params: paramsCurrent, responseType: 'json' });
        }
        else {
            return this.refreshToken(staff.zoomRefreshToken, staff.staffId).pipe(
                tap((tokenData) => {
                    staff.zoomToken = tokenData.access_token;
                    staff.zoomExpiry = tokenData.expiry;
                    staff.zoomRefreshToken = tokenData.refresh_token;
                    staff.entityAspect.setUnchanged();
                }),
                switchMap(() => {
                    return this._httpClient.post(environment.api_path + '/zoom/createMeeting', {}, { params: paramsCurrent, responseType: 'json' });
                })
            );
        }
    }

    public getTokens(_code: string, _staffId: string): Observable<any> {
        return this._httpClient.post(environment.api_path + '/zoom/generatetoken', { code: _code, staffId: _staffId });
    }

    private tokenIsFresh(expiry: Date): boolean {
        const later = DateTime.fromJSDate(expiry).diffNow('minutes');
        return (later.minutes < 15) ? false : true;

    }




    public startZoomMeeting(url): void {

        const popup = this.popupWindow(url, 'Zoom Meeting', window, 500, 500);

    }

    popupWindow(url, windowName, win, w, h): any {
        const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
        const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);
        return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
    }


}
