
import { MatIconModule } from '@angular/material/icon';
import { CalendarViewSelectorComponent } from './calendar-view-selector.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';



@NgModule({
  declarations: [
    CalendarViewSelectorComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule
  ],
  exports: [
    CalendarViewSelectorComponent
  ]
})
export class CalendarViewSelectorComponentModule { }
