import { AppSettings } from './app-settings.service';
import { Injectable } from '@angular/core';
import { EntityQuery, Predicate, FilterQueryOp, FetchStrategy } from 'breeze-client';
import { IBreezeMessage, IMessage } from '../models/entities/EntityModel';
import { BreezeService } from './breeze.service';
import { SupportServices } from './support.service';
import { GlobalEvents } from '../events/events';
import { Observable, Subscription, from } from 'rxjs';
import { filter, chain } from 'lodash-es';
import { DateTime } from 'luxon';

@Injectable()
export class MessageService {


    private refreshTimerSubscription: Subscription;
    private _recentCacheDate: DateTime;

    MESSAGE_CACHE = 'medapp_message_cache';

    constructor(
        private breezeService: BreezeService,
        private appSettings: AppSettings,
        private supportServices: SupportServices,
        private medappEvents: GlobalEvents
    ) {

        let value = localStorage.getItem(this.MESSAGE_CACHE);
        if (value) {
            this._recentCacheDate = DateTime.fromRFC2822(value);
        } else {
            this._recentCacheDate = DateTime.fromJSDate(new Date()).minus({ minutes: 20 });
            localStorage.setItem(this.MESSAGE_CACHE, this._recentCacheDate.toISO());
        }

        this.refreshTimerSubscription = this.medappEvents.refreshTimerFired$.subscribe(() => {
            this.getInboxAll().then((data) => {
                this.medappEvents.event_MessageReceived(data);
            }).catch((err) => {
                // this.noty.warn('Could not refresh your incoming messages. Please check internet connection.');
            });

        });

    }

    public getInboxRemote(id: string = ''): Promise<IBreezeMessage[] | any> {
        return new Promise((resolve, reject) => {
            const p1 = Predicate.create('businessId', 'eq', id);
            const p2 = Predicate.create('direction', FilterQueryOp.Equals, 'IN');
            const pred = Predicate.and([p1, p2]);
            const query = EntityQuery.from('Message').where(pred).orderBy('date desc');

            this.breezeService.service.executeQuery(query).then((data) => {
                resolve(data.results);
            });
        });
    }

    public getInbox(id: string = '', fromServer?: boolean): Promise<IBreezeMessage[] | any> {
        return new Promise((resolve, reject) => {

            const p1 = Predicate.create('businessId', 'eq', id);
            const p2 = Predicate.create('direction', FilterQueryOp.Equals, 'IN');
            const p3 = Predicate.create('date', FilterQueryOp.GreaterThan, DateTime.fromJSDate(new Date()).minus({ 'month': 1 }).toISODate());
            const pred = Predicate.and([p1, p2, p3]);
            let query = EntityQuery.from('Message').where(pred).orderBy('date desc').expand('client');


            if (!fromServer) {
                query = query.using(FetchStrategy.FromLocalCache);
            } else {
                query = query.using(FetchStrategy.FromServer);
            }

            this.breezeService.service.executeQuery(query).then((data) => {
                resolve(data.results);
            }).catch((err) => {
                reject();
            });

        });
    }

    public markBusinessRead(business: string) {
        return new Promise((resolve, reject) => {


            const p2 = Predicate.create('direction', 'eq', 'IN');
            const p3 = Predicate.create('isRead', 'eq', 'false');
            const p4 = Predicate.create('businessId', 'eq', business);
            const pred = Predicate.and([p2, p3, p4]);
            let query = EntityQuery.from('Message').where(pred);

            query = query.using(FetchStrategy.FromServer);

            // var array = this.breezeService.service.executeQueryLocally(query)
            // console.log(this.breezeService.service.getEntities());
            query = query.using(FetchStrategy.FromServer);

            this.breezeService.service.executeQuery(query).then((data) => {
                data.results.forEach((item: IBreezeMessage, index) => {
                    item.isRead = true;
                });
                this.breezeService.saveChanges().then(() => {
                    resolve(null);
                });
            });

        });
    }

    public markClientRead(id: string) {
        return new Promise((resolve, reject) => {

            const p1 = Predicate.create('clientId', 'eq', id);
            const p2 = Predicate.create('direction', 'eq', 'IN');
            const p3 = Predicate.create('isRead', 'eq', 'false');
            const pred = Predicate.and([p1, p2, p3]);
            let query = EntityQuery.from('Message').where(pred);

            query = query.using(FetchStrategy.FromServer);

            this.breezeService.service.executeQuery(query).then((data) => {
                data.results.forEach((item: IBreezeMessage, index) => {
                    item.isRead = true;
                });
                this.breezeService.saveChanges().then(() => {
                    resolve(null);
                });
            });


        });


    }

    public getInboxLast30Days(id: string = '', fromServer?: boolean): Promise<IBreezeMessage[]> {
        return new Promise((resolve, reject) => {


            //const p1 = Predicate.create('businessId', 'eq', id); 
            //This is a bug in the entity framework filtering it wont allow a filter from the client side + a filter on backend - maybe breeze needs to be fixed.
            //.where(p1);//.orderBy('date desc');.expand('client');//.toType('Message');
            //Has to do with a group by clause on the server.

            let query = EntityQuery.from('MessageNewestAll');
            if (!fromServer) {
                query = query.using(FetchStrategy.FromLocalCache);
            } else {
                query = query.using(FetchStrategy.FromServer);
            }

            this.breezeService.service.executeQuery(query).then((data) => {
                //     this.markBusinessRead(id);
                //      this.medappEvents.event_MessageReceived(<IBreezeMessage[]>data.results);

                var filteredList = filter(data.results, (value: any) => {
                    return value.businessId === this.appSettings.currentBID;
                });

                resolve(<IBreezeMessage[]>filteredList);

            }).catch(() => {
                reject();
            });

        });

    }

    public getInboxLast30DaysLocal(id: string = ''): Promise<IBreezeMessage[]> {
        return new Promise((resolve, reject) => {


            const p1 = Predicate.create('businessId', 'eq', id);
            let query = EntityQuery.from('Message').where(p1);//.orderBy('date desc');.expand('client');//.toType('Message');

            query = query.using(FetchStrategy.FromLocalCache);


            this.breezeService.service.executeQuery(query).then((data) => {
                //this.markBusinessRead(id);
                //this.medappEvents.event_MessageReceived(<IBreezeMessage[]>data.results);
                resolve(<IBreezeMessage[]>data.results);

            }).catch((err) => {
                console.log(err);
                reject();
            });

        });

    }


    public getInboxAll(id: string = '', fromServer?: boolean): Promise<IBreezeMessage[] | any> {
        return new Promise((resolve, reject) => {
            let query = EntityQuery.from('MessageNewest');//.orderBy('date desc').expand('client'); //.toType('Message');
            query = query.using(FetchStrategy.FromServer);
            this.breezeService.service.executeQuery(query).then((data) => {
                resolve(data.results);
            }).catch(() => {
                reject();
            });

        });
    }


    public getInboxUnread(id: string = '', fromServer?: boolean): Observable<IBreezeMessage[] | any> {
        const pms = new Promise((resolve, reject) => {

            const p1 = Predicate.create('businessId', 'eq', id);
            const p2 = Predicate.create('direction', FilterQueryOp.Equals, 'IN');
            const p3 = Predicate.create('isRead', 'eq', false);
            const p4 = Predicate.create('date', 'gt', DateTime.local().minus({ month: 1 }).toISODate());
            const pred = Predicate.and([p1, p2, p3, p4]);
            let query = EntityQuery.from('Message').where(pred).orderBy('date desc').expand('client');

            if (!fromServer) {
                query = query.using(FetchStrategy.FromLocalCache);
            } else {
                query = query.using(FetchStrategy.FromServer);
            }

            this.breezeService.service.executeQuery(query).then((data) => {
                resolve(data.results);
            });

        });

        return from(pms);
    }


    public getInboxUnreadLocal(id: string = '', fromServer?: boolean): IBreezeMessage[] {

        const p1 = Predicate.create('businessId', 'eq', id);
        const p2 = Predicate.create('direction', FilterQueryOp.Equals, 'IN');
        const p3 = Predicate.create('isRead', 'eq', false);
        const p4 = Predicate.create('date', 'gt', DateTime.local().minus({ month: 1 }).toISODate());
        const pred = Predicate.and([p1, p2, p3, p4]);
        const query = EntityQuery.from('Message').where(pred).orderBy('date desc').expand('client');

        return <IBreezeMessage[]>this.breezeService.service.executeQueryLocally(query);
    }



    public getIncomingMessages(fromServer: boolean = false): Observable<IBreezeMessage[] | any> {
        const pms = new Promise((resolve, reject) => {
            let query = EntityQuery.from('IncomingMessages').orderBy('date asc').toType('Message');

            if (!fromServer) {
                query = query.using(FetchStrategy.FromLocalCache);
            } else {
                query = query.using(FetchStrategy.FromServer);
            }

            this.breezeService.service.executeQuery(query).then((data) => {
                resolve(data.results);
            });
        });
        return from(pms);
    }

    public getInboxGroupedByTelephone(id: string): Observable<any> {

        const pwms = new Promise((resolve, reject) => {

            const inbox = this.getInbox(id, true).then((results) => {

                const groupedInbox = chain(results)
                    .groupBy('telephone')
                    .toPairs()
                    .map(function (currentItem: any) {

                        // when breeze filter is fixed can use the below.
                        return currentItem[1][0];
                    })
                    .value();

                resolve(groupedInbox);
            });

        });


        return from(pwms);


    }

    public getIncomingMessagesLocal(): Promise<IBreezeMessage[] | any> {

        return new Promise((resolve, reject) => {
            const p1 = Predicate.create('isRead', FilterQueryOp.Equals, false);
            const p2 = Predicate.create('direction', FilterQueryOp.Equals, 'IN');
            const p4 = Predicate.create('date', 'gt', DateTime.local().minus({ month: 1 }).toISODate());
            const pred = Predicate.and([p1, p2, p4]);
            let query = EntityQuery.from('Message').where(pred).orderBy('date desc');

            query = query.using(FetchStrategy.FromLocalCache);

            this.breezeService.service.executeQuery(query).then((data) => {
                resolve(data.results);
            });


        });

    }




    public getMessages(id: string = '', telephone: string = '', limit: number = 0): Promise<IBreezeMessage[] | any> {

        return new Promise((resolve, reject) => {
            const p1 = Predicate.create('clientId', FilterQueryOp.Equals, id);
            const p2 = Predicate.create('telephone', FilterQueryOp.Equals, telephone);
            const pred = Predicate.and([p1, p2]);
            const query = EntityQuery.from('Message').where(pred).orderBy('date');
            this.breezeService.service.executeQuery(query).then((data) => {
                resolve(data.results);
            });
        });


    }






    // public getOutbox(id: string): Promise<IBreezeLogging[] | any> {


    //     return new Promise((resolve, reject) => {
    //         const query = EntityQuery.from('Logging').where('businessId', 'eq', id);
    //         this.breezeService.service.executeQuery(query).then((data) => {
    //             resolve(data.results);
    //         });
    //     });

    // }





    public getMessage(id): IBreezeMessage {

        if (id) {
            return <IBreezeMessage>this.breezeService.service.getEntityByKey('Message', id);
        }

    }

    public delete(id): Promise<IBreezeMessage> {
        return new Promise((resolve, reject) => {
            const app = <IBreezeMessage>this.getMessage(id);
            if (app) {
                app.entityAspect.setDeleted();
                this.breezeService.saveChanges().then(() => {
                    resolve(null);
                }).catch(() => {
                    resolve(null);
                });
            }
        });


    }



    public getRecentMessagesWithCache(id: string = '', telephone: string = '', force: Boolean = false): Promise<IBreezeMessage[] | any> {

        let needsRefresh = false;

        if (force) {
            this._recentCacheDate = DateTime.local().minus({ minutes: 20 });
        }

        const _last = this._recentCacheDate;


        // last refresh date + 5 minutes
        const last = _last.plus({ minutes: 5 });
        // current time
        const now = DateTime.local();
        if (now > last) {
            needsRefresh = true;

        }

        return new Promise((resolve, reject) => {

            // var p1 = Predicate.create('doctorID', FilterQueryOp.Equals, id);
            const p2 = Predicate.create('clientId', FilterQueryOp.Equals, id);
            // var p2 = Predicate.create('telephone', FilterQueryOp.Equals, telephone);


            // var pred = Predicate.and([p1]);
            let query = EntityQuery.from('Message').where(p2).orderBy('date asc').take(5);

            if (!needsRefresh) {
                query = query.using(FetchStrategy.FromLocalCache);
                // console.log("Fetching messages from local")
            } else {

                query = query.using(FetchStrategy.FromServer);
                // console.log("Fetching patients from server");
            }
            this.breezeService.service.executeQuery(query).then((data) => {
                // this._recentCacheDate = moment();
                this._recentCacheDate = DateTime.local();
                localStorage.setItem(this.MESSAGE_CACHE, DateTime.local().toISO());
                resolve(data.results);

            }).catch((err) => {

                /// <reference path="" />
                reject('Offline');

            });

        });
    }




}
