import { Injectable, ElementRef } from '@angular/core';
import { Subject, Observable, Subscription, BehaviorSubject, Subscriber, interval } from 'rxjs';
import { AppSettings } from '../services/app-settings.service';
import {DateTime} from 'luxon'
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { IBreezeMessage, Staff } from '../models/entities/EntityModel';
import { timer } from 'rxjs';
import { LayoutStyleBuilder } from '@angular/flex-layout';

@Injectable()
export class GlobalEvents {







  constructor(private appSettings: AppSettings, private bp: BreakpointObserver) {

  }

  // New events
  public staffEntityChanged = new BehaviorSubject<Staff[]>([]);
  staffEntityChanged$ = this.staffEntityChanged.asObservable();

  public userImageChanged = new Subject<any>();
  userImageChanged$ = this.userImageChanged.asObservable();


  // Event for when a user has been Observable for when a user has changed.
  private loadingIndicator = new Subject<boolean>();
  loadingIndicator$ = this.loadingIndicator.asObservable();

  // Event for when a user has been Observable for when a user has changed.
  private businessAnnounced = new Subject<string>();
  businessAnnounced$ = this.businessAnnounced.asObservable();

  private staffAnnounced = new Subject<string>();
  staffAnnounced$ = this.staffAnnounced.asObservable();

  private serverAnnounceLogout = new Subject<null>();
  serverAnnounceLogout$ = this.serverAnnounceLogout.asObservable();

  // Event for when a the selected calendar list changes.
  private calendarListAnnounced = new BehaviorSubject<string>(null);
  calendarListAnnounced$ = this.calendarListAnnounced.asObservable();

  // observable that fires when menu opens
  // private toggleShowDeleted = new Subject<boolean>();
  // toggleShowDeleted$ = this.toggleShowDeleted.asObservable();

  // observable that fires when menu opens (side menu)
  private sideMenuChange = new Subject<boolean>();
  sideMenuChanged$ = this.sideMenuChange.asObservable();


  public newMessageArrive = new BehaviorSubject<IBreezeMessage[]>(null);
  newMessageArrived$ = this.newMessageArrive.asObservable();


  private refreshMessages = new Subject<boolean>();
  refreshMessages$ = this.refreshMessages.asObservable();




  private compactView = new Subject<boolean>();
  compactView$ = this.compactView.asObservable();


  // this is the mechanism to be used for timers and subscribing to when timers fire.
  private refreshTimerFired = new Subject<boolean>();
  refreshTimerFired$ = this.refreshTimerFired.asObservable();

  private refreshTimer: Observable<any>;
  private refreshSubscription: Subscription;

  public windowFocused: boolean;
  public lastFocus: DateTime;


  // reference to the header element for sizing purposes.

  public refreshBlocker = false;
  lastRefreshTime: DateTime = null;

  public init(): void {

    this.refreshBlocker = false;
    this.lastRefreshTime = DateTime.local();
    this.refreshTimer = interval(900000);
    this.refreshSubscription = this.refreshTimer.subscribe(() => {
      this.refresh();
    });

  }

  refresh(): void {
    if (!this.refreshBlocker && this.windowFocused) {

      const now = DateTime.fromJSDate(new Date());
      const last = this.lastFocus.plus({minutes: 5});
      if (now > last) {
        if (!this.refreshBlocker && this.windowFocused){
          this.lastFocus = DateTime.fromJSDate(new Date());
        }
        this.refreshTimerFired.next(null);

      }
    }
  }
  
  // when messages arrive.
  public event_MessageReceived(data: IBreezeMessage[]): void {
    this.newMessageArrive.next(data);
  }

  public event_fireRefresherManually(): void {
    console.log('Timer event fired to listeners MANUALLY.');
    let time = DateTime.fromJSDate(new Date())

    if (!this.lastRefreshTime) {
      this.lastRefreshTime = DateTime.fromJSDate(new Date());
      this.refreshTimerFired.next(null);
    }
    else if (time > this.lastRefreshTime.plus({seconds: 60})) {
      this.refreshTimerFired.next(null);
      this.lastRefreshTime = DateTime.fromJSDate(new Date());
    }

  }

  public event_loadingIndicator(on: boolean): void {
    this.loadingIndicator.next(on);
  }

  public event_refreshMessages(): void {
    this.refreshMessages.next(null);
  }

  public event_calendarListChanged(list: any): void {
    this.calendarListAnnounced.next(list);
  }

  public event_resizeUi(): void {
    this.sideMenuChange.next(null);
  }

  public logout() {
    this.serverAnnounceLogout.next(null);
  }


  public event_setBusiness(id: string): void {
    this.appSettings.currentBID = id;
    this.businessAnnounced.next(id);
  }

  public event_setStaff(id: string): void {
    this.appSettings.currentSID = id;
    this.staffAnnounced.next(id);
  }

  // public event_showDeleted(value: boolean): void {
  //   this.toggleShowDeleted.next(value);
  // }

  public clear(): void {

    this.staffEntityChanged.next([]);

    this.lastRefreshTime = DateTime.local();

    if (this.refreshSubscription)
      this.refreshSubscription.unsubscribe();
  }
}
