import { MatIconModule } from '@angular/material/icon';
import { CalendarNavigatorComponent } from './calendar-navigator.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { CalendarDatepickerMobileModule } from '../calendar-datepicker-mobile/calendar-datpicker-mobile.module';
import { SharedModule } from 'app/shared/shared.module';
// import { CoreModule } from 'app/core/core.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LuxonDateAdapter, LUXON_DATE_FORMATS } from 'app/shared/luxon.date.adapter';


@NgModule({
  declarations: [
    CalendarNavigatorComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    // CalendarDatepickerMobileModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: LUXON_DATE_FORMATS },
    { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE] }
  ],
  exports: [
    CalendarNavigatorComponent
  ]
})
export class CalendarNavigatorModule { }
