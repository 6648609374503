<!-- Navigation -->
<treo-vertical-navigation
    class="dark bg-gray-900"
    [appearance]="'thin'"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="!isScreenSmall">
  
    <ng-container treoVerticalNavigationContentHeader>
      
        <div class="flex items-center justify-center h-20">
            <img
                class="w-8"
                src="assets/undraw/char-dark.svg"
                alt="Logo image">
        </div>
    </ng-container>
</treo-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent">
        <!-- Navigation toggle button -->
        <button
            class="mr-2"
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-2">
            <app-business-selector *ngIf="multiuser && !isCalendar"></app-business-selector>
            <app-calendar-view-selector *ngIf="isCalendar && !isScreenSmall"></app-calendar-view-selector>
            <search [appearance]="'bar'"></search>
            <!-- <shortcuts [shortcuts]="data.shortcuts"></shortcuts> -->
            <messages></messages>
            <!-- <notifications [notifications]="data.notifications"></notifications> -->
            <user-menu *ngIf="!isScreenSmall"></user-menu>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent">
        <span class="font-medium text-secondary">Treo &copy; {{currentYear}}</span>
    </div> -->

</div>
