
/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Certifications {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    certificationsId: string;
    doctorId: string;
    name: string;
}

