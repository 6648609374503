import { Injectable } from '@angular/core';
import {find} from 'lodash-es';
import {DateTime} from 'luxon';

// DEPREICATED DEF DOES NOT WORK NEED DEBUG

@Injectable()
export class ClientCacheService {

    _list: Array<ClientCacheItem>;
    CLIENT_CACHE = 'hb_client_cache';
    _refreshTime = 15;

    constructor()
    {
        const value = localStorage.getItem(this.CLIENT_CACHE);
        if (value) {
            const cacheData = JSON.parse(value);
            this._list = cacheData as ClientCacheItem[];
        } else {
            this._list = new Array<ClientCacheItem>();
        }
    }

    save(): void {
        localStorage.setItem(this.CLIENT_CACHE, JSON.stringify(this._list));
    }

    clear(): void {
        localStorage.removeItem(this.CLIENT_CACHE);
        this._list = new Array<ClientCacheItem>();
    }

    private add(date: Date, businessId: string): void {
        const item = new ClientCacheItem();
        item.date = date;
        item.businessId = businessId;
        this._list.push(item);
    }

    // jsut checks cache does not modify
    checkCache(date: Date, businessId: string): boolean {
        const _date = DateTime.fromJSDate(date);
        const current = find(this._list, (o: ClientCacheItem | any) => {

            if (o.businessId === businessId) {
                return o;
            }
        });

        if (!current) {

            this.add(new Date(), businessId);
            return false;
        } else {
            // console.log(current);
           
            // last refresh date + 5 minutes
            const last = DateTime.fromJSDate(current.date).plus({minutes: this._refreshTime});
            // current time
            const now = DateTime.fromJSDate(new Date());
            if (now > last) {
                return false;
            }
            return true;
        }



    }

     cacheIsFresh(businessId): boolean {
     
        const current = find(this._list, (o: ClientCacheItem | any) => {
            if (o.businessId === businessId) {
                return o as ClientCacheItem;
            }
        });

        // there is no cache value means its stale
        if (!current) {
            this.add(new Date(), businessId);
            return false;
        } else {

            

            const last = DateTime.fromJSDate(current.date).plus({minutes: this._refreshTime});

            const now = DateTime.fromJSDate(new Date());
            if (now > last) {
                current.date = new Date();
                return false;
            }
            return true;
        }

    }
}

export class ClientCacheItem {
    businessId: string;
    date: Date;
}
