import { Location } from './Location';
import { Service } from './Service';
import { Entity } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class LocationService {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    locationServiceId: string;
    locationId: string;
    serviceId: string;
    location: Location;
    service: Service;
}

