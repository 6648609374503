import { AuthService } from 'app/core/auth/auth.service';
import { SupportServices } from 'app/core/services/support.service';
import { environment } from './../../../environments/environment';
import { GlobalEvents } from './../events/events';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { User } from './user.types';
import { UserAccount } from 'app/core/models/entities/UserAccount';
import { AppSettings } from 'app/core/services/app-settings.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    // Observables
    private _user: BehaviorSubject<UserAccount | null>;
    public userImageURL = '';


    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _auth: AuthService,
        private ge: GlobalEvents
    ) {
        // Set the defaults
        this._user = new BehaviorSubject(null);

        //User has logged out.
        this.ge.serverAnnounceLogout$.subscribe(() => {
            this._user.next(null);
        });

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: UserAccount) {
        // Store the value
        if (value && value.avatar && value.avatar !== '') {
            const timeStamp = (new Date()).getTime();
            this.userImageURL = AppSettings.IMAGE_USER_URL + value.avatar;
        }
        this._user.next(value);
    }

    get user$(): Observable<UserAccount> {
        return this._user.asObservable();
    }

    get getImage(): string {
        const timeStamp = (new Date()).getTime();
        return this.userImageURL + '?' + timeStamp;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    get(): void {

        this._user.subscribe((currentUser) => {
            if (currentUser) {
                //means we have a user.
                //same login wont populate here.
                if (this._auth.user.uid != currentUser.userId)
                    this._populateFromToken();

            }
            else {
                //we dont have a user so lets convert a token to a user.
                this._populateFromToken();
            }
        })
    }

    _populateFromToken(): void {
        this._httpClient.get(environment.api_path + '/auth/userinfo').pipe(
            take(1),
            tap((user: UserAccount) => {
                console.log(user);

                user = SupportServices.toCamel(user);

                // Execute the observable
                if (user.avatar !== '') {
                    const timeStamp = (new Date()).getTime();

                    this.userImageURL = AppSettings.IMAGE_USER_URL + user.avatar + '?timeStamp';
                }

                this._user.next(user);
            })
        ).subscribe();
    }


    /**
     * Update the user data
     *
     * @param user
     */
    update(user: UserAccount): Observable<any> {
        return this._httpClient.patch('api/common/user', { user }).pipe(
            tap(() => {

                // Execute the observable
                this._user.next(user);
            })
        );
    }
}
