import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogModule } from 'app/modules/components/confirmation-dialog/confirmation-dialog.module';
import { TreoAlertModule } from '@treo/components/alert';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TreoAlertModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ConfirmationDialogModule,
        TreoAlertModule
    ]
})
export class SharedModule {
}
