import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NotifyService } from './notify.service'
import {MatButtonModule} from '@angular/material/button'
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    ConfirmationDialogComponent
  ],
  providers: [
    NotifyService
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    FlexLayoutModule
  ],
  exports: [
    ConfirmationDialogComponent
  ]
})
export class ConfirmationDialogModule { }