<button data-testid="profile-button" *ngIf="user" mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user.avatar" [src]="imageURL">
        <mat-icon *ngIf="!showAvatar || !user.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full" [ngClass]="status"></span>
    </span>
</button>

<!-- <button *ngIf="!user" mat-icon-button [matMenuTriggerFor]="loggedOut">
    <span class="relative">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button> -->

<mat-menu class="user-actions-menu" [xPosition]="'before'" #userActions="matMenu">
    <div mat-menu-item>
        <span *ngIf="user" class="user-info">
            <span>Signed in as</span>
            <span class="email">{{user.username}}</span>

        </span>
    </div>

    <div *ngIf="isBus" disabled mat-menu-item>
        <span class="user-info">
            <span class="status-text">

                <div class="status-label">Server status</div>
                <!-- <span class="indicator" [ngClass]="status"></span> -->
                <span class="absolute flex h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full opacity-75" [class.bg-orange-400]="status == 'reconnecting'" [class.bg-green-400]="status == 'online'" [class.bg-red-400]="status == 'disconnected'"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3" [class.bg-orange-500]="status == 'reconnecting'" [class.bg-green-500]="status == 'online'" [class.bg-red-500]="status == 'disconnected'"></span>
                </span>

                <span class="status-desc">Connected</span>

            </span>

        </span>

    </div>
    <mat-divider class="my-2"></mat-divider>

    <a *ngIf="!isBus" [routerLink]="['/bus/calendar']" mat-menu-item>
        <mat-icon [svgIcon]="'business'"></mat-icon>
        <span>My Business</span>
    </a>

    <a [routerLink]="['/bus/user']" mat-menu-item>
        <mat-icon [svgIcon]="'account_circle'"></mat-icon>
        <span>Profile</span>
    </a>

    <button *ngIf="isBus" mat-menu-item [matMenuTriggerFor]="systemInfo">
        <mat-icon [svgIcon]="'dns'"></mat-icon>
        <span>System</span>
    </button>

    <mat-divider class="my-2"></mat-divider>
    <a *ngIf="isBus" [routerLink]="['/home']" mat-menu-item>
        <mat-icon [svgIcon]="'home'"></mat-icon>
        <span>Back to website</span>
    </a>

    <button data-testid="signout" mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'power_settings_new'"></mat-icon>
        <span>Sign out</span>
    </button>

</mat-menu>


<mat-menu #systemInfo="matMenu">
    <button (click)="checkForUpdates()" mat-menu-item>
        <mat-icon [svgIcon]="'system_update'"></mat-icon>
        <span>Check for updates</span>
    </button>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'settings'"></mat-icon>
        <span>Settings</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'confirmation_number'"></mat-icon>
        <span>Version</span>
    </button>
</mat-menu>

<!-- <mat-menu class="user-actions-menu" [xPosition]="'before'" #loggedOut="matMenu">
    <a [routerLink]="['/user']" mat-menu-item>
        <mat-icon [svgIcon]="'account_circle'"></mat-icon>
        <span>Sign-in</span>
    </a>

    <a [routerLink]="['/user']" mat-menu-item>
        <mat-icon [svgIcon]="'account_circle'"></mat-icon>
        <span>Sign-up</span>
    </a>

    <mat-divider class="my-2"></mat-divider>
    <a [routerLink]="['/user']" mat-menu-item>
        <mat-icon [svgIcon]="'account_circle'"></mat-icon>
        <span>Sign-In</span>
    </a>
</mat-menu> -->