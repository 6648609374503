import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { UserAuth } from '../models/auth/user.model';
import { DateAdapter } from '@angular/material/core';

import { DateTime } from 'luxon';
import { CalendarViewNames, AGENDA_VIEW_NAMES } from 'app/modules/business/calendar/scheduler/scheduler.types';
import { AppointmentCacheItem } from './cache/appointment-cach-item';

@Injectable()
export class AppSettings {

  // STATIC MEMBERS

  public static CALENDAR_RESOURCE_COLORS = { 0: 'red', 1: 'blue', 2: 'yellow', 3: 'green', 4: 'purple', 5: 'lightpurple', 6: 'orange' };
  public static GOOGLE_SYNC_TIME = 3600000;
  public static CALENDAR_REFRESH = 3600000;
  public static CACHE_TIME = 5;


  // BLOG STORE IMAGE URL
  public static IMAGE_UPLOAD_URL_PATH = '/Upload/UploadImage';
  public static IMAGE_STAFF_URL = 'https://medappimages.blob.core.windows.net/staff/';
  public static IMAGE_BUSINESS_URL = 'https://medappimages.blob.core.windows.net/business/';
  public static IMAGE_USER_URL = 'https://medappimages.blob.core.windows.net/user/';
  public static IMAGE_LOCATION_URL = 'https://medappimages.blob.core.windows.net/location/';


  // APPLICATION
  preInit = false; // angular has started up.
  init = false; // has the application initiatlized.
  token = ''; // current token used for signalR
  compact = false;
  mobile = false;
  tablet = false;


  // STORAGE KEYS
  TOKEN_ID = 'token_id'; // token id key
  APP_VERSION_KEY = 'app_version'; // application version key
  APP_VERSION = ''; // application version key
  CAL_SETTINGS = 'cal_settings'; // calendar settings key
  settings: any = {};

  showDeleted = true;


  // SIGNALR
  isLive = false;
  isOnline: boolean = navigator.onLine;
  liveState: any;
  lastLiveUpdate: any;

  // USER SPECIFIC IDENTIFIERS
  public user: UserAuth;
  public currentSID: string; // current staff ID this is the id to be used for settings etc.Secretaries have many accounts this is the current selected.
  public currentBID: string; // current staff ID this is the id to be used for settings etc.Secretaries have many accounts this is the current selected.
  public currentBIDcurrency: string; // current business ID

  public multiuser: boolean;

  public calendarList = new BehaviorSubject<object>([]);
  calendarListChanged$ = this.calendarList.asObservable();


  //CACHED ITEMS
  _cachedAppointmentList: Array<AppointmentCacheItem>;



  // CALENDAR SETTINGS
  public calDate: Date = new Date();
  public calSlotSize = '00:30:00';
  public calCalendarList = {};
  public calDefaultView: CalendarViewNames = 'timeGridDayHB';
  public calShowDeleted = false; // 0 (hide deleted) or -9(show deleted) as a number for show deleted.
  public calShowBusinessHours = false;

  public timeZone = 'Africa/Johannesburg';

  // Default posssible options
  calSlotSizeOptions = ['00:15:00', '00:30:00', '01:00:00'];
  // calViewOptions = ['timeGridHB', 'timeGridDayHB', 'timeGridThreeDayHB', 'timeGridWeekHB', 'timeGridFiveDayHB'];

  constructor(
    private bp: BreakpointObserver
  ) {
    // this.readToken();
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);


    if (this.bp.isMatched('(max-width: 599px)')) {
      this.calDefaultView = 'timeGridDayHB';
      this.compact = true;
    }

  }


  showDeletedNumber(): number {
    return this.calShowDeleted ? -9 : 0;

  }

  public getTimeZone(date: Date): DateTime {
    return DateTime.fromJSDate(date).setZone(this.timeZone);
  }

  public getTimeZoneFormat(date: Date, format?: string): string {
    return DateTime.fromJSDate(date).setZone(this.timeZone).toFormat(format);
  }

  public getDateTimeZone(date: Date): Date {
    const _tempDate = DateTime.fromJSDate(date).setZone(this.timeZone); // moment(date).tz(this.timeZone);
    return new Date(_tempDate.year, _tempDate.month, _tempDate.day, _tempDate.hour, _tempDate.minute, _tempDate.second);
  }

  updateOnlineStatus(event): void {
    this.isOnline = navigator.onLine;
  }

  isMultiUser(): boolean {
    // return (this.role.toUpperCase() === UserAuthorization.DOCADMIN) ? true : false;
    return this.multiuser;
  }


  loadPreferences(uid: string): void {
    // where we load calendar preferences + other values from localstorage

    const value = localStorage.getItem(uid);
    let save = false;
    try {
      let data = JSON.parse(value);
      if (!data) {
        // object does not exist in storage.
        data = {};
        save = true;
      }

      if (data.hasOwnProperty('calSlotSize') && this.calSlotSizeOptions.indexOf(data.calSlotSize) > -1) {
        this.calSlotSize = data.calSlotSize;
      } else {
        this.calSlotSize = '00:30:00';
      }

      if (data.hasOwnProperty('calDefaultView') && AGENDA_VIEW_NAMES.indexOf(data.calDefaultView) > -1) {

        if (this.compact) {
          this.calDefaultView = 'timeGridDayHB';
        } else {
          this.calDefaultView = data.calDefaultView;
        }

      } else {
        if (this.compact) {
          this.calDefaultView = 'timeGridDayHB';
        } else {
          this.calDefaultView = 'timeGridThreeDayHB';
        }
      }

      if (data.hasOwnProperty('calShowDeleted')) {
        this.calShowDeleted = Boolean(data.calShowDeleted);
      } else {
        this.calShowDeleted = false;
      }

      if (data.hasOwnProperty('calShowBusinessHours')) {
        this.calShowBusinessHours = Boolean(data.calShowBusinessHours);
      } else {
        this.calShowBusinessHours = false;
      }

      if (data.hasOwnProperty('calCalendarList')) {
        console.log('latest');
        console.log(data.calCalendarList);
        this.calCalendarList = data.calCalendarList;
      } else {
        this.calCalendarList = [];
      }
    } catch (err) {
      localStorage.removeItem(this.CAL_SETTINGS);
    }

    if (save) {
      this.savePreferences();
    }


  }

  savePreferences(): void {
    const data = {
      calShowDeleted: this.calShowDeleted,
      calSlotSize: this.calSlotSize,
      calDefaultView: this.calDefaultView,
      calShowBusinessHours: this.calShowBusinessHours,
      calCalendarList: { ...this.calCalendarList }

    };

    const stringData = JSON.stringify(data);
    localStorage.setItem(this.user.uid, stringData);
  }

  clear(): void {
    this._cachedAppointmentList = new Array<AppointmentCacheItem>();
    this.user = null;
    this.calCalendarList = [];
    this.user = null;
    this.currentSID = ''; // this is the id to be used for settings etc.Secretaries have many accounts this is the current selected.
    this.currentBID = '';
  }




}







