<!-- Item wrapper -->
<div
    class="treo-vertical-navigation-item-wrapper"
    [class.treo-vertical-navigation-item-has-subtitle]="!!item.subtitle"
    [ngClass]="item.classes?.wrapper">

    <div class="treo-vertical-navigation-item">

        <!-- Icon -->
        <mat-icon
            class="treo-vertical-navigation-item-icon"
            [ngClass]="item.classes?.icon"
            *ngIf="item.icon"
            [svgIcon]="item.icon"></mat-icon>

        <!-- Title & Subtitle -->
        <div class="treo-vertical-navigation-item-title-wrapper">
            <div class="treo-vertical-navigation-item-title">
                <span [ngClass]="item.classes?.title">
                    {{item.title}}
                </span>
            </div>
            <div
                class="treo-vertical-navigation-item-subtitle"
                *ngIf="item.subtitle">
                <span [ngClass]="item.classes?.subtitle">
                    {{item.subtitle}}
                </span>
            </div>
        </div>

        <!-- Badge -->
        <div
            class="treo-vertical-navigation-item-badge"
            *ngIf="item.badge">
            <div
                class="treo-vertical-navigation-item-badge-content"
                [ngClass]="item.badge.classes">
                {{item.badge.title}}
            </div>
        </div>

    </div>

</div>

<ng-container *ngFor="let item of item.children; trackBy: trackByFn">

    <!-- Skip the hidden items -->
    <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

        <!-- Basic -->
        <treo-vertical-navigation-basic-item
            *ngIf="item.type === 'basic'"
            [item]="item"
            [name]="name"></treo-vertical-navigation-basic-item>

        <!-- Collapsable -->
        <treo-vertical-navigation-collapsable-item
            *ngIf="item.type === 'collapsable'"
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"></treo-vertical-navigation-collapsable-item>

        <!-- Divider -->
        <treo-vertical-navigation-divider-item
            *ngIf="item.type === 'divider'"
            [item]="item"
            [name]="name"></treo-vertical-navigation-divider-item>

        <!-- Group -->
        <treo-vertical-navigation-group-item
            *ngIf="item.type === 'group'"
            [item]="item"
            [name]="name"></treo-vertical-navigation-group-item>

        <!-- Spacer -->
        <treo-vertical-navigation-spacer-item
            *ngIf="item.type === 'spacer'"
            [item]="item"
            [name]="name"></treo-vertical-navigation-spacer-item>

    </ng-container>

</ng-container>
