<div class="flex-col">
  <h1 class="text-2xl font-semibold pb-5" mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    {{data.description}}
  </div>
  <div class="pt-5" *ngIf="data.onlyInfo" mat-dialog-actions>
    <button mat-button (click)="ok()">Ok</button>
  </div>
  <div class="gap-1 pt-5" *ngIf="!data.onlyInfo" mat-dialog-actions>
    <button data-testid="confirm-yes" mat-button color="primary" (click)="yes()">Yes</button>
    <button data-testid="confirm-no"  mat-button (click)="no()" cdkFocusInitial>No</button>
  </div>

</div>
<!-- [mat-dialog-close]="1" -->