import { Subscription } from 'rxjs';
// import { Tag } from 'app/modules/admin/contacts/contacts.types';
import { AppointmentCacheList } from 'app/core/services/cache/appointment-cache.service';
import { AppSettings } from './app-settings.service';
import { UserAccess } from './../models/entities/UserAccess';
import { UserAccount } from './../models/entities/UserAccount';
import { Injectable } from '@angular/core';
import { Entity, EntityManager, FilterQueryOp, Predicate, EntityState, FetchStrategy, core } from 'breeze-client';
import { EntityFactory, EntityManagerProvider, IEntityFactory, IRepository, UnitOfWork } from '../services/data-access';

import { Location, Staff, Image, Setting, Service, BusinessType, Client, Business, NotificationQueue, Message, Schedule } from '../models/entities/EntityModel';
import { Tag } from '../models/entities/Tag';
import { Appointment } from '../models/entities/Appointment';
import { from, Observable, of } from 'rxjs';
import { NotificationProcess, NotificationAction, ValidationError, ISignalRAppointment } from '../models/entity-objects';
import { SupportServices } from './support.service';
import { skip, debounceTime, throttleTime, map } from 'rxjs/operators';
import { Zoom } from '../models/entities/Zoom';
import { Invoice } from '../models/entities/Invoice';

export class NotificationFactory extends EntityFactory<NotificationQueue> {
    
   
    
    
    constructor(
        manager: EntityManager,
        private _as: AppSettings,
        private appointment: IRepository<Appointment>,
        private location: IRepository<Location>,
        private business: IRepository<Business>,
        private staff: IRepository<Staff>,
        private setting: IRepository<Setting>) {

        super('NotificationQueue', manager);
    }



    async create(config: { appointmentId: string, process: NotificationProcess, action: NotificationAction, sMSCustom?: string }): Promise<NotificationQueue> {


        const apt = await this.appointment.withId(config.appointmentId, true);

        if (!apt) {
            throw new Error('Appointment could not be found.');
        }

        if (!apt.client) {
            throw new Error('No client to SMS or Email.');
        }

        if (config.process === NotificationProcess.Sms && (!apt.telephone || apt.telephone === '')) {
            throw new Error('Client no telephone number.');
        }

        if (config.process === NotificationProcess.Email && (!apt.email || apt.email === '')) {
            console.log('Client does not have an email address - nothing to do.');
            return;
        }

        const nf = await super.create({}, EntityState.Detached);

        nf.appointmentId = apt.appointmentId;
        nf.date = new Date();
        nf.dateTimeStart = this._as.getDateTimeZone(apt.startDate);
        nf.dateTimeEnd = this._as.getDateTimeZone(apt.endDate);
        nf.businessId = apt.businessId;
        nf.email = apt.client.email;
        nf.clientFirst = apt.client.firstname;
        nf.clientLast = apt.client.lastname;
        nf.clientId = apt.clientId;
        nf.telephone = apt.client.telephone;
        nf.otherInfo = '';
        nf.isBusinessOrigin = true;
        nf.direction = '';
        nf.type = NotificationAction[config.process].toUpperCase();
        nf.locationId = apt.locationId;
        nf.process = NotificationProcess[config.process].toUpperCase();


        let message = '';

        if (config.process === NotificationProcess.Sms) {
            const location = await this.location.withId(apt.locationId, true);
            const business = await this.business.withId(apt.businessId, true);
            const staff = await this.staff.withId(apt.staffId, true);

            // Populate either SMS or Email notification request.
            if (config.action === NotificationAction.New) {
                message = SupportServices.parseText(business.setting.smsonNewText, business, staff, this._as.getTimeZone(apt.startDate), location);
            }
            else if (config.action === NotificationAction.Update) {
                message = SupportServices.parseText(business.setting.smsonMoveText, business, staff, this._as.getTimeZone(apt.startDate), location);
            }
            else if (config.action === NotificationAction.Delete) {
                message = SupportServices.parseText(business.setting.smsonCancelText, business, staff, this._as.getTimeZone(apt.startDate), location);
            }
        }
        else if (config.process === NotificationProcess.Email) {
            // This is the Email message and the data is generated on the server (emails are long :)
        }

        nf.customMessage = message;

        return nf;
    }

    createSMS(config: { telephone: string, message: string, businessId: string, clientId: string, clientFirstname: string, clientLastname: string }): NotificationQueue {

        const nf = super.createNoPromise(config, EntityState.Detached);

        if (!config.clientId) {
            config.clientId = SupportServices.EmptyGuid;
        }
        if (!config.businessId) {
            return;
        }
        nf.appointmentId = SupportServices.EmptyGuid;
        nf.businessId = config.businessId;
        nf.clientId = config.clientId;
        nf.date = this._as.getDateTimeZone(new Date());
        nf.isBusinessOrigin = true;
        nf.clientFirst = config.clientFirstname;
        nf.clientLast = config.clientLastname;
        nf.otherInfo = '';
        nf.direction = '';
        nf.dateTimeEnd = this._as.getDateTimeZone(new Date());
        nf.dateTimeStart = this._as.getDateTimeZone(new Date());
        nf.telephone = config.telephone;
        nf.customMessage = config.message.substring(0, 160);
        nf.locationId = SupportServices.EmptyGuid;
        nf.process = NotificationProcess[NotificationProcess.Sms].toUpperCase();
        nf.type = NotificationAction[NotificationAction.Custom].toUpperCase();
        nf.email = '';
        return nf;

    }

}

export class AppointmentFactory extends EntityFactory<Appointment> {
    constructor(manager: EntityManager, private staff: IRepository<Staff>, private location: IRepository<Location>) {
        super('Appointment', manager);
    }

    async createFromSignalRObject(appointment: ISignalRAppointment): Promise<Appointment> {

        return await super.create(appointment, EntityState.Unchanged);

    }

    create(config: { staffId: string, start: Date, end: Date }): Promise<Appointment> {

        return super.create(config, EntityState.Detached).then(appointment => {

            return Promise.all([
                this.staff.withId(config.staffId, true)
            ]).then(result => {
                // set inital status values;
                const staff = result[0];



                appointment.subject = '';
                appointment.description = '';
                appointment.startDate = config.start;
                appointment.endDate = config.end;

                appointment.staffId = staff.staffId;

                appointment.status = 3;
                appointment.label = 3;
                appointment.colorId = '8';
                appointment.businessId = staff.businessId;
                appointment.isRecurring = false;




                const p1 = Predicate.create('businessId', 'eq', staff.businessId);
                const p2 = Predicate.create('deleted', 'eq', false);
                const p3 = Predicate.create('enabledBookings', 'eq', true);
                const pred: Predicate = Predicate.and([p1, p2, p3]);

                return this.location.where(pred).then(location => {
                    appointment.locationId = location[0].locationId;
                    return appointment;
                });

            });
        });
    }
}

export class ClientFactory extends EntityFactory<Client> {
    constructor(manager: EntityManager) {
        super('Client', manager);
    }

    create(): Promise<Client> {
        return super.create();
    }

    createDetached(): Promise<Client> {
        return super.create({}, EntityState.Detached);
    }
    createUnchanged(values: Client): Promise<Client> {

        return super.create(values, EntityState.Unchanged);
    }
}


export class LocationFactory extends EntityFactory<Location> {
    constructor(manager: EntityManager) {
        super('Location', manager);
    }

    create(values): Promise<Location> {
        return super.create(values);
    }

}

export class ServiceFactory extends EntityFactory<Service> {
    constructor(manager: EntityManager) {
        super('Service', manager);
    }

    create(values): Promise<Service> {
        return super.create(values);
    }

}

export class StaffFactory extends EntityFactory<Staff> {
    constructor(manager: EntityManager) {
        super('Staff', manager);
    }

    create(values): Promise<Staff> {
        return super.create(values);
    }

}


export class ZoomFactory extends EntityFactory<Zoom> {
    constructor(manager: EntityManager) {
        super('Zoom', manager);
    }

    create(values): Promise<Zoom> {
        return super.create(values);
    }

}

export class InvoiceFactory extends EntityFactory<Invoice> {
    constructor(manager: EntityManager) {
        super('Invoice', manager);
    }

    create(values): Promise<Invoice> {
        return super.create(values);
    }

    createQuick(values): Invoice {
        return super.createNoPromise(values);
    }

}

export class ScheduleFactory extends EntityFactory<Schedule> {
    constructor(manager: EntityManager) {
        super('Schedule', manager);
    }

    create(values): Promise<Schedule> {
        return super.create(values);
    }

    createDetached(values): Promise<Schedule> {
        return super.create(values, EntityState.Detached);
    }




}

export class TagFactory extends EntityFactory<Tag> {
    constructor(manager: EntityManager) {
        super('Tag', manager);
    }

    create(values): Promise<Tag> {
        return super.create(values);
    }

}

export class UserAccessFactory extends EntityFactory<UserAccess> {
    constructor(manager: EntityManager) {
        super('UserAccess', manager);
    }

    create(values): Promise<UserAccess> {
        return super.create(values);
    }
}


@Injectable()
export class HBUnitOfWork extends UnitOfWork {

    _staffSubscription: Subscription;

    // staffingResourceListItems: IRepository<StaffingResourceListItem>;
    appointments: IRepository<Appointment>;
    schedule: IRepository<Schedule>;
    messages: IRepository<Message>;
    services: IRepository<Service>;
    locations: IRepository<Location>;
    clients: IRepository<Client>;
    staff: IRepository<Staff>;
    setting: IRepository<Setting>;
    business: IRepository<Business>;
    businessType: IRepository<BusinessType>;
    userAccount: IRepository<UserAccount>;
    userAccess: IRepository<UserAccess>;
    notifications: IRepository<NotificationQueue>;
    images: IRepository<Image>;
    tag: IRepository<Tag>;
    zoom: IRepository<Zoom>;
    invoice: IRepository<Invoice>;

    appointmentFactory: AppointmentFactory;
    clientFactory: ClientFactory;
    notificationFactory: NotificationFactory;
    locationFactory: LocationFactory;
    invoiceFactory: InvoiceFactory;
    serviceFactory: ServiceFactory;
    staffFactory: StaffFactory;
    tagFactory: TagFactory;
    userAccessFactory: UserAccessFactory;
    scheduleFactory: ScheduleFactory;
    zoomFactory: ZoomFactory;


    constructor(private emProvider: EntityManagerProvider, private appSettings: AppSettings) {
        super(emProvider);
        console.log('Loading constructor for HB UOW');
        // this.staffingResourceListItems = this.createRepository<StaffingResourceListItem>(null, 'resourcemgt/staffingresourcelistitems');
        this.appointments = this.createRepository<Appointment>('Appointment', 'Appointment', false);
        this.services = this.createRepository<Service>('Service', 'Service', true);
        this.locations = this.createRepository<Location>('Location', 'Location', true);
        this.messages = this.createRepository<Message>('Message', 'Message', false);
        this.setting = this.createRepository<Setting>('Setting', 'Settting', true);
        this.clients = this.createRepository<Client>('Client', 'Client', true);
        this.business = this.createRepository<Business>('Business', 'Business', true);
        this.userAccount = this.createRepository<UserAccount>('UserAccount', 'UserAccount', true);
        this.userAccess = this.createRepository<UserAccess>('UserAccess', 'UserAccess', true);
        this.staff = this.createRepository<Staff>('Staff', 'Staff', true);
        this.notifications = this.createRepository<NotificationQueue>('NotificationQueue', 'NotificationQueue', true);
        this.images = this.createRepository<Image>('Image', 'Image', true);
        this.tag = this.createRepository<Tag>('Tag', 'Tag', true);
        this.businessType = this.createRepository<BusinessType>('BusinessType', 'BusinessType', true);
        this.schedule = this.createRepository<Schedule>('Schedule', 'Schedule', true);
        this.invoice = this.createRepository<Invoice>('Invoice', 'Invoice', false);

        this.appointmentFactory = new AppointmentFactory(this.manager, this.staff, this.locations);
        this.clientFactory = new ClientFactory(this.manager);
        this.notificationFactory = new NotificationFactory(this.manager, this.appSettings, this.appointments, this.locations, this.business, this.staff, this.setting);
        this.locationFactory = new LocationFactory(this.manager);
        this.staffFactory = new StaffFactory(this.manager);
        this.serviceFactory = new ServiceFactory(this.manager);
        this.tagFactory = new TagFactory(this.manager);
        this.userAccessFactory = new UserAccessFactory(this.manager);
        this.scheduleFactory = new ScheduleFactory(this.manager);
        this.zoomFactory = new ZoomFactory(this.manager);
        this.invoiceFactory = new InvoiceFactory(this.manager);
    }

    startListeners(): void {
        this._staffSubscription = this.entityTypeChanged('Staff').subscribe((staff) => {
            this.updateStaffResources();
        });
    }

    stopListenersAndClear(): void {
        this._staffSubscription.unsubscribe();
        this.clear();
    }

    async prepare(): Promise<boolean> {
        console.log('Prepare: loading initial data');
        const pBusiness = this.business.expand(['service', 'setting', 'staff', 'location', 'businessType', 'tag', 'userAccess']);
        const pUserAccount = this.userAccount.expand(['userAccess']);


        try {
            await Promise.all([pBusiness, pUserAccount]);
            this.startListeners();
            this.updateStaffResources();
            this.ensureTagsList();
            await this.userAccess.all();
            return true;
        } catch (err) {
            throw (err);
        }


    }

    getMe(): UserAccount {
        return this.userAccount.withIdInCache(this.appSettings.user.uid);
    }

    getLanguage(): string {
        console.log('set local');
        return "en_ZA";
    }


    // STAFF/RESOURCE METHODS
    public getAllStaff(onlyActive: boolean = true, businessId: string = null): Staff[] {

        const p1 = Predicate.create('businessId', 'eq', businessId);
        const p2 = Predicate.create('deleted', 'eq', false);
        const p3 = Predicate.create('enabled', 'eq', true);

        let pred: Predicate;
        if (onlyActive) {
            pred = Predicate.and([p2, p3]);
        }
        else {
            pred = Predicate.and([p1, p2]);
        }

        if (businessId) {
            pred = pred.and(p1);
        }


        return this.staff.whereInCache(pred);
    }

    public getAllServices(onlyActive: boolean = true, businessId: string = null): Service[] {

        const p1 = Predicate.create('businessId', 'eq', businessId);
        const p2 = Predicate.create('deleted', 'eq', false);
        const p3 = Predicate.create('enabled', 'eq', true);

        let pred: Predicate;
        if (onlyActive) {
            pred = Predicate.and([p2, p3]);
        }
        else {
            pred = Predicate.and([p1, p2]);
        }

        if (businessId) {
            pred = pred.and(p1);
        }


        return this.services.whereInCache(pred);
    }

    public getStaffById(id, onlyActive: boolean = true): Staff {
        const p1 = Predicate.create('staffId', 'eq', id);
        const p2 = Predicate.create('deleted', 'eq', false);
        const p3 = Predicate.create('enabled', 'eq', true);
        let pred: Predicate;

        if (onlyActive) {
            pred = Predicate.and([p1, p2, p3]);
        }
        else {
            pred = p1;
        }
        return this.staff.whereInCache(pred)[0];
    }

    public getBusinessImage(id: string): string {
        return this.business.withIdInCache(id).image;
    }
    public getStaffImage(id: string): string {
        return this.staff.withIdInCache(id).image;
    }
    public getUserImage(id: string): string {
        return this.userAccount.withIdInCache(id).avatar;
    }

    public getCurrentUserAccess(): UserAccess {
        const p1 = Predicate.create('userId', 'eq', this.appSettings.user.uid);
        return this.userAccess.whereInCache(p1)[0];
    }


    // Gets messages from the server 1 per business which is unread? - this looks pretty useless
    public async getMessagesLatestByBusiness(): Promise<Message[] | any> {

        const messages = await this.messages.whereEndpointParams('MessageNewest', null, null, null, null);
        console.log(messages);
        return messages;
    }

    // Gets messages for the past 3 days from the server for all businesses authorized.
    // Might want to create a cache object for this as it is server intensive.
    public async getRecentMessages(): Promise<Message[] | any> {

        const messages = await this.messages.whereEndpointParams('MessagesRecent', null, null, null, null);
        console.log(messages);
        return messages;
    }



    // Location specific queries.

    public getBookableLocations(businessId: string, onlyActive: boolean): Location[] {
        const p1 = Predicate.create('businessId', 'eq', businessId);
        const p2 = Predicate.create('enabledBookings', 'eq', true);
        const p3 = Predicate.create('deleted', 'eq', false);
        let pred: Predicate;
        if (onlyActive) {
            pred = Predicate.and([p1, p2, p3]);
        } else {
            pred = p1;
        }
        return this.locations.whereInCache(pred) as Location[];
    }

    public getTagsByBusiness(businessId: string): Tag[] {
        // console.log('get business');
        // console.log(businessId);
        const p1 = Predicate.create('businessId', 'eq', businessId);
        return this.tag.whereInCache(p1) as Tag[];
    }


    // Fix for local Staff resources.
    public updateStaffResources(): void {
        console.log('preparing resource');
        // Check if settings emtpy then hydrate fully
        if (Object.keys(this.appSettings.calCalendarList).length === 0) {
            for (const staff of this.getAllStaff(true)) {
                this.appSettings.calCalendarList[staff.staffId] = true;
            }
        } else {
            // Active list that needs to be rehydrated.
            // Clean Need to check if keys are all still valid in the list.
            for (const key in this.appSettings.calCalendarList) {
                if (this.appSettings.calCalendarList.hasOwnProperty(key)) {
                    const resourceDetails = this.getStaffById(key, true);
                    if (!resourceDetails) {
                        // means the resource in the list no longer valid.
                        delete this.appSettings.calCalendarList[key];
                    }
                }
            }

            // Check there are no new staff added to the master staff active list.
            for (const staff of this.getAllStaff(true)) {
                if (!this.appSettings.calCalendarList.hasOwnProperty(staff.staffId)) {
                    this.appSettings.calCalendarList[staff.staffId] = true;
                }
            }
        }
        this.appSettings.savePreferences();

    }

    public async ensureTagsList(): Promise<null> {

        let business = this.business.getEntities();
        let hasChanges = false;

        for (const key of business) {
            let tags = this.getTagsByBusiness(key.businessId);
            if (tags.length == 0) {
                hasChanges = true;
                let cancelled = {
                    tagId: core.getUuid(),
                    businessId: key.businessId,
                    text: ['Cancelled'],
                    description: ['Tag to identify appointments which are cancelled.'],
                }

                let noShow = {
                    tagId: core.getUuid(),
                    businessId: key.businessId,
                    text: ['No Show'],
                    description: ['Tag used to identify when a client does not show up.'],
                }

                let paid = {
                    tagId: core.getUuid(),
                    businessId: key.businessId,
                    text: ['Paid'],
                    description: ['Tag used to flag appoinements that are paid.'],
                }

                let unpaid = {
                    tagId: core.getUuid(),
                    businessId: key.businessId,
                    text: ['Unpaid'],
                    description: ['Tag used to flag appoinements that are unpaid.'],
                }

                await this.tagFactory.create(cancelled);
                await this.tagFactory.create(noShow);
                await this.tagFactory.create(paid);
                await this.tagFactory.create(unpaid);
            }

            if (hasChanges) {
                await this.commit();
            }

            return null;

        }
        // const serv = await this.uow.tagFactory.create(tag);
        // if (serv.entityAspect.hasValidationErrors) {
        //     console.log(serv.entityAspect.getValidationErrors());
        //     throw new ValidationError('Save failed', serv.entityAspect.getValidationErrors());
        // }
        // this.uow.commit();
        // return;


    }


    public async currentBusiness(): Promise<Business> {
        return this.business.withId(this.appSettings.currentBID, true);
    }

    public currentBusinessInCache(): Business {
        return this.business.withIdInCache(this.appSettings.currentBID);
    }


    //Need to use this to get records from server because of client security checks
    getClient(clientId: string = ''): Observable<Client> {

        const pred = Predicate.create('clientId', FilterQueryOp.Equals, clientId);

        let client: Client;

        client = this.clients.withIdInCache(clientId);

        if (client) {
            return of(client);
        }

        return from(this.clients.whereEndpointParams('Client', { businessId: this.appSettings.currentBID }, undefined, undefined, false, pred)).pipe(
            map((clients) => {
                return clients[0];
            }));

    }

}


//this is a special unit of work that managed user access.
//it is separate so that the data does not polute the rest of the system and populate additional user accounts.
@Injectable()
export class UserAccessUOW extends UnitOfWork {

    userAccount: IRepository<UserAccount>;

    constructor(emProvider: EntityManagerProvider) {
        super(emProvider);
        this.userAccount = this.createRepository<UserAccount>('UserAccount', 'UserAccount', false);
    }
}
