import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SignalRServiceBase } from './signalR-base.service';
import { Appointment } from '../../models/entities/EntityModel';
import { ISignalRAppointment } from 'app/core/models/entity-objects';


@Injectable()
export class SignalROutboundService {

    private sentNewAppointmentSubject = new Subject<Appointment>();
    private sentUpdateAppointmentSubject = new Subject<Appointment>();
    private sentDeleteAppointmentSubject = new Subject<Appointment>();


    // Observable string streams
    sentNewAppointment$ = this.sentNewAppointmentSubject.asObservable();
    sentDeleteAppointment$ = this.sentDeleteAppointmentSubject.asObservable();
    sentUpdateAppointment$ = this.sentUpdateAppointmentSubject.asObservable();

    constructor(private signalR: SignalRServiceBase) {

    }

    public broadcastDeleteAppointment(appointment: ISignalRAppointment): void {
        try {
            this.signalR.connection.invoke('broadcastDeleteAppointment', appointment).catch(err => {
                console.error(err.toString());
            });

        } catch (err) {
            console.log(err);
        }
    }

    public broadcastUpdateAppointment(appointment: ISignalRAppointment): void {
        try {
            this.signalR.connection.invoke('broadcastUpdateAppointment', appointment).catch(err => console.error(err.toString()));
        } catch (err) {
            console.log(err);
        }
    }

    public broadcastNewAppointment(appointment: ISignalRAppointment): void {
        console.log('SR:Broadcast New');
        try {
            this.signalR.connection.invoke('broadcastNewAppointment', appointment).catch(err => {
                console.error(err.toString());
            });
        } catch (err) {
            console.log(err);

        }
    }

}
