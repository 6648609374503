import { TreoNavigationItem } from '@treo/components/navigation';
export const businessNavigation: TreoNavigationItem[] = [
    {
        id: 'main.calendar',
        title: 'Calendar',
        type: 'basic',
        link: '/bus/calendar',
        icon: 'heroicons_outline:home'
    },
    {
        id: 'main.dashboard',
        title: 'Dashboard',
        // subtitle: 'Insights on your business.',
        type: 'basic',
        link: '/bus/dashboards/finance',
        icon: 'heroicons_outline:chart-pie'
    },
    {
        id: 'main.clients',
        title: 'Clients',
        // subtitle: 'Add, Edit or Delete contacts',
        type: 'basic',
        link: '/bus/apps/contacts',
        icon: 'heroicons_outline:user-group',

    },
    {
        id: 'main.search',
        title: 'Search',
        type: 'basic',
        link: '/bus/apps/search',
        // subtitle: 'Search for anything you have tagged.',
        icon: 'heroicons_outline:search-circle'
    },
    {
        id: 'main.send',
        title: 'Messages',
        type: 'basic',
        // subtitle: 'Easily send messages to or many clients',
        icon: 'heroicons_outline:chat-alt'
    },
    {
        id: 'main.invoice',
        title: 'Invoices',
        type: 'basic',
        link: '/bus/apps/invoice/list',
        // subtitle: 'Easily send invoices to clients',
        icon: 'heroicons_outline:collection'
    },
    {
        id: 'main.settings',
        title: 'Settings',
        type: 'basic',
        link: '/bus/apps/settings',
        // subtitle: 'Easily send invoices to clients',
        icon: 'heroicons_outline:cog'
    },
    {
        id: 'applications.settings',
        title: 'Settings',
        type: 'collapsable',
        icon: 'heroicons_outline:cog',
        children: [
            {
                id: 'applications.settings.business',
                title: 'Business Profile',
                type: 'basic',
                link: '/bus/apps/business',
                icon: 'heroicons_outline:office-building'
            },
            {
                id: 'applications.settings.staff',
                title: 'Staff',
                type: 'basic',
                link: '/bus/apps/staff',
                icon: 'heroicons_outline:users'
            },
            {
                id: 'applications.settings.locations',
                title: 'Locations',
                type: 'basic',
                link: '/bus/apps/locations',
                icon: 'heroicons_outline:location-marker'
            },
            {
                id: 'applications.settings.services',
                title: 'Services',
                type: 'basic',
                link: '/bus/apps/services',
                icon: 'heroicons_outline:cash'
            },
            {
                id: 'applications.settings.notifications',
                title: 'Notifications',
                type: 'basic',
                link: '/bus/apps/notifications',
                icon: 'heroicons_outline:bell'
            },
            {
                id: 'applications.settings.tag',
                title: 'Tags',
                type: 'basic',
                link: '/bus/apps/tags',
                icon: 'heroicons_outline:tag'
            },
            {
                id: 'applications.settings.credit-card',
                title: 'Billing',
                icon: 'heroicons_outline:credit-card',
                type: 'basic',
                link: '/bus/apps/business/billing'
            },
            // {
            //     id: 'applications.settings.invoice',
            //     title: 'Invoice',
            //     type: 'collapsable',
            //     icon: 'heroicons_outline:cash',
            //     children: [
            //         {
            //             id: 'applications.settings.invoice.create',
            //             title: 'New Invoice',
            //             icon: 'heroicons_outline:document-add',
            //             type: 'basic',
            //             link: '/apps/invoice/add'
            //         },
            //         {
            //             id: 'applications.settings.invoice.list',
            //             title: 'List Invoices',
            //             icon: 'heroicons_outline:collection',
            //             type: 'basic',
            //             link: '/apps/invoice/list'
            //         },
            //         {
            //             id: 'applications.settings.invoice.settings',
            //             title: 'Settings',
            //             icon: 'heroicons_outline:cog',
            //             type: 'basic',
            //             link: '/apps/invoice/settings'
            //         }
            //     ]
            // },
            {
                id: 'applications.settings.sharing',
                title: 'Sharing',
                type: 'basic',
                link: '/bus/apps/sharing',
                icon: 'heroicons_outline:share'
            }

        ]
    },
]