import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarIconComponent } from './avatar-icon/avatar-icon.component';


@NgModule({
  declarations: [
    AvatarIconComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AvatarIconComponent
  ]
})
export class AppAvatarIconModule { }
