import { AnalyticsMockApi } from 'app/mock-api/dashboards/analytics/api';
import { AuthMockApi } from 'app/mock-api/common/auth/api';
// import { CalendarMockApi } from 'app/mock-api/apps/calendar/api';
import { ContactsMockApi } from 'app/mock-api/apps/contacts/api';
// import { CryptoMockApi } from 'app/mock-api/dashboards/crypto/api';
// import { ECommerceInventoryMockApi } from 'app/mock-api/apps/ecommerce/inventory/api';
import { FinanceMockApi } from 'app/mock-api/dashboards/finance/api';
import { HelpCenterMockApi } from 'app/mock-api/pages/help-center/api';
import { IconsMockApi } from 'app/mock-api/ui/icons/api';
// import { MailboxMockApi } from 'app/mock-api/apps/mailbox/api';
// import { MessagesMockApi } from 'app/mock-api/common/messages/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
// import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { SearchMockApi } from 'app/mock-api/common/search/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';
import { TasksMockApi } from 'app/mock-api/apps/tasks/api';
import { UserMockApi } from 'app/mock-api/common/user/api';

export const mockApiServices = [
    AnalyticsMockApi,
    AuthMockApi,
    // CalendarMockApi,
    ContactsMockApi,
    // CryptoMockApi,
    // ECommerceInventoryMockApi,
    FinanceMockApi,
    HelpCenterMockApi,
    IconsMockApi,
    // MailboxMockApi,
    // MessagesMockApi,
    NavigationMockApi,
    // NotificationsMockApi,
    SearchMockApi,
    ShortcutsMockApi,
    TasksMockApi,
    UserMockApi
];
