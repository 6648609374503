import { Business } from './Business';
import { EntityBase } from './EntityBase';
import { UserAccount } from './UserAccount';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class UserAccess  extends EntityBase {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    userAccessId: string;
    authorized: boolean;
    businessId: string;
    createInvoice: boolean;
    editCalendar: boolean;
    generalSettings: boolean;
    manageBusiness: boolean;
    manageLocations: boolean;
    manageNotifications: boolean;
    manageStaff: boolean;
    owner: boolean;
    userId: string;
    viewReports: boolean;
    business: Business;
    user: UserAccount;
}

