import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { SupportServices } from '../../../../core/services/support.service'
@Component({
  selector: 'app-avatar-icon',
  templateUrl: './avatar-icon.component.html',
  styleUrls: ['./avatar-icon.component.scss']
})
export class AvatarIconComponent implements OnInit, OnChanges {


    @Input() name:string = '';
    @Input() size:string = '';
    
    color:string;
    initial:string;



    constructor() {
      
    }

    ngOnInit(): void {
    
      this.color = SupportServices.getColorFromInitial(this.name);
      this.initial = this.name.charAt(0);
    }

    ngOnChanges(changes: SimpleChanges): void {

    

      this.color = SupportServices.getColorFromInitial(changes.name.currentValue);
      this.initial = changes.name.currentValue.charAt(0);

  }

}
