import { Injectable } from '@angular/core';

import { find } from 'lodash-es';
import { Business, Staff, Location } from '../models/entities/EntityModel';
import { DateTime } from 'luxon';

export interface Address {
    streetNo: string,
    postal_code: string,
    street: string,
    region: string,
    city: string,
    country: string
}


@Injectable()
export class SupportServices {
    static IID = 'SupportServices';
    static $inject = ['$mdToast', '$mdDialog'];
    static EmptyGuid = '00000000-0000-0000-0000-000000000000';

    public static COLORSDX = [

        { text: 'Gray', id: 8, color: '#E1E1E1', fcolor: '#444' },
        { text: 'Blue', id: 12, color: '#2196F3', fcolor: 'white' },
        { text: 'Bold Blue', id: 9, color: '#0273D4', fcolor: 'white' },
        { text: 'LightBlue', id: 1, color: '#A4BDFC', fcolor: 'white' },
        { text: 'Bold Green', id: 10, color: '#51B749', fcolor: 'white' },
        { text: 'Turquoise', id: 7, color: '#46D6DB', fcolor: 'white' },
        { text: 'Teal', id: 2, color: '#7AE7BF', fcolor: 'white' },
        { text: 'Yellow', id: 5, color: '#FBD75B', fcolor: '#444' },
        { text: 'Deep Red', id: 11, color: '#DC2127', fcolor: 'white' },
        { text: 'Orange', id: 4, color: '#FF887C', fcolor: 'white' },
        { text: 'Deep Purple', id: 6, color: '#FFB878', fcolor: 'white' },
        { text: 'Yellow', id: 3, color: '#DBADFF', fcolor: 'white' },
        { text: 'Gray', id: 0, color: '#A4BDFC', fcolor: 'white' }
    ];

    public static SMSCODES = [
        { value: 0, text: 'No Status Available' },
        { value: 11, text: 'Delivered to mobile' },
        { value: 22, text: 'Internal fatal error' },
        { value: 23, text: 'Authentication failure' },
        { value: 24, text: 'Data validation failed' },
        { value: 25, text: 'You do not have sufficient credits' },
        { value: 26, text: 'Upstream credits not available' },
        { value: 27, text: 'You have exceeded your daily quota' },
        { value: 28, text: 'Upstream quota exceeded' },
        { value: 29, text: 'Message sending cancelled' },
        { value: 31, text: 'Unroutable' },
        { value: 32, text: 'Blocked (probably because of a recipient’s complaint against you). Often occurs due to incorrect number being SMSd. Please check the patient number is correct' },
        { value: 33, text: 'Failed: censored' },
        { value: 50, text: 'Delivery failed - generic failure. Out of service / not a cell number.' },
        { value: 51, text: 'Delivery to phone failed. Out of service or not a cell number' },
        { value: 52, text: 'Delivery to network failed' },
        { value: 53, text: 'Message expired' },
        { value: 54, text: 'Failed on remote network' },
        { value: 55, text: 'Failed: remotely blocked (variety of reasons)' },
        { value: 56, text: 'Failed: remotely censored (typically due to content of message)' },
        { value: 57, text: 'Failed due to fault on handset (e.g. SIM full)' },
        { value: 64, text: 'Queued for retry after temporary failure delivering, due to fault on handset (transient)' },
        { value: 70, text: 'Unknown upstream status' }
    ];

    public static COLORS = [
        { text: 'Gray', value: 8, color: '#E1E1E1' },
        { text: 'Blue', value: 12, color: '#2196F3' },
        { text: 'Bold Blue', value: 9, color: '#0273D4' },
        { text: 'LightBlue', value: 1, color: '#A4BDFC' },
        { text: 'Bold Green', value: 10, color: '#51B749' },
        { text: 'Turquoise', value: 7, color: '#46D6DB' },
        { text: 'Teal', value: 2, color: '#7AE7BF' },
        { text: 'Yellow', value: 5, color: '#FBD75B' },
        { text: 'Deep Red', value: 11, color: '#DC2127' },
        { text: 'Orange', value: 4, color: '#FF887C' },
        { text: 'Deep Purple', value: 6, color: '#FFB878' },
        { text: 'Yellow', value: 3, color: '#DBADFF' },
        { text: 'Gray', value: 0, color: '#A4BDFC' }
    ];

    public static GCOLOR = {
        8: { text: 'Graphite', color: '#616161', otext: 'Gray', id: 8, ocolor: '#E1E1E1', fcolor: 'white' }, // id is the current medapp ID
        12: { text: 'Blueberry', color: '#3F51B5', otext: 'Blue', id: 12, ocolor: '#2196F3', fcolor: 'white' },
        9: { text: 'Blueberry', color: '#3F51B5', otext: 'Bold Blue', id: 9, ocolor: '#0273D4', fcolor: 'white' },
        1: { text: 'Peacock', color: '#039BE5', otext: 'LightBlue', id: 1, ocolor: '#A4BDFC', fcolor: 'white' },
        10: { text: 'Basil', color: '#0B8043', otext: 'Bold Green', id: 10, ocolor: '#51B749', fcolor: 'white' },
        7: { text: 'Sage', color: '#33B679', otext: 'Turquoise', id: 7, ocolor: '#46D6DB', fcolor: 'white' },
        2: { text: 'Flamingo', color: '#E67C73', otext: 'Teal', id: 2, ocolor: '#7AE7BF', fcolor: 'white' },
        5: { text: 'Banana', color: '#F6BF26', otext: 'Yellow', id: 5, ocolor: '#FBD75B', fcolor: '#444' },
        11: { text: 'Red', color: '#D50000', otext: 'Deep Red', id: 11, ocolor: '#DC2127', fcolor: 'white' },
        4: { text: 'Tangerine', color: '#F4511E', otext: 'Orange', id: 4, ocolor: '#FF887C', fcolor: 'white' },
        6: { text: 'Grape', color: '#8E24AA', otext: 'Deep Purple', id: 6, ocolor: '#FFB878', fcolor: 'white' },
        3: { text: 'Lavender', color: '#7986CB', otext: 'Purple', id: 3, ocolor: '#DBADFF', fcolor: 'white' },
        0: { text: 'Graphite', color: '#616161', otext: 'Gray', id: 0, ocolor: '#A4BDFC', fcolor: 'white' }
    };

    public static defaultPalette = [

        '#1abc9c', '#2ecc71', '#3498db',
        '#9b59b6', '#34495e', '#16a085',
        '#27ae60', '#2980b9', '#8e44ad',
        '#2c3e50', '#f1c40f', '#e67e22',
        '#e74c3c', '#95a5a6', '#f39c12',
        '#d35400', '#c0392b', '#bdc3c7',
        '#7f8c8d', '#F44336', '#E91E63',
        '#9C27B0', '#673AB7', '#3F51B5',
        '#2196F3', '#03A9F4', '#00BCD4',
        '#009688', '#4CAF50', '#8BC34A',
        '#CDDC39', '#FFEB3B', '#FFC107',
        '#FF9800', '#FF5722', '#795548',
        '#E57373', '#F06292', '#F06292',
        '#7986CB', '#64B5F6', '#4FC3F7',
        '#4DD0E1', '#4DB6AC', '#81C784',
        '#AED581', '#DCE775', '#FFF176',
        '#FFD54F', '#FFB74D', '#FF8A65',
        '#A1887F'
    ];



    constructor() {
        return this;
    }

    public static isInstalled(): boolean {

        
        return (('standalone' in window.navigator) && (window.navigator['standalone'])) || (window.matchMedia('(display-mode: standalone)').matches) || window.hasOwnProperty("cordova");
    }



    public static getColorHashFromColorId(id: number | string): string {
        const _color = find(SupportServices.COLORS, { 'value': Number(id) });
        if (_color) {
            return _color.color
        }
        else {
            console.log('Could not find a color id:' + id);
            return '#E1E1E1';
        }
    }

    public static getColorIDFromColorHash(hash: string): number {
        const _color = find(SupportServices.COLORS, { 'color': hash });
        console.log(SupportServices.COLORS);
        console.log(_color);
        if (_color) {
            return _color.value
        }
        else {
            console.log('Could not find a color hashmap.' + hash);
            return 9;
        }
    }

    public static parseText(value: string, business: Business, staff: Staff, mo: DateTime, loc: Location): string {

        let output: string = value;
        let first: string = staff.firstname;
        let businessname: string = business.name;
        let last: string = staff.lastname;
        let title: string = staff.title;
        let date: string = mo.toFormat('EEE dd-MM-yyyy');
        let time: string = mo.toFormat('HH:mm');

        let location: string = loc.name;
        let building: string = loc.addressBuildingNo;
        let street: string = loc.addressStreet;
        let directions: string = loc.directionDescription;
        let telephone: string = loc.telephone;

        if (output != null) {
            output = output.replace(new RegExp('@firstname', 'gi'), first);
            output = output.replace(new RegExp('@lastname', 'gi'), last);
            output = output.replace(new RegExp('@business', 'gi'), businessname);
            output = output.replace(new RegExp('@title', 'gi'), title);
            output = output.replace(new RegExp('@date', 'gi'), date);
            output = output.replace(new RegExp('@time', 'gi'), time);
            output = output.replace(new RegExp('@location', 'gi'), location);
            output = output.replace(new RegExp('@building', 'gi'), building);
            output = output.replace(new RegExp('@street', 'gi'), street);
            output = output.replace(new RegExp('@directions', 'gi'), directions);
            output = output.replace(new RegExp('@telephone', 'gi'), telephone);
        }
        return output;
    }



    public static getColorFromLongString(value: string): string {
        const arr = value.split('');
        if (arr.length > 2) {
            return this.getColorFromInitials(arr[0], arr[1]);
        }
        else {
            return this.getColorFromInitial(arr[0]);
        }
    }

    public static getColorFromInitials(first: string, last: string): string {

        let i = parseInt(first.charAt(0), 36) - 9;
        const ii = parseInt(last.charAt(0), 36) - 9;
        let total = i + ii;
        return SupportServices.defaultPalette[total];
    }
    public static getColorFromSingle(first: string): string {
        let i = parseInt(first.charAt(0), 36) - 9;
        let total = i;
        return SupportServices.defaultPalette[total];
    }


    public static getColorFromInitial(first: string): string {
        let i = parseInt(first.charAt(0), 36);
        let total = i;
        return SupportServices.defaultPalette[total];
    }


    public static toCamel(o) {
        var newO, origKey, newKey, value
        if (o instanceof Array) {
            return o.map(function (value) {
                if (typeof value === "object") {
                    value = SupportServices.toCamel(value)
                }
                return value
            })
        } else {
            newO = {}
            for (origKey in o) {
                if (o.hasOwnProperty(origKey)) {
                    newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                    value = o[origKey]
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = SupportServices.toCamel(value)
                    }
                    newO[newKey] = value
                }
            }
        }
        return newO
    }


    public static parseAddressObject(address_components): Address {
        var ShouldBeComponent = {
            streetNo: ["street_number"],
            postal_code: ["postal_code"],
            street: ["street_address", "route"],
            region: [
                "administrative_area_level_1",
                "administrative_area_level_2",
                "administrative_area_level_3",
                "administrative_area_level_4",
                "administrative_area_level_5"
            ],
            city: [
                "locality",
                "sublocality",
                "sublocality_level_1",
                "sublocality_level_2",
                "sublocality_level_3",
                "sublocality_level_4"
            ],
            country: ["country"]
        };

        var address: Address = {
            streetNo: "",
            postal_code: "",
            street: "",
            region: "",
            city: "",
            country: ""
        };
        address_components.forEach(component => {
            for (var shouldBe in ShouldBeComponent) {
                if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                    if (shouldBe === "country") {
                        address[shouldBe] = component.short_name;
                    } else {
                        address[shouldBe] = component.long_name;
                    }
                }
            }
        });
        return address;
    }



}
