import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, Router, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { TreoModule } from '@treo';
import { TreoConfigModule } from '@treo/services/config';
import { TreoMockApiModule } from '@treo/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { AgmCoreModule } from '@agm/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { appInitializer } from './appInit';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy',
    useHash: true
};

const googleMapsParams = {
    apiKey: 'AIzaSyAemkfIROX2u3zD-pNLQepiexq_s90LDEY',
    libraries: ['places'],
    language: 'en'
};

@NgModule({
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'en-ZA'
            // useFactory: loadLanguage,
            // deps: [HBUnitOfWork],
            // multi: true  //returns locale string
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6Lc1yI4gAAAAAC6KacV24i81TGl7SdUj54aJSowP" },
        { provide: DEFAULT_CURRENCY_CODE, useValue: "R" },
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [Router] },
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        RecaptchaV3Module,
        // Treo & Treo Mock API
        TreoModule,
        TreoConfigModule.forRoot(appConfig),
        TreoMockApiModule.forRoot(mockApiServices),
        AgmCoreModule.forRoot(googleMapsParams),
        // Core
        CoreModule.forRoot(),

        // Layout
        LayoutModule,

        // 3rd party modules
        MarkdownModule.forRoot({}),
        ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
