import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export enum ConfirmationType {
  Information = 1,
  YesNoCancel = 2
}

export abstract class IConfirmationData {
  type: ConfirmationType;
  title: string;
  description: string;
  icon: string;
  yesNo: boolean;
  onlyInfo: boolean;
}


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IConfirmationData) { }


  ok(): void {
    this.dialogRef.close(this.data);

  }

  yes(): void {
    this.data.yesNo = true;
    this.dialogRef.close(this.data);
  }

  no(): void {
    this.data.yesNo = false;
    this.dialogRef.close(this.data);
  }
  ngOnInit(): void {
    //console.log(this.data);
  }

}
