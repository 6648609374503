import { EntityBase } from './EntityBase';
import { Appointment } from './Appointment';
import { Invoice } from './Invoice';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class AppointmentInvoice extends EntityBase {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    invoiceAppointmentId: string;
    appointmentDate: Date;
    appointmentId: string;
    invoiceId: string;
    appointment: Appointment[];
    appointmentNavigation: Appointment;
    invoice: Invoice;
}

