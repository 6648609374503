import { BaseComponent } from './../../../shared/base-component';
import { GlobalCalendarEventsService } from './../../../core/events/calendar.events';
import { AppSettings } from './../../../core/services/app-settings.service';
/*
Version 6.0
Calendar View Selector - Agenda / Day / Week etc.
*/
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {BreakpointObserver, Breakpoints,BreakpointState} from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-calendar-view-selector',
  templateUrl: './calendar-view-selector.component.html',
  styleUrls: ['./calendar-view-selector.component.scss']
})
export class CalendarViewSelectorComponent extends BaseComponent implements OnInit, OnDestroy {



  viewChangedSubscription: Subscription;
  viewControl = new UntypedFormControl();

  constructor(
    public appSettings: AppSettings, 
    public gces: GlobalCalendarEventsService
    ) { 
      super();
      this.viewControl.setValue(this.appSettings.calDefaultView);
    }

  ngOnInit(): void {
    this.viewChangedSubscription = this.gces.changeView$.subscribe((view: string) => {
      this.viewControl.setValue(view, {emitEvent: false} );
    });

    this.viewControl.valueChanges.subscribe(view => {
      this.appSettings.calDefaultView = view;
      this.gces.changeView(view);
      this.appSettings.savePreferences();

      });

  }
  ngOnDestroy(): void {
    if (this.viewChangedSubscription) {
      this.viewChangedSubscription.unsubscribe();
    }
  }

}
