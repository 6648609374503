import { environment } from './../../../environments/environment';
import { HBUnitOfWork } from './hb-unit-of-work';
import { Injectable, Input } from '@angular/core';
import { EntityQuery, QueryResult, MergeStrategy, Predicate, Entity, FilterQueryOp, FetchStrategy, core } from 'breeze-client';
import { BreezeFilters } from '../models/entity-objects';

import {Appointment, Client, IBreezeClient, UserAccount} from '../models/entities/EntityModel';

import { BreezeService } from './breeze.service';
import { SupportServices } from '../services/support.service';
import { AppSettings } from './app-settings.service';
import { Observable, from } from 'rxjs';
import { DateTime} from 'luxon';
import { HttpClient } from '@angular/common/http';
import {ClientSearchResult} from '../models/medapp-objects';


@Injectable()
export class ClientService {

    private _recentCacheDate: DateTime;

    CLIENT_CACHE = 'medapp_client_cache';


    constructor(private breezeService: BreezeService, private http: HttpClient, private appSettings: AppSettings , private uow:HBUnitOfWork) {

        let value = localStorage.getItem(this.CLIENT_CACHE);
        if (value) {
            this._recentCacheDate = DateTime.fromISO(value);
        } else {
            this._recentCacheDate = DateTime.local().minus({minutes: 20});
            localStorage.setItem(this.CLIENT_CACHE, this._recentCacheDate.toISO());
        }
    }


    $PatientsRemoteByString: Observable<IBreezeClient[]>;


    //NEW METHODS

    public search(businessId: string, search: string): Observable<Client[]> {

        return from(this.uow.clients.whereEndpointParams('ClientsSearch', { query: search, businessId: businessId },undefined,undefined, true));

        // const query = EntityQuery.from('ClientsSearch')
        //     .withParameters({ query: search, businessId: businessId })
        //     .inlineCount();

        // const _promise = new Promise<Client[]>((resolve, reject) => {
        //     this.breezeService.service.executeQuery(query).then((data) => {
        //         resolve(data.results as Client[]);
        //     });

        // });
        // return from(_promise);
    }

    // Search across all businesses where results match.
    public searchAcross(search: string): Observable<Client[]> {

        return from(this.uow.clients.whereEndpointParams('ClientsAllBusinessSearch', { query: search}, undefined,undefined, true));

    }

    //OLD METHODS




    public getPatients(): any {

        // if (filters) {
        //    var start: number;
        //    start = (filters.page - 1) * filters.limit;
        //    var query = breeze.EntityQuery.from('Patients').orderBy(filters.order).skip(start).take(5);
        //    return this.breezeService.service.executeQueryLocally(query);
        // }
        // else {


        let p2 = Predicate.create('deleted', FilterQueryOp.NotEquals, true);
        let query = EntityQuery.from('Client').where(p2).orderBy('date desc');
        return this.breezeService.service.executeQueryLocally(query);
        // }

    }
    // gets a list of recent patients.
    public getRecentPatients(search: string, doctorId: string) {
        let query = EntityQuery.from('ClientsRecent')
            .withParameters({ query: search, doctorId: doctorId })
            .inlineCount();
        return this.breezeService.service.executeQuery(query);
    }


    // public getSharingUsers(businessId: string, search: string): Observable<UserAccount[]> {

    //     let query = EntityQuery.from('FrontdeskUserSearch').toType('UserAccount').withParameters({ query: search});


    //     const _promise = new Promise((resolve, reject) => {
    //         this.breezeService.service.executeQuery(query).then((data) => {
    //             resolve(data.results);
    //         });

    //     });

    //     return from(_promise);

    // }


    // clever method that looks locally and then at the server to find a client.
    public getClient(id: string): Observable<IBreezeClient | any> {
        let promise = new Promise((resolve, reject) => {
            let p1 = Predicate.create('clientId', FilterQueryOp.Equals, id);
            let p2 = Predicate.create('deleted', FilterQueryOp.NotEquals, true);
            // var p3 = Predicate.create('doctorID', FilterQueryOp.Equals, doctorId)
            let pred = Predicate.and([p1, p2]); // p3]);

            let query = EntityQuery.from('Client').where(pred); // .expand("appointments");

            // check locally
            let results = this.breezeService.service.executeQueryLocally(query);

            if (results != null && results != undefined && results.length > 0) {
                resolve(results[0]);
            } else {
                this.breezeService.service.executeQuery(query).then((data) => {
                    resolve(<IBreezeClient>data.results[0]);
                });
            }

        });

        return from(promise);

    }

    public checkDuplidates(doctorId: string, telephone: string, idno: string, patientId: string, email: string): Promise<IBreezeClient[] | any> {

        return new Promise((resolve, reject) => {
            let query = EntityQuery.from('ClientsDuplicateCheck').toType('Client')
                .withParameters({ doctorId: doctorId, telephone: telephone, idno: idno, patientId: patientId, email: email });
            this.breezeService.service.executeQuery(query).then((data) => {
                resolve(data.results);
            });

        });

    }

    public getClientAppointments(id: string, businessId: string): Observable<Appointment[] | any> {
        let promise = new Promise((resolve, reject) => {
            let p1 = Predicate.create('clientId', FilterQueryOp.Equals, id);
            let p2 = Predicate.create('businessId', FilterQueryOp.Equals, businessId);
            let pred = Predicate.and([p1, p2]);

            let query = EntityQuery.from('Appointment').where(pred).orderBy('startDate desc');



            // check locally
            let results = this.breezeService.service.executeQueryLocally(query);

            if (results != null && results != undefined && results.length > 0) {
                resolve(results);
            } else {
                this.breezeService.service.executeQuery(query).then((data) => {
                    resolve(<Appointment[]>data.results);
                });
            }

        });

        return from(promise);

    }




    // public getClientAppointmentHistory(id): Observable<Appointment[] | any> {
    //     let promise = new Promise((resolve, reject) => {
    //         let p1 = Predicate.create('clientId', FilterQueryOp.Equals, id);
    //         let p2 = Predicate.create('startDate', FilterQueryOp.LessThan, SupportServices.dateToUTC(new Date()).toDate());

    //         let p4 = Predicate.create('startDate', FilterQueryOp.GreaterThanOrEqual, SupportServices.dateToUTC(new Date()).toDate());
    //         let p5 = Predicate.create('label', FilterQueryOp.LessThan, 0);

    //         let pred = Predicate.and([p1, p2]);
    //         let pred2 = Predicate.and([p1, p4, p5]);

    //         let predOr = Predicate.or(pred, pred2);

    //         let query = EntityQuery.from('Appointment').where([predOr]).orderBy('startDate desc');



    //         // check locally
    //         let results = this.breezeService.service.executeQueryLocally(query);

    //         if (results != null && results != undefined && results.length > 0) {
    //             resolve(results);
    //         } else {
    //             this.breezeService.service.executeQuery(query).then((data) => {
    //                 resolve(<Appointment[]>data.results);
    //             });
    //         }

    //     });

    //     return from(promise);

    // }

    // public getClientAppointmentFuture(id): Observable<Appointment[] | any> {
    //     let promise = new Promise((resolve, reject) => {
    //         let p1 = Predicate.create('clientId', FilterQueryOp.Equals, id);

    //         let p2 = Predicate.create('startDate', FilterQueryOp.GreaterThanOrEqual, SupportServices.dateToUTC(new Date()).toDate());
    //         let p3 = Predicate.create('label', FilterQueryOp.GreaterThan, 0);
    //         let pred = Predicate.and([p1, p2, p3]);
    //         let query = EntityQuery.from('Appointment').where([pred]).orderBy('startDate asc');



    //         // check locally
    //         let results = this.breezeService.service.executeQueryLocally(query);

    //         if (results != null && results != undefined && results.length > 0) {
    //             resolve(results);
    //         } else {
    //             this.breezeService.service.executeQuery(query).then((data) => {
    //                 resolve(<Appointment[]>data.results);
    //             });
    //         }

    //     });

    //     return from(promise);

    // }


    public deleteClient(id): Promise<QueryResult | any> {
        return new Promise((resolve, reject) => {
            let client = <IBreezeClient>this.breezeService.service.getEntityByKey('Client', id);
            client.geo = '';
            client.deleted = true;
            this.breezeService.saveChanges().then((result) => {
                resolve(result);
            });

        });

    }

    public getRecentPatientsWithCache(search: string, businessId: string, force: boolean = false): Promise<IBreezeClient[] | any> {
        // var cacheStatus = false;
        // var query;

        let needsRefresh = false;

        if (force) {
            this._recentCacheDate = DateTime.local().minus({minutes: 20 });
        }

        const _last = this._recentCacheDate;


        // last refresh date + 5 minutes
        const last = _last.plus({minutes: 5 });
        // current time
        const now = DateTime.local();
        if (now > last) {
            needsRefresh = true;

        }

        return new Promise((resolve, reject) => {
            let query;
            if (!needsRefresh) {
                let query = EntityQuery.from('Client').take(50).orderBy('date', true);
                query = query.using(FetchStrategy.FromLocalCache);

            } else {
                let query = EntityQuery.from('ClientsRecent').toType('Client')
                    .withParameters({ query: search, businessId: businessId })
                    .inlineCount();

                query = query.using(FetchStrategy.FromServer);

            }
            this.breezeService.service.executeQuery(query).then((data) => {
                // this._recentCacheDate = moment();
                this._recentCacheDate = DateTime.local();
                localStorage.setItem(this.CLIENT_CACHE, DateTime.local().toISO());
                resolve(data.results);

            }).catch((err) => {

                /// <reference path="" />
                reject('Offline');

            });

        });
    }




    public getClientsRemote(filters?: BreezeFilters): any {
        let query;
        let start: number = (filters.page) * filters.limit;

        if (filters.search != '') {
            query = EntityQuery.from('ClientsSearch').toType('Client')
                .withParameters({ query: filters.search, doctorId: filters.doctor })
                .inlineCount();
        } else {
            query = EntityQuery.from('Client')
                .orderBy(filters.order)
                .skip(start)
                .take(filters.limit)
                .inlineCount();
        }
        return this.breezeService.service.executeQuery(query);
    }

    public addClient(client: Client, id: string): Promise<QueryResult> {

        return new Promise((resolve, reject) => {
            if (!id) {
                throw new Error('Could not create client. Business ID is empty.');
            }

            client.clientId = core.getUuid();
            client.globalUserId = SupportServices.EmptyGuid;
            client.businessId = id;

            let _client = <IBreezeClient>this.breezeService.service.createEntity('Client', client);

            if (_client.entityAspect.hasValidationErrors) {
                let errors = _client.entityAspect.getValidationErrors();
                reject(errors[0].errorMessage);
                _client.entityAspect.setDetached();
            } else {
                this.breezeService.saveChanges().then(
                    () => {
                        this._recentCacheDate = DateTime.local().minus({minutes: 20});
                        resolve(null);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    }


    public changePassword(uid: string, password: string): Observable<any> {

        return this.http.get(environment.api_path + '/api/Users/ChangePassword?password=' + password + '&id=' + uid);
    }
}
