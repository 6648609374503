import { GlobalCalendarEventsService } from './../../../core/events/calendar.events';
import { BaseComponent } from '../../../shared/base-component';
import { Component, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppSettings } from '../../../core/services/app-settings.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { skip, takeUntil } from 'rxjs/operators';
import { CalendarViewNames, AGENDA_VIEW_NAMES } from '../../business/calendar/scheduler/scheduler.types';
import { UntypedFormControl } from '@angular/forms';
@Component({
  selector: 'app-calendar-navigator',
  templateUrl: './calendar-navigator.component.html',
  styleUrls: ['./calendar-navigator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarNavigatorComponent extends BaseComponent implements OnDestroy {

  viewText = '';
  hasScheduler = false;
  date = new UntypedFormControl(new Date());

  constructor(
    public dialog: MatDialog,
    public appSettings: AppSettings,
    public gces: GlobalCalendarEventsService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.gces.calendarLoadedSubject$.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {

      this.hasScheduler = data;
      this._changeDetectorRef.markForCheck();

      this.gces.changeAfterDateData$.pipe(takeUntil(this.unsubscribeAll)).subscribe((_data) => {
        if (data) {
          this.updateText(_data);
          this._changeDetectorRef.markForCheck();
        }
      });

      this.gces.changeDate$.pipe(takeUntil(this.unsubscribeAll)).subscribe((date) => {
        this.date.setValue(date, { emitEvent: false });
      });

      this.date.valueChanges.subscribe((date) => {
        this.gces.changeDate(date.toISO());
        console.log('value changed date');
      })

    });
  }

  next(): void {
    this.gces.nextDate();
  }
  prev(): void {
    this.gces.previousDate();
  }

  todayClick(): void {
    this.gces.changeDate(new Date());
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  updateText(data: any): void {

    let dataType: CalendarViewNames;
    if (data && data.type) {
      dataType = data.type;
    }
    else {
      dataType = 'timeGridWeekHB';
    }

    const start = DateTime.fromJSDate(data.currentStart);
    const end = DateTime.fromJSDate(data.currentEnd);


    console.log(dataType);

    //check for possible list of agenda views - checking for invalid string.
    if (AGENDA_VIEW_NAMES.indexOf(dataType) > -1) {
      if (this.isScreenSmall || data.type.toLowerCase().indexOf('day') > -1) {
        this.viewText = start.toFormat('d MMM yyyy');
      }
      else if (data.type.toLowerCase().indexOf('week') > -1) {
        const isSameMonth = start.hasSame(end, 'month');
        this.viewText = start.toFormat('d MMM') + ' - ' + end.toFormat('d MMM yyyy');
      }
      else if (data.type.toLowerCase().indexOf('month') > -1) {
        const isSameYear = start.hasSame(end, 'year');
        if (isSameYear) {
          this.viewText = start.toFormat('MMM yyyy');
        }
        else {
          this.viewText = start.toFormat('MMM yyyy') + ' – ' + end.toFormat('yyyy');
        }
      }
    } else {
      console.error('There is an invalid agenda string name.')
      this.viewText = data.title;
    }

  }


}
