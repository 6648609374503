import { Subscription } from 'rxjs';
import { Component, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {GlobalEvents} from '../../../core/events/events';

@Component({
    selector: 'loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class LoadingIndicatorComponent implements OnDestroy {

    @Input() show: boolean;

    loadsubscription: Subscription;

    constructor(
        private events: GlobalEvents,
        private _changeDetectorRef: ChangeDetectorRef
    ) {

        this.loadsubscription = events.loadingIndicator$.subscribe((value) => {
            this.show = value;
            _changeDetectorRef.markForCheck();
        });

    }

    ngOnDestroy(): void
    {
        this.loadsubscription.unsubscribe();
    }

}
