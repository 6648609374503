import { Business } from './Business';
import { EntityBase } from './EntityBase';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Setting extends EntityBase {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    businessId: string;
    bookingApprovalRequired: boolean;
    bookingCancelTimeHours: number;
    bookingMaxFutureDate: number;
    bookingNotifyEmail: string;
    bookingNotifyTelephone: string;
    bookingShowPrice: boolean;
    calendarEndTime: string;
    calendarPermDeleteRecords: boolean;
    calendarShowDeletedRecords: boolean;
    calendarShowSaturdays: boolean;
    calendarSlotSize: number;
    calendarStartTime: string;
    emailCancel: string;
    emailCancelOn: boolean;
    emailNew: string;
    emailNewOn: boolean;
    emailReminder: string;
    emailReminderOn: boolean;
    emailUpdate: string;
    emailUpdateOn: boolean;
    smsnotificationText: string;
    smsnotifications: boolean;
    smsonCancel: boolean;
    smsonCancelText: string;
    smsonMove: boolean;
    smsonMoveText: string;
    smsonNew: boolean;
    smsonNewText: string;
    sendSmsApprovalLink: boolean;
    vatRegistrationNo: string;
    business: Business;

}

