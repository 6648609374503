import { AppointmentCacheItem } from './appointment-cach-item';
import { Injectable } from '@angular/core';
import {DateTime} from 'luxon';
import {find} from 'lodash-es';
import { AppSettings } from '../app-settings.service';

@Injectable()
export class AppointmentCacheList {

   
    _refreshTime = 30;

    APPOINTMENT_CACHE = 'medapp_appointment_cache';

    constructor(private _appSettings: AppSettings) {


        const value = localStorage.getItem(this.APPOINTMENT_CACHE);
        if (value) {
            // console.log("Appointment Object Loaded");
            const cache_data = JSON.parse(value);
            this._appSettings._cachedAppointmentList = cache_data as AppointmentCacheItem[];
            // console.log(this._list);
        } else {
            // console.log("Creating new Appointment Object");
            this._appSettings._cachedAppointmentList = new Array<AppointmentCacheItem>();

        }

    }

    save(): void {
        localStorage.setItem(this.APPOINTMENT_CACHE, JSON.stringify(this._appSettings._cachedAppointmentList));
    }

    clear(): void {
        localStorage.removeItem(this.APPOINTMENT_CACHE);
        this._appSettings._cachedAppointmentList = new Array<AppointmentCacheItem>();
    }



    // private addToCache(start: DateTime, end: DateTime) {
    //     const startWeek = start.startOf('week');
    //     const endWeek = end.clone().startOf('week');
    //     do {
    //         console.log(startWeek.week().toString() + startWeek.year().toString());
    //         startWeek.add('week', 1);
    //     }
    //     while (startWeek < endWeek);
    // }

    // jsut checks cache does not modify
    checkCache(date: Date, staffId): boolean {
        // console.log(this._list);
        const _date = DateTime.fromJSDate(date);

        const _key = _date.month.toString() + _date.year.toString();

        //TODO REMOVE ANY and FIX ISSUE.
        const current = find(this._appSettings._cachedAppointmentList, ( o: any) => {

            if (_key === o.key && o.staffId === staffId) {
                return o;
            }
        });

        if (!current) {
            // console.log('Adding item to cache list');
            // this.add(_startOfweek.toDate(), doctorId);
            this.add(new Date(), staffId, _key, _date.toJSDate());
            return false;
        } else {
            // last refresh date + 5 minutes
            const last = DateTime.fromJSDate(current.lastRefresh).plus({minutes: this._refreshTime});
            // current time
            const now = DateTime.fromJSDate(new Date());
            if (now > last) {
                return false;
            }
            return true;
        }



    }

    // checks cache and adds record
    cacheIsFresh(date: Date, staffId): boolean {
        // console.log(this._list);
        const _date = DateTime.fromJSDate(date);
        // var _startOfweek = _date.startOf('day');

        // create cache key
        const _key = _date.month.toString() + _date.year.toString();

        //TODO REMOVE ANY and FIX ISSUE.
        const current = find(this._appSettings._cachedAppointmentList, (o: any ) => {
            if (_key === o.key && o.staffId === staffId) {
                return o;
            }
        });

        if (!current) {
            this.add(new Date(), staffId, _key, _date.toJSDate());

            return false;
        } else {
           
            const last = DateTime.fromJSDate(current.lastRefresh).plus({minutes: this._refreshTime});
            // current time
            const now = DateTime.fromJSDate(new Date());
            if (now > last) {
                current.lastRefresh = new Date();
                return false;
            }
            // console.log('cache is cool :-)');
            // console.log('cache is fresh');
            return true;
        }

    }

    private add(date: Date, staffId: string, key: string, period: Date):void {
        const item = new AppointmentCacheItem();
        item.date = date;
        item.key = key;
        item.staffId = staffId;
        item.lastRefresh = new Date();
        item.period = period;
        this._appSettings._cachedAppointmentList.push(item);
    }

}


