
import { AuthService } from 'app/core/auth/auth.service';
import { HBUnitOfWork } from './hb-unit-of-work';
import { Injectable } from '@angular/core';
import { UserType } from '../models/auth/user.model';
import { AppSettings } from './app-settings.service';
import { GlobalEvents } from '../events/events';
// import { HttpClient } from '@angular/common/http';
// import { Router } from '@angular/router';
// import { SignalRInboundService } from './signalR/signalR-inbound.service';
import { SignalRServiceBase } from './signalR/signalR-base.service';
// import { IconService } from './icon.service';
import { FilterQueryOp, Predicate } from 'breeze-client';
// import { getMatInputUnsupportedTypeError } from '@angular/material/input';
import { DateTime } from 'luxon';
import { UserService } from '../user/user.service';


@Injectable()
export class AppControlService {



  lastRefresh: DateTime; // this is the last time a fresh happened???
  majorTick: 30;


  constructor(
    // private breezeService: BreezeService,
    private appSettings: AppSettings, // basic app settings not preferences - need to clean this up.
    private signalRBase: SignalRServiceBase,
    private authService: AuthService,
    private userService: UserService,
    private ge: GlobalEvents,
    private uow: HBUnitOfWork
  ) {

    // this.ge.serverAnnounceLogout$.subscribe(() => {
    //   this.signOut();
    // });

    this.ge.businessAnnounced$.subscribe((id) => {
      let bus = this.uow.business.withIdInCache(id);
      this.appSettings.currentBIDcurrency = bus.currency;
    });

  }

  async init(): Promise<boolean> {

    try {

      console.log('1.Running init');
      this.appSettings.preInit = false;

      if (this.appSettings.init) {
        console.log('0.Has init already.');
        return true;
      }

      this.ge.event_loadingIndicator(true);
      console.log('1.1 Checking if logged in.');


      this.appSettings.loadPreferences(this.appSettings.user.uid);

      try {
        await this.uow.prepare();
      }
      catch (err) {
        throw new Error('Could not start calendar5 platform. Check your connection to the internet and try again.');

      }
      finally {
        this.ge.event_loadingIndicator(false);
      }

      if (this.appSettings.user.type !== UserType.USER) {


        this.userService.get();

        const business = await this.uow.business.all();

        this.appSettings.currentBID = business[0].businessId;
        this.appSettings.timeZone = business[0].timeZone;


        const pred = new Predicate('userId', FilterQueryOp.Equals, this.appSettings.user.uid);
        const userAccessList = await this.uow.userAccess.where(pred);
        const staff = await this.uow.staff.all();
        this.appSettings.currentSID = staff[0].staffId;

        if (userAccessList.length > 1 || staff.length > 1) {
          this.appSettings.multiuser = true;
        }

        console.log('2.Starting up medapp.');

        // sets the current selected user to the first set defaults returned.
        this.ge.event_setBusiness(userAccessList[0].businessId);
        this.ge.event_setStaff(staff[0].staffId);

        try {
          this.signalRBase.disconnect();
          this.signalRBase.start();
        } catch (err) {
          console.log(err);
        }

        this.ge.init();
        this.appSettings.preInit = true;
        this.appSettings.init = true;
        this.ge.event_loadingIndicator(false);
        return true;
      }
      else {
        throw new Error('Not authorized');
      }





    } catch (err) {
      console.log('Throwing error');
      throw new Error('Could not login.' + err);
      // this.router.navigate(['login']);

    } finally {

    }
  }

  //TODO need to fix the way auth works have 3 services that manage auth
  public signOut(): void {

    this.authService.signOut().subscribe(() => {
      this.signalRBase.disconnect();
      this.ge.logout();
      this.uow.stopListenersAndClear();
    });
  }

}
