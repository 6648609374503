

import { Client } from './entities/EntityModel';


export enum UserAuthorization {
    DOCTOR = 'DOCTOR',
    DOCADMIN = 'DOCADMIN',
    ADMIN = 'ADMIN',
    USER = 'USER',
}


export class ValidationError extends Error {
    errors = [];

    constructor(message, err) {
        super(message);
        this.name = 'ValidationError';
        this.errors = err;
    }
}


export interface IFullCalendarAppointment {
    id?: string;
    title?: string;
    start: Date;
    end: Date;
    allDay?: boolean;
    textColor?: string;
    backgroundColor?: string;
    borderColor?: string;
    description?: string;
    // resourceId?: string,
    rendering?: string;
    resourceId?: string;
    resourceIds?: string[];
    editable?: boolean;
    extendedProps?: IFullCalendarExtendedProps;
    rrule?: string | object;
    duration?: string;
}

export interface IFullCalendarSchedule {
    id?: string;
    title?: string;
    start: Date;
    end: Date;
    allDay?: boolean;
    textColor?: string;
    backgroundColor?: string;
    borderColor?: string;
    background?: string;
    editable?: boolean;
    rrule?: string | object;
    duration?: string;
    staffId?: string; // not a valid property but using as shortcut to extended properties.
    locationId?: string;
    extendedProps?: IFullCalendarScheduleExtendedProps;
}

export interface IFullCalendarScheduleExtendedProps {
    businessId?: string;
    staffId?: string;
    oColorId?: string;
    locationId?: string;
}


export interface IFullCalendarExtendedProps {
    appointmentId?: string;
    status?: string | number;
    label?: string | number;
    clientId?: string;
    color?: string;
    businessId?: string;
    staffId?: string;
    firstname?: string;
    lastname?: string;
    telephone?: string;
    email?: string;
    oColorId?: string;
    locationId?: string;
    readonly: boolean;

}

export type CalendarMode = 'agendaThreeDay' | 'agendaDay' | 'listDay' | 'agendaWeek' | 'listWeek' | 'listMonth' | 'month';

// export enum ConfirmationType {
//     Information = 1,
//     YesNoCancel = 2
//   }

// export abstract class IConfirmationData {
//   type: ConfirmationType;
//   title: string;
//   description: string;
//   icon: string;
//   yesNo: boolean;
//   onlyInfo: boolean;
// }


// export interface IBreezeManagerSaveQue extends EntityManager {
//     enableSaveQueuing(value: boolean);
// }

export interface ILogging {
    logID: string;
    logType: string;
    logMessage: string;
    logUserID: string;
    date: Date;
    doctorID: string;
    otherInfoDecimal: number;
    otherInfoText: string;
    patientFirst: string;
    patientLast: string;
    patientID: string;
    appointmentID: string;
    creationDate: Date;
    lat: number;
    lng: number;
    medappPatientID: string;
    telephone: string;
    email: string;
    batchID: string;
    doctorIDShort: string;
}

export interface INotification {
    notification_ID: string;
    process: string;
    type: string;
    doctor_ID: string;
    appointment_ID: string;
    patient_ID: string;
    otherInfo: string;
    date: Date;
    telephone: string;
    email: string;
    docFirst: string;
    docLast: string;
    dateTimeStart: Date;
    dateTimeEnd: Date;
    isCustom: boolean;
    customMessage: string;
    patientFirst: string;
    patientLast: string;
    isDocOrigin: boolean;
    lat: number;
    lng: number;
    direction: string;
    worklocationID: string;

}

export enum NotificationProcess { Email = 0, Sms = 1 }
export enum NotificationAction { New = 0, Update = 1, Delete = 2, PinReset = 3, PreliminaryBooking = 4, Custom = 5, Directions = 6, Notes = 7 }
export class BreezeFilters {
    order = '';
    limit = 5;
    page = 1;
    search = '';
    doctor = '';
}

export class LastDeleted {
    id: string;
    label: number;
    uid: string;
}

export class Speciality {
    speciality_ID: string;
    specialityText: string;
    alternativeText: string;
}


export interface ISignalRAppointment {
    appointmentId: string;
    allDay: boolean;
    businessId: string;
    clientId: string;
    colorId: string;
    creationDate: Date;
    customField: string;
    description: string;
    email: string;
    endDate: Date;
    firstname: string;
    isConfirmed: boolean;
    isRecurring: boolean;
    recurrenceInfo: string;
    reminderInfo: string;
    label: number;
    lastUpdated: Date;
    lastname: string;
    locationId: string;
    smsBatchId: string;
    smsStatus: number;
    staffId: string;
    startDate: Date;
    status: number;
    subject: string;
    telephone: string;
    client: Client;
}

