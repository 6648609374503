import { Appointment } from './Appointment';
import { EntityBase } from './EntityBase';
import { Tag } from './Tag';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class AppointmentTag extends EntityBase{

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    appointmentTagId: string;
    appointmentDate: Date;
    appointmentId: string;
    tagId: string;
    appointment: Appointment;
    tag: Tag;
}

