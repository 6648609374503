import { Appointment } from './Appointment';
import { Business } from './Business';
import { Schedule } from './Schedule';
import { StaffService } from './StaffService';
import { EntityBase } from './EntityBase';
import { Zoom } from './Zoom';
/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Staff extends EntityBase {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    staffId: string;
    aboutMe: string;
    businessId: string;
    deleted: boolean;
    email: string;
    enabled: boolean;
    firstname: string;
    gender: number;
    googleCalendarName: string;
    googleCalendarOn: boolean;
    googleCalendarTwoWay: boolean;
    googleRefreshToken: string;
    googleToken: string;
    googleTokenExpiry: string;
    image: string;
    lastname: string;
    onlineBookings: boolean;
    showTitle: boolean;
    speciality: string;
    telephone: string;
    telephoneCountry: string;
    title: string;
    zoomExpiry: Date;
    zoomId: string;
    zoomRefreshToken: string;
    zoomToken: string;
    appointment: Appointment[];
    business: Business;
    schedule: Schedule[];
    staffService: StaffService[];
    zoom: Zoom[];
}

