<!-- Navigation -->
<ng-container *ngIf="isScreenSmall && !(signedIn$ | async)">
    <treo-vertical-navigation class="dark bg-gray-900" [mode]="'over'" [name]="'mainNavigation'" [navigation]="navigation" [opened]="false">
        <!-- Navigation header hook -->
        <ng-container treoVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 justify-center pb-5 px-3">
                <img class="w-13" src="assets/undraw/char-dark.svg" alt="Logo image">
            </div>
        </ng-container>
    </treo-vertical-navigation>
</ng-container>

<ng-container *ngIf="isScreenSmall && (signedIn$ | async)">
    <treo-vertical-navigation class="dark bg-gray-900" [mode]="'over'" [name]="'mainNavigation'" [navigation]="signedInNavigation" [opened]="false">
        <!-- Navigation header hook -->
        <ng-container treoVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 justify-center pb-5 px-3">
                <img class="w-13" src="assets/undraw/char-dark.svg" alt="Logo image">
            </div>
        </ng-container>
    </treo-vertical-navigation>
</ng-container>


<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent">
        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <a [routerLink]="['/home']">
                <div class="flex items-center mx-2">
                    <div class="hidden lg:flex">
                        <img class="dark:hidden w-10" src="assets/undraw/char.svg">
                        <img class="hidden dark:flex w-10" src="assets/undraw/char.svg">
                    </div>
                    <img class="flex lg:hidden w-10" src="assets/undraw/char.svg">
                </div>
            </a>
            <!-- Horizontal navigation -->
            <treo-horizontal-navigation *ngIf="!(signedIn$ | async)" class="mr-2" [name]="'mainNavigation'" [navigation]="navigation"></treo-horizontal-navigation>
            <treo-horizontal-navigation *ngIf="(signedIn$ | async)" class="mr-2" [name]="'mainNavigation'" [navigation]="signedInNavigation"></treo-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
            <a [routerLink]="['/home']">
                <div class="flex items-center mx-2">
                    <div class="hidden lg:flex">
                        <img class="dark:hidden w-10" src="assets/undraw/char.svg">
                        <img class="hidden dark:flex w-10" src="assets/undraw/char-dark.svg">
                    </div>
                    <img class="flex lg:hidden w-10" src="assets/undraw/char.svg">
                    <span class="text-2xl font-semibold tracking-tighter pl-1 pt-1 text-default">
                        CalendarFive
                    </span>
                </div>
            </a>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-2">
            <!-- <search [appearance]="'bar'"></search>
            <shortcuts [shortcuts]="data.shortcuts"></shortcuts>
            <messages [messages]="data.messages"></messages>
            <notifications [notifications]="data.notifications"></notifications> -->
            <user-menu></user-menu>

            <!-- <a>Business sign-ups</a> -->
            <!-- <button *ngIf="(signedIn$ | async) && !isScreenSmall" [routerLink]="['/private/account']" class=" hover:bg-primary-100 bg-primary-200 text-blue-700" mat-flat-button>My Account</button> -->
            <button *ngIf="(!(signedIn$ | async) && !isScreenSmall)" [routerLink]="['/sign-in']" class=" hover:bg-primary-100 bg-primary-200 text-blue-700" mat-flat-button>Sign in</button>
            <button *ngIf="!(signedIn$ | async) && !isScreenSmall && !user" [routerLink]="['/sign-up-business']" class="bg-yellow-400 hover:bg-yellow-100 text-yellow-900" mat-flat-button>Register Business</button>
            <button *ngIf="(signedIn$ | async) && !isScreenSmall && user && (user.userRole.toUpperCase() != 'USER')" [routerLink]="['/bus/calendar']" class="bg-yellow-400 hover:bg-yellow-100 text-yellow-900" mat-flat-button>My business</button>
            <!-- <a class="text-primary-700 font-medium">Register your business</a> -->

        </div>
    </div>
    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent">
        <span class="font-medium text-secondary">Calendar5 &copy; {{currentYear}}</span>
    </div> -->
    <!-- 
    <footer class="text-gray-600 body-font">
        <div class="container px-30 py-5 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
            <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
                        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
                    </svg>
                    <span class="ml-3 text-xl">Tailblocks</span>
                </a>
                <p class="mt-2 text-sm text-gray-500">Air plant banjo lyft occupy retro adaptogen indego</p>
            </div>
            <div class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                    <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
                    <nav class="list-none mb-10">
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">First Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Second Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Third Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
                        </li>
                    </nav>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                    <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
                    <nav class="list-none mb-10">
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">First Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Second Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Third Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
                        </li>
                    </nav>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                    <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
                    <nav class="list-none mb-10">
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">First Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Second Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Third Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
                        </li>
                    </nav>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                    <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
                    <nav class="list-none mb-10">
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">First Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Second Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Third Link</a>
                        </li>
                        <li>
                            <a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
                        </li>
                    </nav>
                </div>
            </div>
        </div>
        <div class="bg-gray-100">
            <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                <p class="text-gray-500 text-sm text-center sm:text-left">© 2020 CalendarFive / medapp.co.za —
                    <a href="https://twitter.com/knyttneve" rel="noopener noreferrer" class="text-gray-600 ml-1" target="_blank"></a>
                </p>
                <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                    <a class="text-gray-500">
                        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                        </svg>
                    </a>
                    <a class="ml-3 text-gray-500">
                        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                        </svg>
                    </a>
                    <a class="ml-3 text-gray-500">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                        </svg>
                    </a>
                    <a class="ml-3 text-gray-500">
                        <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
                            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                            <circle cx="4" cy="4" r="2" stroke="none"></circle>
                        </svg>
                    </a>
                </span>
            </div>
        </div>
    </footer> -->

</div>