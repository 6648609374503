export class Metadata {
    public static value = {
   "structuralTypes":[
      {
         "shortName":"Appointment",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"Appointment",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"AppointmentId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"AllDay",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"ClientId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":3,
               "nameOnServer":"ColorId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":3,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"CreationDate",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"CustomField",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"CustomStatus",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"Description",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":100,
               "nameOnServer":"Email",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":100,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":false,
               "nameOnServer":"EndDate",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":100,
               "nameOnServer":"Firstname",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":100,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"InvoiceId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"IsConfirmed",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"IsRecurring",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"Label",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":false,
               "nameOnServer":"LastUpdated",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":100,
               "nameOnServer":"Lastname",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":100,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"LocationId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"RecurrenceInfo",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":false,
               "nameOnServer":"RecurringEndDate",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"ReminderInfo",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"ServiceType",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"SmsBatchId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"SmsStatus",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"StaffId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":false,
               "nameOnServer":"StartDate",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"Status",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":300,
               "nameOnServer":"Subject",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":300,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":20,
               "nameOnServer":"Telephone",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":20,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"UserAccountId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"AppointmentIcal:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.AppointmentIcal_HB2.Data.Models.Appointment_Appointment",
               "invForeignKeyNamesOnServer":[
                  "AppointmentId"
               ],
               "nameOnServer":"AppointmentIcal",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.Business_Business",
               "foreignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Client:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.Client_Client",
               "foreignKeyNamesOnServer":[
                  "ClientId"
               ],
               "nameOnServer":"Client",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"CustomStatus:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.CustomStatus_CustomStatusNavigation",
               "foreignKeyNamesOnServer":[
                  "CustomStatus"
               ],
               "nameOnServer":"CustomStatusNavigation",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"ExternalData:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.ExternalData_HB2.Data.Models.Appointment_Appointment",
               "invForeignKeyNamesOnServer":[
                  "AppointmentId"
               ],
               "nameOnServer":"ExternalData",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Invoice:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.Invoice_Invoice",
               "foreignKeyNamesOnServer":[
                  "InvoiceId"
               ],
               "nameOnServer":"Invoice",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Location:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.Location_Location",
               "foreignKeyNamesOnServer":[
                  "LocationId"
               ],
               "nameOnServer":"Location",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Log:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Log_HB2.Data.Models.Appointment_Appointment",
               "invForeignKeyNamesOnServer":[
                  "AppointmentId"
               ],
               "nameOnServer":"Log",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Staff:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.Staff_Staff",
               "foreignKeyNamesOnServer":[
                  "StaffId"
               ],
               "nameOnServer":"Staff",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"AppointmentIcal",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"AppointmentIcal",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"AppointmentIcalId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"AppointmentId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"Count",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Appointment:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.AppointmentIcal_HB2.Data.Models.Appointment_Appointment",
               "foreignKeyNamesOnServer":[
                  "AppointmentId"
               ],
               "nameOnServer":"Appointment",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"BookingSlot",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"BookingSlot",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"BookingSlotId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"FridayIsAvailable",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"LocationId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"MondayIsAvailable",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"SaturdayIsAvailable",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"SundayIsAvailable",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"ThursdayIsAvailable",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"TimeSlotEnd",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"TimeSlotStart",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Timeslot",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"TuesdayIsAvailable",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"WednesdayIsAvailable",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"Business",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"Business",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"AccountStatusReason",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"AllowOnlineBookings",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"BillingAddress1",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"BillingAddress2",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"BillingEmail",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"BillingLocationLicenses",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"BillingModuleFrontdesk",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"BillingModuleInvoices",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"BillingModuleLoyalty",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"BillingPackage",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Decimal",
               "isNullable":false,
               "nameOnServer":"BillingPackageDiscount",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"number"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"BillingPackageType",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"BillingSmsLimit",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"BillingStaffLicenses",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":200,
               "nameOnServer":"BillingSubscriptionExternalId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":200,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":200,
               "nameOnServer":"BillingSubscriptionId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":200,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"BillingTaxNo",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"BusinessTypeId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Classification",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"Description",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"DocHpcsa",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"DocPracticeNumber",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Email",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursFriEnd",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursFriStart",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursMonEnd",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursMonStart",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursSatEnd",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursSatStart",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursSunEnd",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursSunStart",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursThuEnd",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursThuStart",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursTueEnd",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursTueStart",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursWedEnd",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"HoursWedStart",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Image",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"Locked",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Name",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"OperatesFri",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"OperatesMon",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"OperatesSat",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"OperatesSun",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"OperatesThu",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"OperatesTue",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"OperatesWed",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"OwnerEmail",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"OwnerFirstname",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"OwnerLastname",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"OwnerTelephone",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":3,
               "nameOnServer":"OwnerTelephoneCountry",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":3,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"ShowOnPortal",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"SmsLimitReached",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"SmsMode",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"SmsPassword",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"SmsUsername",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"SpecialityId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Suburb",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":3,
               "nameOnServer":"TelephoneCountry",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":3,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"TelephoneNumber",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"TimeZone",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"WebsiteUrl",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Appointment:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.Business_Business",
               "invForeignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Appointment",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"BusinessType:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Business_HB2.Data.Models.BusinessType_BusinessType",
               "foreignKeyNamesOnServer":[
                  "BusinessTypeId"
               ],
               "nameOnServer":"BusinessType",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Client:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Client_HB2.Data.Models.Business_Business",
               "invForeignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Client",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"CustomStatus:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.CustomStatus_HB2.Data.Models.Business_Business",
               "invForeignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"CustomStatus",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Image:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Image_HB2.Data.Models.Business_Business",
               "invForeignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"ImageNavigation",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Invoice:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Invoice_HB2.Data.Models.Business_Business",
               "invForeignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Invoice",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Location:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Location_HB2.Data.Models.Business_Business",
               "invForeignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Location",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Message:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Message_HB2.Data.Models.Business_Business",
               "invForeignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Message",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Service:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Service_HB2.Data.Models.Business_Business",
               "invForeignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Service",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Setting:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Setting_HB2.Data.Models.Business_Business",
               "invForeignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Setting",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Specialities:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Business_HB2.Data.Models.Specialities_Speciality",
               "foreignKeyNamesOnServer":[
                  "SpecialityId"
               ],
               "nameOnServer":"Speciality",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Staff:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Staff_HB2.Data.Models.Business_Business",
               "invForeignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Staff",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"UserAccess:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.UserAccess_HB2.Data.Models.Business_Business",
               "invForeignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"UserAccess",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"BusinessStats",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"ReportBusinessStats",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Int32",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"AppointmentTotalYear",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"AppointmentMonthCancelTotal",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"AppointmentWeekTotal",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"ClientTotal",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"MessageMonthTotal",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"UnreadTotal",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"BusinessType",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"BusinessType",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"BusinessTypeId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"BusCode",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":200,
               "nameOnServer":"Description",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":200,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Business_HB2.Data.Models.BusinessType_BusinessType",
               "invForeignKeyNamesOnServer":[
                  "BusinessTypeId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"Certifications",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"Certifications",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"CertificationsId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"DoctorId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Name",
               "validators":[
                  
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"Client",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"Client",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"ClientId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Building",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"City",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"CustomId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"Date",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Deleted",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Email",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"FbId",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Firstname",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Gender",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"NetTopologySuite.Geometries.Geometry",
               "isNullable":true,
               "nameOnServer":"Geo",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"GlobalUserId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"IdentityNumber",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Issue",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Lastname",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Lat",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Lng",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Notes",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":10,
               "nameOnServer":"PostalCode",
               "validators":[
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Street",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"StreetNo",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Suburb",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Telephone",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"TelephoneAlternate",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Appointment:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.Client_Client",
               "invForeignKeyNamesOnServer":[
                  "ClientId"
               ],
               "nameOnServer":"Appointment",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Client_HB2.Data.Models.Business_Business",
               "foreignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Invoice:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Invoice_HB2.Data.Models.Client_Client",
               "invForeignKeyNamesOnServer":[
                  "ClientId"
               ],
               "nameOnServer":"Invoice",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Message:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Message_HB2.Data.Models.Client_Client",
               "invForeignKeyNamesOnServer":[
                  "ClientId"
               ],
               "nameOnServer":"Message",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"CustomStatus",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"CustomStatus",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"CustomStatusId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Comment",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"StatusText",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Appointment:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.CustomStatus_CustomStatusNavigation",
               "invForeignKeyNamesOnServer":[
                  "CustomStatus"
               ],
               "nameOnServer":"Appointment",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.CustomStatus_HB2.Data.Models.Business_Business",
               "foreignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"DateValue",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"ReportDateValue",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"DateTime",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"Date",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"Value",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"DoctorWorkplacePrice",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"DoctorWorkplacePrice",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"DoctorWorkplacePriceId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"DoctorId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"PriceId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"WorkplaceId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"ExternalData",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"ExternalData",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"AppointmentId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"CalendarName",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"ColorId",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Id",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"OtherData2",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Status",
               "validators":[
                  
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Appointment:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.ExternalData_HB2.Data.Models.Appointment_Appointment",
               "foreignKeyNamesOnServer":[
                  "AppointmentId"
               ],
               "nameOnServer":"Appointment",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"Icd",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"Icd",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Int32",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"Number",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":100,
               "nameOnServer":"AgeRange",
               "validators":[
                  {
                     "maxLength":100,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"ChapterDesc",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"ChapterNo",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Gender",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"GroupCode",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"GroupDesc",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"Icd103Code",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"Icd103CodeDesc",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"Icd10Code",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Status",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":200,
               "nameOnServer":"ValidIcd10Asterisk",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":200,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"ValidIcd10ClinicalUse",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"ValidIcd10Dagger",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"ValidIcd10Primary",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"ValidIcd10Sequelae",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"WhoFullDesc",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"InvoiceItems:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.InvoiceItems_HB2.Data.Models.Icd_IcdNavigation",
               "invForeignKeyNamesOnServer":[
                  "Icd"
               ],
               "nameOnServer":"InvoiceItems",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"Image",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"Image",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"ImageId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"Date",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Filename",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"IsDefault",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Image_HB2.Data.Models.Business_Business",
               "foreignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"Invoice",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"Invoice",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"InvoiceId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"ClientId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"Date",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Decimal",
               "isNullable":true,
               "nameOnServer":"DiscountAmount",
               "validators":[
                  {
                     "name":"number"
                  }
               ]
            },
            {
               "dataType":"Decimal",
               "isNullable":true,
               "nameOnServer":"DiscountPercentage",
               "validators":[
                  {
                     "name":"number"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"DiscountType",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"InvoiceNo",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"Status",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Appointment:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.Invoice_Invoice",
               "invForeignKeyNamesOnServer":[
                  "InvoiceId"
               ],
               "nameOnServer":"Appointment",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Invoice_HB2.Data.Models.Business_Business",
               "foreignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Client:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Invoice_HB2.Data.Models.Client_Client",
               "foreignKeyNamesOnServer":[
                  "ClientId"
               ],
               "nameOnServer":"Client",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"InvoiceItems:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.InvoiceItems_HB2.Data.Models.Invoice_Invoice",
               "invForeignKeyNamesOnServer":[
                  "InvoiceId"
               ],
               "nameOnServer":"InvoiceItems",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"InvoiceItems",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"InvoiceItems",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"InvoiceItemId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Decimal",
               "isNullable":false,
               "nameOnServer":"Amount",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"number"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Description",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"Icd",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"InvoiceId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Item",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"MedicalIdCode",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"ProductId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"Quantity",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Decimal",
               "isNullable":false,
               "nameOnServer":"Tax",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"number"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Icd:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.InvoiceItems_HB2.Data.Models.Icd_IcdNavigation",
               "foreignKeyNamesOnServer":[
                  "Icd"
               ],
               "nameOnServer":"IcdNavigation",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Invoice:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.InvoiceItems_HB2.Data.Models.Invoice_Invoice",
               "foreignKeyNamesOnServer":[
                  "InvoiceId"
               ],
               "nameOnServer":"Invoice",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Service:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.InvoiceItems_HB2.Data.Models.Service_Product",
               "foreignKeyNamesOnServer":[
                  "ProductId"
               ],
               "nameOnServer":"Product",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"Location",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"Location",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"LocationId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":255,
               "nameOnServer":"AddressBuilding",
               "validators":[
                  {
                     "maxLength":255,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"AddressStreet",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":true,
               "nameOnServer":"CreationDate",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"Deleted",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"DirectionDescription",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"EnabledBookings",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"NetTopologySuite.Geometries.Geometry",
               "isNullable":false,
               "nameOnServer":"Geo",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"Json",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Lat",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Double",
               "isNullable":true,
               "nameOnServer":"Latitude",
               "validators":[
                  {
                     "name":"number"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Lng",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Double",
               "isNullable":true,
               "nameOnServer":"Longitude",
               "validators":[
                  {
                     "name":"number"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Name",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":10,
               "nameOnServer":"PostalCode",
               "validators":[
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"SecretaryEmail",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":255,
               "nameOnServer":"Telephone",
               "validators":[
                  {
                     "maxLength":255,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":3,
               "nameOnServer":"TelephoneCountry",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":3,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Appointment:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.Location_Location",
               "invForeignKeyNamesOnServer":[
                  "LocationId"
               ],
               "nameOnServer":"Appointment",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Location_HB2.Data.Models.Business_Business",
               "foreignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"LocationService:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.LocationService_HB2.Data.Models.Location_Location",
               "invForeignKeyNamesOnServer":[
                  "LocationId"
               ],
               "nameOnServer":"LocationService",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Schedule:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Schedule_HB2.Data.Models.Location_Location",
               "invForeignKeyNamesOnServer":[
                  "LocationId"
               ],
               "nameOnServer":"Schedule",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"LocationService",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"LocationService",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"LocationServiceId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"LocationId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"ServiceId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Location:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.LocationService_HB2.Data.Models.Location_Location",
               "foreignKeyNamesOnServer":[
                  "LocationId"
               ],
               "nameOnServer":"Location",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Service:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.LocationService_HB2.Data.Models.Service_Service",
               "foreignKeyNamesOnServer":[
                  "ServiceId"
               ],
               "nameOnServer":"Service",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"Log",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"Log",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"LogId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"AppointmentId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"BatchId",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"ClientFirst",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"ClientId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"ClientLast",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":true,
               "nameOnServer":"CreationDate",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":true,
               "nameOnServer":"Date",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"LogMessage",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"LogType",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Decimal",
               "isNullable":true,
               "nameOnServer":"OtherInfoDecimal",
               "validators":[
                  {
                     "name":"number"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"OtherInfoText",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"Smsstatus",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Telephone",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Appointment:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Log_HB2.Data.Models.Appointment_Appointment",
               "foreignKeyNamesOnServer":[
                  "AppointmentId"
               ],
               "nameOnServer":"Appointment",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"MedicalAid",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"MedicalAid",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"MedicalAidId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"MedicalAid1",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"Message",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"Message",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"MessageId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"ClientId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"ConcatNumSegments",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"ConcatSeqNum",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"ContactReference",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":true,
               "nameOnServer":"Date",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Direction",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Firstname",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"IsRead",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Lastname",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":160,
               "nameOnServer":"MessageContent",
               "validators":[
                  {
                     "maxLength":160,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"MsgId",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Msisdn",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"ReferringBatchId",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"ReferringMsgId",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Telephone",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Type",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Message_HB2.Data.Models.Business_Business",
               "foreignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Client:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Message_HB2.Data.Models.Client_Client",
               "foreignKeyNamesOnServer":[
                  "ClientId"
               ],
               "nameOnServer":"Client",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"NewUsers",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"NewUsers",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"UserId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Activated",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"ActivationCode",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"AddressBuilding",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"AddressStreet",
               "validators":[
                  
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"DateCreated",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Enabled",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"Expr1",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Fbid",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"FirstName",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Hpcsaregistration",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"IdentityNumber",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"LastLogin",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"MedicalAidId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"MedicalAidNumber",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"NotWebsiteUser",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Paid",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Password",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"PracticeNumber",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"SuburbId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Surname",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Telephone",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Title",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"UserDetailsId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"UserRole",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"UserType",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Username",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"NotificationQueue",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"NotificationQueue",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"NotificationId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"AppointmentId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"ClientFirst",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"ClientId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"ClientLast",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"CustomMessage",
               "validators":[
                  
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":true,
               "nameOnServer":"Date",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":true,
               "nameOnServer":"DateTimeEnd",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":true,
               "nameOnServer":"DateTimeStart",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Direction",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Email",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"IsBusinessOrigin",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"IsCustom",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"LocationId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"OtherInfo",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Process",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"StaffFirst",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"StaffLast",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Telephone",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Type",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"OnlineBookings",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"OnlineBookings",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"AppointmentsId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"ActivationCode",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"AddressBuilding",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"AddressStreet",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"AllDay",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"CreationDate",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Description",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Detail1",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Detail2",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Detail3",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Detail4",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"DoctorId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Email",
               "validators":[
                  
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"EndDate",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Expr2",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"ExternalColorId",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"ExternalId",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"ExternalOtherData",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"FirstName",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Hpcsaregistration",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"IdentityNumber",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"Label",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Location",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"MedicalAidId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"MedicalAidNumber",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"NotWebsiteUser",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Paid",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"PatientId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"PracticeNumber",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"RecurrenceInfo",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"ReminderInfo",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"ResourceId",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"StartDate",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"Status",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Subject",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"SuburbId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Surname",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Telephone",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Title",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"Type",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"UserDetailsId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"SMSReminders",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"ReportSMSReminders",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Int32",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"Date",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"Cancelled",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"New",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"Reminders",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"Total",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"Schedule",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"Schedule",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"ScheduleId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":6,
               "nameOnServer":"Color",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":6,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"EndDate",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"LocationId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"StaffId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"StartDate",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Subject",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Location:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Schedule_HB2.Data.Models.Location_Location",
               "foreignKeyNamesOnServer":[
                  "LocationId"
               ],
               "nameOnServer":"Location",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Staff:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Schedule_HB2.Data.Models.Staff_Staff",
               "foreignKeyNamesOnServer":[
                  "StaffId"
               ],
               "nameOnServer":"Staff",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"SearchResults",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"SearchResults",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Classification",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Description",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Email",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Name",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"SpecialityText",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"TelephoneNumber",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"TypeDescription",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"WebsiteURL",
               "validators":[
                  
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"Service",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"Service",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"ServiceId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"Deleted",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Description",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"Enabled",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"IsService",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"ListOrder",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"Name",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"Decimal",
               "isNullable":true,
               "nameOnServer":"Price",
               "validators":[
                  {
                     "name":"number"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"ServiceTimeMinutes",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"ShowOnline",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Service_HB2.Data.Models.Business_Business",
               "foreignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"InvoiceItems:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.InvoiceItems_HB2.Data.Models.Service_Product",
               "invForeignKeyNamesOnServer":[
                  "ProductId"
               ],
               "nameOnServer":"InvoiceItems",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"LocationService:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.LocationService_HB2.Data.Models.Service_Service",
               "invForeignKeyNamesOnServer":[
                  "ServiceId"
               ],
               "nameOnServer":"LocationService",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"StaffService:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.StaffService_HB2.Data.Models.Service_Service",
               "invForeignKeyNamesOnServer":[
                  "ServiceId"
               ],
               "nameOnServer":"StaffService",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"Setting",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"Setting",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"BookingApprovalRequired",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"BookingCancelTimeHours",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"BookingMaxFutureDate",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":200,
               "nameOnServer":"BookingNotifyEmail",
               "validators":[
                  {
                     "maxLength":200,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"BookingNotifyTelephone",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"BookingShowPrice",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"CalendarEndTime",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"CalendarPermDeleteRecords",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"CalendarShowDeletedRecords",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"CalendarShowSaturdays",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"CalendarSlotSize",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"CalendarStartTime",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"EmailCancel",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"EmailCancelOn",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"EmailNew",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"EmailNewOn",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"EmailReminder",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"EmailReminderOn",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"EmailUpdate",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"EmailUpdateOn",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"SendSmsApprovalLink",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":300,
               "nameOnServer":"SmsnotificationText",
               "validators":[
                  {
                     "maxLength":300,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Smsnotifications",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"SmsonCancel",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":300,
               "nameOnServer":"SmsonCancelText",
               "validators":[
                  {
                     "maxLength":300,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"SmsonMove",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":300,
               "nameOnServer":"SmsonMoveText",
               "validators":[
                  {
                     "maxLength":300,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"SmsonNew",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":300,
               "nameOnServer":"SmsonNewText",
               "validators":[
                  {
                     "maxLength":300,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"VatRegistrationNo",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Setting_HB2.Data.Models.Business_Business",
               "foreignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"SmstotalByMonth",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"SmstotalByMonth",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"DateTime",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"MonthDate",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"TotalCount",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"Specialities",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"Specialities",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"SpecialityId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"AlternativeText",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"SpecialityText",
               "validators":[
                  
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Business_HB2.Data.Models.Specialities_Speciality",
               "invForeignKeyNamesOnServer":[
                  "SpecialityId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"Staff",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"Staff",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"StaffId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "nameOnServer":"AboutMe",
               "validators":[
                  {
                     "name":"required"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"Deleted",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":100,
               "nameOnServer":"Email",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":100,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"Enabled",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Firstname",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":false,
               "nameOnServer":"Gender",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":250,
               "nameOnServer":"Image",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":250,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Lastname",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"OnlineBookings",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"ShowTitle",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Speciality",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":20,
               "nameOnServer":"Telephone",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":20,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":3,
               "nameOnServer":"TelephoneCountry",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":3,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":20,
               "nameOnServer":"Title",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":20,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Appointment:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Appointment_HB2.Data.Models.Staff_Staff",
               "invForeignKeyNamesOnServer":[
                  "StaffId"
               ],
               "nameOnServer":"Appointment",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.Staff_HB2.Data.Models.Business_Business",
               "foreignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Schedule:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.Schedule_HB2.Data.Models.Staff_Staff",
               "invForeignKeyNamesOnServer":[
                  "StaffId"
               ],
               "nameOnServer":"Schedule",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"StaffService:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.StaffService_HB2.Data.Models.Staff_Staff",
               "invForeignKeyNamesOnServer":[
                  "StaffId"
               ],
               "nameOnServer":"StaffService",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"StaffService",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"StaffService",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"StaffService1",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"ServiceId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"StaffId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Service:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.StaffService_HB2.Data.Models.Service_Service",
               "foreignKeyNamesOnServer":[
                  "ServiceId"
               ],
               "nameOnServer":"Service",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"Staff:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.StaffService_HB2.Data.Models.Staff_Staff",
               "foreignKeyNamesOnServer":[
                  "StaffId"
               ],
               "nameOnServer":"Staff",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"UserAccess",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"None",
         "defaultResourceName":"UserAccess",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"UserAccessId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"Authorized",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"BusinessId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"ManageAccess",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":false,
               "nameOnServer":"Owner",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"UserId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"Business:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.UserAccess_HB2.Data.Models.Business_Business",
               "foreignKeyNamesOnServer":[
                  "BusinessId"
               ],
               "nameOnServer":"Business",
               "validators":[
                  
               ]
            },
            {
               "entityTypeName":"UserAccount:#HB2.Data.Models",
               "isScalar":true,
               "associationName":"HB2.Data.Models.UserAccess_HB2.Data.Models.UserAccount_User",
               "foreignKeyNamesOnServer":[
                  "UserId"
               ],
               "nameOnServer":"User",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"UserAccount",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"UserAccount",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"UserId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Activated",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"ActivationCode",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":300,
               "nameOnServer":"Avatar",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":300,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":true,
               "nameOnServer":"DateCreated",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Enabled",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"FbId",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Firstname",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":20,
               "nameOnServer":"IdentityNumber",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":20,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTimeOffset",
               "isNullable":true,
               "nameOnServer":"LastLogin",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":50,
               "nameOnServer":"Lastname",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Password",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":18,
               "nameOnServer":"Telephone",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":18,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":3,
               "nameOnServer":"TelephoneCountry",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":3,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":false,
               "maxLength":10,
               "nameOnServer":"Title",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "maxLength":10,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"UserRole",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"UserType",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Username",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            {
               "entityTypeName":"UserAccess:#HB2.Data.Models",
               "isScalar":false,
               "associationName":"HB2.Data.Models.UserAccess_HB2.Data.Models.UserAccount_User",
               "invForeignKeyNamesOnServer":[
                  "UserId"
               ],
               "nameOnServer":"UserAccess",
               "validators":[
                  
               ]
            }
         ]
      },
      {
         "shortName":"UsersView",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"UsersView",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"UserId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Activated",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"ActivationCode",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"AddressBuilding",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"AddressStreet",
               "validators":[
                  
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"DateCreated",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Enabled",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"FirstName",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Hpcsaregistration",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"IdentityNumber",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"LastLogin",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"MedicalAidId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"MedicalAidNumber",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"NotWebsiteUser",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Paid",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Password",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"PracticeNumber",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"SuburbId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Surname",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Telephone",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Title",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":false,
               "nameOnServer":"UserDetailsId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"UserRole",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"UserType",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Username",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      },
      {
         "shortName":"ViewDoctors",
         "namespace":"HB2.Data.Models",
         "autoGeneratedKeyType":"Identity",
         "defaultResourceName":"ViewDoctors",
         "isComplexType":false,
         "dataProperties":[
            {
               "dataType":"Guid",
               "isPartOfKey":true,
               "isNullable":false,
               "nameOnServer":"UserId",
               "validators":[
                  {
                     "name":"required"
                  },
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"AboutMe",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Activated",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"ActivationCode",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"AddressBuilding",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"AddressStreet",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"BankAccNo",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"BankBranchCode",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"BankName",
               "validators":[
                  
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"DateCreated",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Enabled",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Expr1",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"FirstName",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Hpcsaregistration",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"IdentityNumber",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"LastLogin",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Guid",
               "isNullable":true,
               "nameOnServer":"MedicalAidId",
               "validators":[
                  {
                     "name":"guid"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"MedicalAidNumber",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"NotWebsiteUser",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"Paid",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Password",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"PracticeNumber",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"DateTime",
               "isNullable":true,
               "nameOnServer":"PromotionEndDate",
               "validators":[
                  {
                     "name":"date"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"RankArea",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"RankEndUsers",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"RankIsRecommendedImage",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"RankIsValidatedImage",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"RankPartner",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"RankPartnerImage",
               "validators":[
                  
               ]
            },
            {
               "dataType":"Boolean",
               "isNullable":true,
               "nameOnServer":"RankPromotional",
               "validators":[
                  {
                     "name":"bool"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"RankSystem",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"SetTimeIntervals",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Surname",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"Telephone",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Title",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"UserRole",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "nameOnServer":"UserType",
               "validators":[
                  
               ]
            },
            {
               "dataType":"String",
               "isNullable":true,
               "maxLength":50,
               "nameOnServer":"Username",
               "validators":[
                  {
                     "maxLength":50,
                     "name":"maxLength"
                  }
               ]
            },
            {
               "dataType":"Int32",
               "isNullable":true,
               "nameOnServer":"Votes",
               "validators":[
                  {
                     "name":"int32"
                  }
               ]
            },
            {
               "dataType":"Decimal",
               "isNullable":true,
               "nameOnServer":"VotesTotal",
               "validators":[
                  {
                     "name":"number"
                  }
               ]
            }
         ],
         "navigationProperties":[
            
         ]
      }
   ]
};
}