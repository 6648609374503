<div *ngIf="business" class="med-select select-small outline-blue flex-column sm:flex-row">
    <mat-form-field class="s-width">
     <mat-select  [formControl]="viewControl" class="flex-auto" name="business">
        <mat-select-trigger>
            <app-avatar-icon  [size]="'small'"  [name]="business.name"></app-avatar-icon>
            <span [innerHtml]="business?.name"> </span>
          </mat-select-trigger>
        <mat-option *ngFor='let bus of businesses;let i = index;' [value]="bus.businessId">  {{bus.name}} </mat-option>
     </mat-select>
   </mat-form-field>
   </div>