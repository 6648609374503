<!-- Navigation -->
<treo-vertical-navigation *ngIf="!isScreenSmall" (openedChanged)="openChanged()" class="bg-white print:hidden" [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation" [appearance]="'futuristic'" [mode]="isScreenSmall ? 'over' : 'side'" [opened]="(!isScreenSmall && !isCalendar)">

    <ng-container treoVerticalNavigationContentHeader>

        <div class="flex items-center h-20 p-6 mt-5 pt-0 pb-0">
            <img class="w-10 mr-2" src="assets/undraw/char.svg" alt="Logo image">
            <div class="text-3xl font-semibold tracking-tighter">CalendarFive</div>
        </div>
    </ng-container>
</treo-vertical-navigation>

<treo-vertical-navigation *ngIf="isScreenSmall" class="bg-white print:hidden" [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation" [appearance]="'futuristic'" [mode]="isScreenSmall ? 'over' : 'side'" [opened]="(!isScreenSmall && !isCalendar)">
    <!-- Navigation header hook -->
    <ng-container treoVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-7" src="assets/undraw/char.svg">
                <span class="text-2xl font-semibold tracking-tighter pl-1 pt-1 text-default">
                    CalendarFive
                </span>
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <!-- <user-menu [showAvatar]="false"></user-menu> -->
                <user-menu [showAvatar]="false"></user-menu>
                <button [routerLink]="'/sign-out'" mat-icon-button>
                    <mat-icon>power_settings_new</mat-icon>
                </button>
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img class="w-full h-full rounded-full" *ngIf="data.user.avatar" [src]="data.user.avatar" alt="User avatar">
                <mat-icon class="icon-size-24" *ngIf="!data.user.avatar" [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.firstname}} {{user.lastname}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap overflow-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.username}}
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Navigation footer hook -->
    <ng-container treoVerticalNavigationContentFooter>

        <!-- <div class="fixed bottom-0 flex flex-1 items-center justify-center h-5 pr-6 pl-2 opacity-50"> -->
        <!-- <img class="max-w-36" src="assets/images/logo/logo-text-on-dark.svg"> -->
        <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent"> -->
        <!-- <span class="pl-5 font-medium text-sm text-secondary">v{{env.version}}</span> -->
        <!-- </div> -->
        <!-- </div> -->
    </ng-container>
</treo-vertical-navigation>


<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="print:hidden relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <app-calendar-navigator *ngIf="isCalendar"></app-calendar-navigator>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-2">
            <app-business-selector *ngIf="multiuser && !isCalendar"></app-business-selector>
            <app-calendar-view-selector *ngIf="isCalendar && !isScreenSmall"></app-calendar-view-selector>
            <search [appearance]="'bar'"></search>
            <!-- <shortcuts [shortcuts]="data.shortcuts"></shortcuts> -->
            <messages></messages>
            <!-- <notifications [notifications]="data.notifications"></notifications> -->
            <user-menu *ngIf="!isScreenSmall"></user-menu>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent">
        <span class="font-medium text-secondary">v.{{env.version}}</span>
    </div> -->

</div>