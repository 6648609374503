import { Injectable, OnDestroy} from '@angular/core';
import { AppSettings } from '../app-settings.service';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { SignalRServiceBase } from './signalR-base.service';
import { ISignalRAppointment } from '../../models/entity-objects';
import * as signalR from '@microsoft/signalr';
import {DateTime} from 'luxon';
@Injectable()
export class SignalRInboundService implements OnDestroy {


    private receiveNewAppointmentSubject = new Subject<ISignalRAppointment>();
    private receiveUpdateAppointmentSubject = new Subject<ISignalRAppointment>();
    private receiveDeleteAppointmentSubject = new Subject<ISignalRAppointment>();


    // Observable string streams
    receivedNewAppointment$ = this.receiveNewAppointmentSubject.asObservable();
    receivedDeleteAppointment$ = this.receiveDeleteAppointmentSubject.asObservable();
    receivedUpdateAppointment$ = this.receiveUpdateAppointmentSubject.asObservable();

    subscription: Subscription;

    constructor(
        private _signalR: SignalRServiceBase,
        private appSettings: AppSettings
    ) {


        this._signalR.reInitSignalRSubject$.subscribe(() => {
            console.log('RTS Inbound Initialized.');


            this._signalR.connection.on('receiveNewAppointment', (data: ISignalRAppointment) => {
                this.receiveNewAppointment(data);
            });
    
            this._signalR.connection.on('receiveUpdateAppointment', (data: ISignalRAppointment) => {
                this.receiveUpdateAppointment(data);
            });
    
            this._signalR.connection.on('receiveDeleteAppointment', (data: ISignalRAppointment) => {
                this.receiveDeleteAppointment(data);
            });

            this._signalR.connection.on('hubReceived', (data: string) => {
                console.log(data);
            });

            this._signalR.connection.on('execute', (data: string) => {
                
            });

        });

    }
    private receiveNewAppointment = (data: ISignalRAppointment) => {
        console.log('SR:Received New Appointment');
        this.appSettings.lastLiveUpdate = DateTime.fromJSDate(new Date());
        this.receiveNewAppointmentSubject.next(data);
    }

    private receiveUpdateAppointment = (data: ISignalRAppointment) => {
        this.appSettings.lastLiveUpdate = DateTime.fromJSDate(new Date());
        this.receiveUpdateAppointmentSubject.next(data);
    }

    private receiveDeleteAppointment = (data: ISignalRAppointment) => {
        this.appSettings.lastLiveUpdate = DateTime.fromJSDate(new Date());
        this.receiveDeleteAppointmentSubject.next(data);
    }


    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
