import { Service } from './Service';
import { Staff } from './Staff';
import { Entity } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class StaffService {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    staffService1: string;
    serviceId: string;
    staffId: string;
    service: Service;
    staff: Staff;
}
export interface IStaffService extends StaffService {}
export interface IBreezeInvoice extends Entity, IStaffService { }
