import { Appointment } from './Appointment';
import { Business } from './Business';
import { Message } from './Message';
import { Entity, EntityState } from 'breeze-client';
import { ClientTag } from './ClientTag';
import { EntityBase } from './EntityBase';
import { Invoice } from './Invoice';
import { Reminder } from './Reminder';
/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Client extends EntityBase  {

    /// <code> Place custom code between <code> tags
    
    isNew(): boolean
    {
        if(this.entityAspect.entityState === EntityState.Added)
            return true;
        
        return false;
    }

    clear() : void {
        this.clientId = null;
        this.firstname = '';
        this.lastname = '';
        this.telephone = '';
        this.telephoneAlternate = '';
    }
    /// </code>

    // Generated code. Do not place code below this line.
    clientId: string;
    addressBuildingNo: string;
    addressCity: string;
    addressPostalCode: string;
    addressStreet: string;
    addressStreetNo: string;
    addressSuburb: string;
    businessId: string;
    customId: string;
    date: Date;
    deleted: boolean;
    email: string;
    fbId: string;
    firstname: string;
    gender: string;
    geo: string;
    globalUserId: string;
    googleLocationString: string;
    identityNumber: string;
    issue: boolean;
    lastname: string;
    lat: string;
    lng: string;
    medicalAidName: string;
    medicalAidNo: string;
    medicalReferringDoc: string;
    notes: string;
    telephone: string;
    telephoneAlternate: string;
    appointment: Appointment[];
    business: Business;
    clientTag: ClientTag[];
    invoice: Invoice[];
    message: Message[];
    reminder: Reminder[];
}
export interface IClient extends Client {}
export interface IBreezeClient extends Entity, IClient { }
