import { environment } from './../../../environments/environment';


import { Injectable } from '@angular/core';
import { EntityManager, Predicate, DataService, NavigationProperty, Entity, MetadataStore, EntityQuery, config, SaveResult, QueryResult, FilterQueryOp, NamingConvention, core, Validator, ValidationOptions, breeze } from 'breeze-client';
// import { IBreezeManagerSaveQue } from '../models/entity-objects';
import { IBreezeService, UserAccount, UserAccess, Staff } from '../models/entities/EntityModel';
import { IBreezeStaff } from '../models/entities/IBreezeStaff';
import { IBreezeSetting } from '../models/entities/IBreezeSetting';
import { AppSettings } from './app-settings.service';
import { AppointmentCacheList } from './cache/appointment-cache.service';
import { DateTime } from 'luxon';
// import * as _ from 'lodash';


// import 'breeze-client/breeze.dataService.webApi';
// import 'breeze-client/breeze.modelLibrary.backingStore';
// import 'breeze-client/breeze.uriBuilder.json';

import { DataServiceWebApiAdapter } from 'breeze-client/adapter-data-service-webapi';
import { ModelLibraryBackingStoreAdapter } from 'breeze-client/adapter-model-library-backing-store';
import { UriBuilderODataAdapter } from 'breeze-client/adapter-uri-builder-odata';
import { AjaxHttpClientAdapter } from 'breeze-client/adapter-ajax-httpclient';

import { UriBuilderJsonAdapter } from 'breeze-client/adapter-uri-builder-json';

import { enableSaveQueuing } from 'breeze-client/mixin-save-queuing';
import { SupportServices } from './support.service';
// declare var window: any;

import { RegistrationHelper } from '../models/entities/RegistrationHelper';
import { Metadata } from '../models/entities/Metadata';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';


@Injectable()
export class BreezeService {



    serviceName: string;
    service: EntityManager;
    servicealt: EntityManager;
    breezeCache: any;
    lastSaveToCache: DateTime = DateTime.fromJSDate(new Date());



    constructor(

        private appSettings: AppSettings,
        private http: HttpClient,
        private appointmentCache: AppointmentCacheList
    ) {
        NamingConvention.camelCase.setAsDefault();
        // config.initializeAdapterInstances({ dataService: 'webApi', uriBuilder: 'json' });


        ModelLibraryBackingStoreAdapter.register();
        UriBuilderJsonAdapter.register();
        AjaxHttpClientAdapter.register(http);
        DataServiceWebApiAdapter.register();
        config.initializeAdapterInstance('uriBuilder', 'json');

        // DataType.parseDateFromServer = function (source) {
        //     var date = moment.utc(source).utcOffset('+0000');
        //     return date.toDate();
        // };

        // var v0 = Validator.required({ allowEmptyStrings: true });

        // Validator.required()

        // this is a fix to the validator that allows empty strings for required string fields.
        Validator.required = function (context) {
            const valFn = function (v, ctx) {
                return v != null;
            };
            return new Validator('required', valFn, context);

        };
        // register the new validator so that metadata can find it.
        Validator.registerFactory(Validator.required, 'required');

        // Validator.registerFactory()
        // Validator.required()


        // var vo = new ValidationOptions({
        //     validateOnSave: false,
        //     validateOnQuery: false, // not needed here but shown for completeness
        //     validateOnAttach: false  // not needed here but shown for completeness
        // });



        this.serviceName = environment.api_path + '/breeze/'; // breeze Web API controller
        const dataService = new DataService({
            serviceName: environment.api_path + '/breeze/',
            hasServerMetadata: false  // don't ask the server for metadata
        });

        const metadataStore = new MetadataStore();
        metadataStore.importMetadata(JSON.stringify(Metadata.value));

        // metadataStore.fetchMetadata(dataService).then((data)=>{
        //     //console.log(data);
        // });



        // Identify the endpoint for the remote data service

        // NamingConvention.camelCase.setAsDefault();
        // this.service = new EntityManager(this.serviceName);

        this.service = new EntityManager(
            {
                dataService: dataService,
                metadataStore: metadataStore
            }
        );

        // RegistrationHelper.register(this.service.metadataStore);

        enableSaveQueuing(this.service);

        // this.service.setProperties({ validationOptions: vo });
        // this.service = new EntityManager(this.serviceName);
        // this.service.enableSaveQueuing(true);

    }

    // public saveToCache() {

    //     const last = _.cloneDeep(this.lastSaveToCache).plus({minutes: 15});
    //     const now = DateTime.fromJSDate(new Date());

    //     const exportData = this.service.exportEntities();
    //     this.appointmentCache.save();
    // }

    // public cleanCache() {
    //     const cachedAppointments = this.service.getEntities('Appointment'); // all invoices in cache
    //     // Todo: this should be a function of the Breeze EntityManager itself
    //     cachedAppointments.forEach((entity: Appointment) => {
    //         const oldest = moment(this.appSettings.calDate).subtract(60, 'd');
    //         const appDate = moment(entity.startDate);
    //         if (appDate.isBefore(oldest)) {
    //             this.service.detachEntity(entity);
    //         }
    //     });

    //     const newArr = _.filter(this.appointmentCache._list, (o: any) => {
    //         const oldest = moment(this.appSettings.calDate).subtract(60, 'd');
    //         const appDate = moment(o.period);
    //         return appDate.isBefore(oldest);
    //     });
    //     this.appointmentCache._list = newArr;
    // }


    // public loadFromCache() {
    //     return new Promise((resolve, reject) => {
    //         this.loadBaseData().then(() => {
    //             resolve();
    //         }).catch((err) => {
    //             console.log('error B100');
    //             console.log(err);
    //             reject();
    //         });
    //     });

    // }







    // public loadBaseData() {

    //     const business = EntityQuery.from('Business').expand(['service', 'setting', 'staff', 'location', 'businessType']);
    //     const pBusiness = this.service.executeQuery(business).then((data) => {
    //         // console.log(data);
    //     });

    //     const userAccount = EntityQuery.from('UserAccount').expand('userAccess');
    //     const pAccount = this.service.executeQuery(userAccount).then((data) => {
    //         // console.log(data);
    //     });

    //     return Promise.all([pBusiness, pAccount]);

    // }

    public adminLoadBaseData() {

        const business = EntityQuery.from('Business').expand(['businessType']);
        const pBusiness = this.service.executeQuery(business).then((data) => {
            // console.log(data);
        });

        return Promise.all([pBusiness]);

    }



    // public getUserAccountById(id): IBreezeUserAccount {
    //     // var query;
    //     if (id) {
    //         return this.service.getEntityByKey('UserAccount', id) as IBreezeUserAccount;
    //     }
    // }

    public loadMessages(): any {
        const messages = EntityQuery.from('Messages');
        return this.service.executeQuery(messages);


    }

    // public getBusinessById(id): IBreezeBusiness {
    //     return this.service.getEntityByKey('Business', id) as IBreezeBusiness;
    // }

    // public getBusinessOwnerById(id): IBreezeUserAccount {



    //     const p1 = Predicate.create('businessId', 'eq', id);
    //     const p2 = Predicate.create('owner', 'eq', true);
    //     const pred: Predicate = Predicate.and([p1, p2]);
    //     const query = EntityQuery.from('UserAccess').where(pred);
    //     return this.service.executeQueryLocally(query)[0] as IBreezeUserAccount;

    // }


    public getStaffById(id): Staff {
        return this.service.getEntityByKey('Staff', id) as Staff;
    }

    public getServiceById(id): IBreezeService {
        return this.service.getEntityByKey('Service', id) as IBreezeService;
    }

    public getStaffByIdActive(id): IBreezeStaff {

        const p1 = Predicate.create('staffId', 'eq', id);
        const p2 = Predicate.create('deleted', 'eq', false);
        const p3 = Predicate.create('enabled', 'eq', true);
        let pred: Predicate;

        pred = Predicate.and([p1, p2, p3]);

        const query = EntityQuery.from('Staff').where(pred);
        return this.service.executeQueryLocally(query)[0] as IBreezeStaff;


    }

    // public getDoctorSpeciality(id): any {
    //     var query = EntityQuery.from('DoctorSpecialities').where('doctor_ID', 'eq', id);
    //     var set = this.service.executeQueryLocally(query);
    //     return set[0];
    // }

    // public getDoctorsSpeciality(id): any {
    //     var query = EntityQuery.from('DoctorSpecialities').where('doctor_ID', 'eq', id);
    //     return this.service.executeQueryLocally(query);
    // }

    public getAppointmentByClient(id: string): Promise<Entity[]> {

        return new Promise((resolve, reject) => {
            // var query = EntityQuery.from('AppointmentsUnlimited').where('doctorPatientID', FilterQueryOp.Equals, id).orderBy('startDate desc');
            const query = EntityQuery.from('AppointmentsUnlimited').where('clientId', FilterQueryOp.Equals, id).orderBy('StartDate desc');

            this.servicealt.executeQuery(query).then((data) => {
                resolve(data.results);
            });
        });
    }


    // public createSpecialityRow(id:string): Promise<IBreezeDoctorSpeciality>
    // {
    //     return new Promise((resolve, reject) => {
    //         if (!id)
    //             throw new Error('Could not create speciality row. Doctor ID is empty.');

    //         // client.patientID = core.getUuid();
    //         // client.medappID = SupportServices.EmptyGuid;
    //         // client.doctorID = id;

    //         let doctorSpeciality = <IBreezeDoctorSpeciality>this.service.createEntity('DoctorSpecialities');
    //         doctorSpeciality.doctorSpeciality_ID = core.getUuid();
    //         doctorSpeciality.doctor_ID = id;
    //         doctorSpeciality.speciality_ID = '2FFE696B-FCB0-486B-A7A2-086FD2209F5A'; //set it to GP for now.


    //         if (doctorSpeciality.entityAspect.hasValidationErrors) {
    //             var errors = doctorSpeciality.entityAspect.getValidationErrors();
    //             reject(errors[0].errorMessage);
    //             doctorSpeciality.entityAspect.setDetached();
    //         } else {
    //             this.service.saveChanges().then(
    //                 (data) => {

    //                     resolve(doctorSpeciality);
    //                 })
    //                 .catch((err) => {
    //                     reject(err);
    //                 })
    //         }
    //     });
    // }


    // public getBusinessAccess() {


    //     const p1 = Predicate.create('businessId', 'eq', this.appSettings.currentBID);
    //     const p2 = Predicate.create('owner', 'eq', false);
    //     const pred = Predicate.and([p1, p2]);
    //     const query = EntityQuery.from('UserAccess').where(pred);
    //     // const query = EntityQuery.from('UserAccess').where('businessId', FilterQueryOp.Equals, this.appSettings.currentBID);
    //     return this.service.executeQueryLocally(query) as IBreezeUserAccess[];
    // }

    // public getBusinessImage(id: string): Promise<IBreezeImage> {
    //     return new Promise((resolve, reject) => {
    //         if (!id) {
    //             throw new Error('Could not find doctor Images');
    //         }

    //         const query = EntityQuery.from('Image').where('businessId', 'eq', id).orderBy('date', true);

    //         return this.service.executeQuery(query).then((data) => {
    //             resolve(data.results[0] as IBreezeImage);
    //         });

    //     });
    // }


    // //master data
    // public getSpecialities(): Promise<IBreezeSpecialities[]> {
    //     //should have a local speciality list here to save then load from.


    //     return new Promise((resolve, reject) => {
    //         var specialities = EntityQuery.from('Specialities');
    //         this.service.executeQuery(specialities).then((data) => {
    //             resolve( <IBreezeSpecialities[]>data.results);

    //         });
    //     });

    // }


    public getSettings(id): IBreezeSetting {

        return this.service.getEntityByKey('Setting', id) as IBreezeSetting;
    }
    public getAllSettings(): IBreezeSetting[] {

        return this.service.getEntities('Setting') as IBreezeSetting[];
    }

    // public reCheckMultiUser() {
    //     const userAccessList = this.getUserAccess();

    //     const staff = this.getStaff();

    //     if (userAccessList.length > 1 || staff.length > 1) {
    //         this.appSettings.multiuser = true;
    //     }
    // }


    // public getLocation(id: string): IBreezeLocation {
    //     return this.service.getEntityByKey('Location', id) as IBreezeLocation;
    // }
    // public getBookableLocations(businessId: string, onlyActive: boolean): IBreezeLocation[] {


    //     const p1 = Predicate.create('businessId', 'eq', businessId);
    //     const p2 = Predicate.create('enabledBookings', 'eq', true);
    //     const p3 = Predicate.create('deleted', 'eq', false);
    //     let pred: Predicate;
    //     if (onlyActive) {
    //         pred = Predicate.and([p1,p2,p3]);
    //     }
    //     else {
    //         pred = p1;
    //     }
    //     const query = EntityQuery.from('Location').where(pred);
    //     return this.service.executeQueryLocally(query) as IBreezeLocation[];

    // }
    // public getAllLocations(businessId: string, onlyActive: boolean): IBreezeLocation[] {
    //     const p1 = Predicate.create('businessId', 'eq', businessId);
    //     const p2 = Predicate.create('deleted', 'eq', false);
    //     let pred: Predicate;
    //     if (onlyActive) {
    //         pred = Predicate.and([p1,p2]);
    //     }
    //     else {
    //         pred = p1;
    //     }
    //     const query = EntityQuery.from('Location').where(pred);
    //     return this.service.executeQueryLocally(query) as IBreezeLocation[];

    // }

    // public canDeleteLocation(businessId: string, locationId: string): Promise<boolean> {

    //     return new Promise((resolve, reject) => {
    //         const p1 = Predicate.create('businessId', 'eq', businessId);
    //         const p2 = Predicate.create('locationId', 'eq', locationId);
    //         const p3 = Predicate.create('startDate', FilterQueryOp.GreaterThanOrEqual, moment());
    //         const p5 = Predicate.create('label', FilterQueryOp.GreaterThanOrEqual, Number(0));

    //         let pred: Predicate;
    //         pred = Predicate.and([p1, p2, p3, p5]);

    //         const query = EntityQuery.from('Appointment').where(pred);
    //         return this.service.executeQuery(query).then((data) => {
    //             resolve((data.results.length > 0) ? true : false);
    //         });
    //     });


    // }


    public getAllStaffByBusiness(businessId: string, showDeleted: boolean, onlyEnabled: boolean): IBreezeStaff[] {


        const p1 = Predicate.create('businessId', 'eq', businessId);
        const p2 = Predicate.create('deleted', 'eq', showDeleted);
        let pred: Predicate;
        if (onlyEnabled) {
            const p3 = Predicate.create('enabled', 'eq', true);
            pred = Predicate.and([p1, p2, p3]);
        }
        else {
            pred = Predicate.and([p1, p2]);
        }

        const query = EntityQuery.from('Staff').where(pred);
        return this.service.executeQueryLocally(query) as IBreezeStaff[];
    }
    public getAllStaff(onlyActive: boolean = true): IBreezeStaff[] {



        const p2 = Predicate.create('deleted', 'eq', false);
        const p3 = Predicate.create('enabled', 'eq', true);
        let pred: Predicate;
        if (onlyActive) {
            pred = Predicate.and([p2, p3]);
        }
        else {
            pred = p2;
        }
        const query = EntityQuery.from('Staff').where(pred);
        return this.service.executeQueryLocally(query) as IBreezeStaff[];
    }




    public getAllServices(businessId: string, onlyActive: boolean): IBreezeService[] {


        const p1 = Predicate.create('businessId', 'eq', businessId);
        // var p2 = Predicate.create('deleted', 'eq', false);
        let pred: Predicate;
        // if(onlyActive)
        //     pred = Predicate.and([p1,p2]);
        // else
        pred = p1;
        const query = EntityQuery.from('Service').where(pred).orderBy('listOrder');
        return this.service.executeQueryLocally(query) as IBreezeService[];
    }


    // public getAdminUsers(id, searchType): Promise<IBreezeUserAccount | Entity[]> {

    //     return new Promise((resolve, reject) => {

    //         if (id.length < 2) {
    //             resolve([]);
    //             return;
    //         }

    //         const p1 = Predicate.create('username', 'contains', id);
    //         const p2 = Predicate.create('userRole', 'contains', id);
    //         const p3 = Predicate.create('firstname', 'contains', id);
    //         const p4 = Predicate.create('lastname', 'contains', id);

    //         let pred: any;
    //         let p5: any;
    //         if (searchType == 0) {
    //             pred = Predicate.or([p1, p2, p3, p4]);
    //         } else if (searchType == 1) {
    //             p5 = Predicate.create('userRole', 'eq', 'PATIENT');
    //         }
    //         else if (searchType == 2) {
    //             p5 = Predicate.create('userRole', 'eq', 'DOCTOR');
    //         }
    //         else if (searchType == 3) {
    //             p5 = Predicate.create('userRole', 'eq', 'DOCADMIN');
    //         }

    //         if (p5) {
    //             pred = Predicate.or([p1, p2, p3, p4]).and(p5);
    //         }

    //         const query = EntityQuery.from('UserAccount').where(pred);

    //         this.service.executeQuery(query).then((data) => {
    //             resolve(data.results);
    //         });
    //     });


    // }


    getStaff(): IBreezeStaff[] {
        return this.service.getEntities('Staff') as IBreezeStaff[];
    }
    // getBusiness(): IBreezeBusiness[] {
    //     return this.service.getEntities('Business') as IBreezeBusiness[];
    // }

    // adminGetBusiness(id:string): Promise<IBreezeBusiness> {
    //         return new Promise((resolve, reject) => {
    //         const p1 = Predicate.create('businessId', 'eq', id);

    //         const query = EntityQuery.from('Business').where(p1).expand('businessType');

    //         this.service.executeQuery(query).then((data) => {
    //             resolve(data.results[0] as IBreezeBusiness);
    //         });
    //     });
    //     // return <IBreezeBusiness[]>this.service.getEntities('Business');
    // }




    // getUserAccess(): IBreezeUserAccess[] {
    //     const test = this.service;
    //     const op = FilterQueryOp;

    //     const query = EntityQuery.from('UserAccess').where('userId', op.Equals, this.appSettings.user.uid);
    //     return this.service.executeQueryLocally(query) as IBreezeUserAccess[];
    // }



    // public addSharingUser(user: UserAccount, businessId: string): Promise<QueryResult> {

    //     return new Promise((resolve, reject) => {
    //         if (!businessId) {
    //             throw new Error('Could not create client. Business ID is empty.');
    //         }

    //         const ua = new UserAccess();
    //         ua.authorized = false;
    //         ua.businessId = businessId;
    //         ua.userAccessId = core.getUuid();
    //         ua.userId = user.userId;
    //         ua.owner = false;


    //         const userAccess = this.service.createEntity('UserAccess', ua) as IBreezeUserAccess;


    //         if (userAccess.entityAspect.hasValidationErrors) {
    //             const errors = userAccess.entityAspect.getValidationErrors();
    //             reject(errors[0].errorMessage);
    //             userAccess.entityAspect.setDetached();
    //         } else {
    //             this.service.saveChanges().then(
    //                 () => {
    //                     resolve();
    //                 })
    //                 .catch((err) => {
    //                     userAccess.entityAspect.setDetached();
    //                     reject(err);
    //                 });
    //         }
    //     });
    // }


    // public getSharedUsers(): Promise<IBreezeUserAccess[]> {

    //     return new Promise((resolve, reject) => {
    //         const query = EntityQuery.from('UserAccess').where('businessId', FilterQueryOp.Equals, this.appSettings.currentBID);
    //         this.service.executeQuery(query).then((data) => {
    //             resolve(data.results as IBreezeUserAccess[]);
    //         })
    //             .catch((err) => {
    //                 console.log(err);
    //                 resolve(null);
    //             });
    //     });

    // }


    // this.http.get(AppSettings.SERVER_API_PATH + '/auth/UserInfo').toPromise()
    // .then((data) => {

    public getAccess(businessId: string): Promise<any> {

        return new Promise((resolve, reject) => {

            this.http.get(environment.api_path + '/appointment/ListProfileAccess?businessId=' + businessId).toPromise()
                .then((data) => {
                    resolve(data);
                });

        });

    }





    initCalendarList() {







        // let data:IBreezeStaff[];
        // if (this.appSettings.role.toUpperCase() == "DOCADMIN") {
        //     var query = EntityQuery.from('Staff');
        //     data = <IBreezeStaff[]>this.service.executeQueryLocally(query);

        //     if (data.length != 1) {
        //         var op = FilterQueryOp;
        //         query = EntityQuery.from('Staff')
        //             .where('userDetails_ID', op.NotEquals, this.appSettings.uid).orderBy('surname');
        //         data = <IBreezeStaff[]>this.service.executeQueryLocally(query);
        //     }
        // }
        // else {
        //     var query = EntityQuery.from('UserDetails');
        //     data = <IBreezeUserDetail[]>this.service.executeQueryLocally(query);

        //     if (data.length != 1) {
        //         var op = FilterQueryOp;
        //         query = EntityQuery.from('UserDetails')
        //             .where('userDetails_ID', op.Equals, this.appSettings.uid).orderBy('surname');
        //         data = <IBreezeUserDetail[]>this.service.executeQueryLocally(query);
        //     }

        // }
        // return data;

    }



    // public getAllDoctors(): IBreezeDetail[] {
    //     // Gets all doctors removes secretary if exists.
    //     //If logged in as secretary = will return only doctors otherwise will return doctor.
    //     let data:IBreezeUserDetail[];
    //     if (this.appSettings.role.toUpperCase() == "DOCADMIN") {
    //         var query = EntityQuery.from('UserDetails');
    //         data = <IBreezeUserDetail[]>this.service.executeQueryLocally(query);

    //         if (data.length != 1) {
    //             var op = FilterQueryOp;
    //             query = EntityQuery.from('UserDetails')
    //                 .where('userDetails_ID', op.NotEquals, this.appSettings.uid).orderBy('surname');
    //             data = <IBreezeUserDetail[]>this.service.executeQueryLocally(query);
    //         }


    //     }
    //     else {
    //         var query = EntityQuery.from('UserDetails');
    //         data = <IBreezeUserDetail[]>this.service.executeQueryLocally(query);

    //         if (data.length != 1) {
    //             var op = FilterQueryOp;
    //             query = EntityQuery.from('UserDetails')
    //                 .where('userDetails_ID', op.Equals, this.appSettings.uid).orderBy('surname');
    //             data = <IBreezeUserDetail[]>this.service.executeQueryLocally(query);
    //         }

    //     }
    // // if (this.appSettings.role.toUpperCase() === UserAuthorization.DOCADMIN)
    // // this.mevents.event_setMultiUser(true);
    // // else
    // // this.mevents.event_setMultiUser(false);
    // return data;

    // }


    // public clear() {
    //     this.service.clear();
    // }


    // public getMe(id?: string): IBreezeUserAccount {
    //     if (id) {
    //         return this.service.getEntityByKey('UserAccount', id) as IBreezeUserAccount;
    //     } else {
    //         return this.service.getEntityByKey('UserAccount', this.appSettings.user.uid) as IBreezeUserAccount;
    //     }
    // }

    // public getOfficeAdministrators(user: IBreezeUserDetail): any {
    //     var officeNavProp = <NavigationProperty>user.entityType.getProperty("officeAdmin");
    //     var query = EntityQuery.fromEntityNavigation(user, officeNavProp);
    //     return this.service.executeQueryLocally(query);
    // }



    public saveChanges(): Promise<{}> {
        return new Promise((resolve, reject) => {
            this.service.saveChanges().then((ct: SaveResult) => {
                // this.saveToCache();
                resolve(null);
            }).catch((err: SaveResult) => {
                console.log(err);
                reject('err');
            });
        });

    }

}
