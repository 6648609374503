import { NotificationQueue } from './../models/entities/NotificationQueue';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, interval, Observable, of } from 'rxjs';
import { first, switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class NotificationService {

    constructor(public _httpClient: HttpClient) {

    }

    send(nq: NotificationQueue): Observable<boolean> {
        if (!nq) {
            console.log('Notification was empty. Not sending.');
            return;
        }
        return this._httpClient.post(environment.api_path + '/sms/send', nq.toJson()).pipe(
            switchMap((response: any) => {
                return of(true);
            }),
            catchError((err, caught) => {
                console.log(err);
                return of(false);
            })
        );
    }



}






























