import { Business } from './Business';
import { Entity } from 'breeze-client';
import { EntityBase } from './EntityBase';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Image extends EntityBase {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    imageId: string;
    businessId: string;
    date: Date;
    filename: string;
    isDefault: number;
    business: Business;
}
export interface IImage extends Image {}
export interface IBreezeImage extends Entity, IImage { }
